import { Text } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingMenuShow } from '../../components/features/bookingMenus/show';
import { Loading } from '../../components/ui/Loading';
import { useFetchBookingMenu } from '../../hooks/bookingMenus';

const BookingMenuShowPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約メニューの詳細');

  const id = React.useMemo(() => {
    const path = location.pathname.split('/');
    console.log(`path: ${path}`, { path });
    return path[path.length - 2]; // 末尾はshow
  }, []);
  const bookingMenuQuery = useFetchBookingMenu({ providerAccount, id });

  return (
    <>
      {bookingMenuQuery.isFetching ? (
        <Loading />
      ) : bookingMenuQuery.data ? (
        <BookingMenuShow
          providerAccount={providerAccount}
          bookingMenu={bookingMenuQuery.data}
        />
      ) : (
        <Text>予約メニューが見つかりませんでした</Text>
      )}
    </>
  );
};

export default BookingMenuShowPage;
