import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { needPayment } from '../../commons/paymentUtil';
import { Loading } from '../../components/ui/Loading';
import { useAuthState } from '../../context/providerAccount';
import { ConsoleRouters } from '../../routers/ConsoleRouter';
import { Path } from '../../routers/Path';
import { ConsoleLayout } from '../ui/ConsoleLayout';

export const Console = () => {
  const location = useLocation();

  const { providerAccount } = useAuthState();

  const { result } = useCheckPreconditions();
  const toast = useToast();

  // 古いパス(/#/付き)を開いたらURLを書き換える
  if (location.hash.startsWith('#/')) {
    return <Navigate replace to={location.hash.replace('#/', '')} />;
  }

  // 事前条件チェック
  if (result.status === 'loading') {
    return (
      <>
        <Loading />
      </>
    );
  } else if (result.status === 'ng') {
    switch (result.error) {
      case 'not-logged-in':
        toast({
          title: 'ログインしてください',
          status: 'info',
        });
        return <Navigate replace to={Path.login} />;

      case 'not-attached':
        toast({
          title: `ログインしたアカウント(${providerAccount?.displayName})はポチコとLINEが連携されていません。LINE連携をおこなってください。`,
          status: 'error',
        });
        if (providerAccount?.lineChannelType === 'messagingApi') {
          return <Navigate replace to={Path.lineOaLinkMessagingApi} />;
        } else {
          return <Navigate replace to={Path.lineOaLink} />;
        }

      case 'unpaid':
        if (
          !(
            [Path.paymentRegistration, Path.churn, Path.unpaid] as string[]
          ).includes(location.pathname)
        ) {
          return <Navigate replace to={Path.unpaid} />;
        }
    }
  }
  if (!providerAccount) {
    toast({
      title: 'ログインしてください',
      status: 'info',
    });
    return <Navigate replace to={Path.login} />;
  }

  return (
    <ConsoleLayout>
      <ConsoleRouters providerAccount={providerAccount} />
    </ConsoleLayout>
  );
};

type CheckPreconditionResult =
  | { status: 'loading' }
  | { status: 'ok' }
  | {
      status: 'ng';
      error:
        | 'not-found'
        | 'not-logged-in'
        | 'not-attached'
        | 'unpaid'
        | 'unknown';
    };

// 管理画面を使うにあたっての事前条件チェック
const useCheckPreconditions = () => {
  const location = useLocation();

  const { initialized, providerAccount, firebaseUser } = useAuthState();

  const [result, setResult] = React.useState<CheckPreconditionResult>({
    status: 'loading',
  });

  useEffect(() => {
    if (!initialized) {
      setResult({ status: 'loading' });
      return;
    }

    if (initialized) {
      if (!firebaseUser) {
        setResult({ status: 'ng', error: 'not-logged-in' });
        return;
      }
      if (!providerAccount) {
        setResult({ status: 'ng', error: 'not-attached' });
        return;
      }

      // firebaseUser && providerAccountが存在する
      if (needPayment(providerAccount)) {
        setResult({ status: 'ng', error: 'unpaid' });
        return;
      } else if (providerAccount.status === 'detached') {
        setResult({ status: 'ng', error: 'not-attached' });
        return;
      }

      if (location.pathname.includes('undefined/undefined')) {
        // 初期化終わったのにproviderAccountが無いとき、パスが/undefined/undefinedとかになるのを防ぐ
        setResult({ status: 'ng', error: 'not-logged-in' });
      } else {
        // ここまで来たらOK
        setResult({ status: 'ok' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerAccount, initialized, firebaseUser, location.pathname]);

  return {
    result,
  };
};
