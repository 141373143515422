import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Booking, ProviderAccount, waitAtLeast } from '@pochico/shared';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import dayjs from 'dayjs';
import React from 'react';
import { BookingFilter } from '../components/features/Bookings';
import {
  BookingCreateParams,
  BookingUpdateParams,
  DisplayBooking,
} from '../firebase/types';
import {
  createBooking,
  deleteBookings,
  getBooking,
  getBookingCount,
  getBookingList,
  updateBooking,
} from '../providers/dataProvider/booking';
import { Sort } from '../providers/dataProvider/type';
import { spotQueryKey } from './spots';
import { usePaginationQuery } from './usePaginationQuery';
import { useUrlParamState } from './useUrlParamState';

const bookingQueryKey = createQueryKeys('booking', {
  show: (providerAccountId: string, id: Booking['id']) => [
    providerAccountId,
    id,
  ],
  count: (providerAccountId: string, filter: BookingFilter) => [
    providerAccountId,
    filter,
  ],
  list: (input: {
    providerAccountId: ProviderAccount['id'];
    filter: BookingFilter;
    perPage: number;
    sort: Sort<Booking>;
    // page: number;
  }) => [
    input.providerAccountId,
    input.filter,
    input.perPage,
    // input.page,
    input.sort,
  ],
});

export const useFetchBooking = ({
  providerAccount,
  id,
}: {
  providerAccount: ProviderAccount;
  id: string;
}) => {
  return useQuery({
    queryKey: bookingQueryKey.show(providerAccount.id, id).queryKey,
    queryFn: () => getBooking(providerAccount.id, id),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 1, // 1min
  });
};

export const useFetchBookings = ({
  providerAccount,
  filter,
  perPage,
  sort,
  page,
  enabled,
}: {
  providerAccount: ProviderAccount;
  filter: BookingFilter;
  perPage: number;
  sort: Sort<Booking>;
  page: number;
  enabled?: boolean;
}) => {
  const query = usePaginationQuery<DisplayBooking>({
    queryKey: bookingQueryKey.list({
      providerAccountId: providerAccount.id,
      filter,
      perPage,
      sort,
    }).queryKey,
    enabled: enabled ?? true,
    queryFn: (pagination) =>
      getBookingList(providerAccount.id, {
        filter,
        pagination,
      }).then((result) => {
        return result.data;
      }),
    pagination: {
      perPage,
      sort,
    },
    page,
  });
  return query;
};

export const useFetchBookingCount = ({
  providerAccountId,
  filter,
}: {
  providerAccountId: string;
  filter: BookingFilter;
}) => {
  return useQuery({
    queryKey: bookingQueryKey.count(providerAccountId, filter).queryKey,
    queryFn: () => getBookingCount(providerAccountId, filter),
    placeholderData: keepPreviousData,
  });
};

export const useCreateBooking = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BookingCreateParams) =>
      waitAtLeast(createBooking(providerAccount, params), 500),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: bookingQueryKey._def }),
        queryClient.invalidateQueries({ queryKey: spotQueryKey._def }),
      ]);
    },
  });
};

export const useDeleteBookings = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: string[]) =>
      waitAtLeast(deleteBookings(providerAccount.id, ids), 500),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: bookingQueryKey._def }),
        queryClient.invalidateQueries({ queryKey: spotQueryKey._def }),
      ]);
    },
  });
};

export const useUpdateBooking = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updateInput: BookingUpdateParams) =>
      waitAtLeast(updateBooking({ providerAccount, updateInput }), 1000),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: bookingQueryKey._def });
    },
  });
};

export const useBookingListFilter = () => {
  const now = React.useMemo(() => dayjs(), []);
  const { urlState: filter, setUrlState: setFilter } =
    useUrlParamState<BookingFilter>(['bookingMenuId', 'displayDate'], {
      defaultValue: {
        displayDate: {
          start: now.format('YYYY-MM-DD'),
          end: now.add(1, 'month').format('YYYY-MM-DD'),
        },
      },
    });
  return { filter, setFilter };
};
