import { ProviderAccount } from '@pochico/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import {
  BulkAddSpotsInput,
  convertBulkAddSpotsToSpots,
} from '../components/features/bulkAddSpots/types';
import { DisplaySpot } from '../firebase/types';
import { createBulkSpots } from '../providers/dataProvider/bulkSpot';
import { getSpotList } from '../providers/dataProvider/spot';
import { spotQueryKey } from './spots';

export const useBulkSpotsCreate = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BulkAddSpotsInput) => {
      return createBulkSpots(providerAccount.id, providerAccount.botId, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: spotQueryKey._def });
    },
  });
};

export const useBulkSpotsValidator = (providerAccount: ProviderAccount) => {
  const checkDuplicates = React.useCallback(
    async (input: BulkAddSpotsInput) => {
      const result = await getSpotList(providerAccount.id, {
        filter: {
          displayDate: {
            start: input.duration.startDate,
            end: input.duration.endDate,
          },
          bookingMenuId: input.bookingMenuId,
        },
        pagination: {
          page: 1,
          perPage: 9999,
          sort: {
            field: 'dateTimeForSort',
            direction: 'asc',
          },
        },
      });
      if (!result.data) {
        return [];
      }
      const spotLikes = convertBulkAddSpotsToSpots(input);
      const duplicates: DisplaySpot[] = result.data.filter((spot) => {
        return (
          spot.status === 'active' &&
          spotLikes.some(({ dateTimeForSort }) => {
            return dateTimeForSort === spot.dateTimeForSort;
          })
        );
      });
      return duplicates;
    },
    [providerAccount.id]
  );
  return {
    checkDuplicates,
  };
};
