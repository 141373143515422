import { HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from '../../../context/providerAccount';
import { postBotApi } from '../../../dataStore/bot';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { Path } from '../../../routers/Path';
import { Layout } from '../../ui/Layout';

// 請求先メールアドレス変更を完了するためのページ
// 新しいメールアドレスに対して送信されたメールに記載されるリンクからアクセスされる
export const ChangeInvoiceConfigurationComplete = () => {
  const { initialized, providerAccount, firebaseUser } = useAuthState();
  if (!initialized) {
    return <Spinner />;
  }
  if (!providerAccount || !firebaseUser) {
    return <Navigate to={Path.login} />;
  }
  return (
    <ChangeInvoiceConfigurationCompleteInner
      providerAccount={providerAccount}
      firebaseUser={firebaseUser}
    />
  );
};

const ChangeInvoiceConfigurationCompleteInner: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const mutation = useMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      return postBotApi<
        | { ok: false; error: string }
        | { ok: true; invoiceName: string; invoiceEmail: string }
      >(
        `/provider-accounts/${providerAccount.id}/changeInvoiceEmail`,
        firebaseUser,
        { token }
      );
    },
    onSuccess: async (data) => {
      if (data?.ok) {
        if (data.body.ok) {
          return data.body.invoiceEmail;
        } else {
          return Promise.reject(data.body.error);
        }
      } else {
        return Promise.reject(data?.error);
      }
    },
  });
  React.useEffect(() => {
    if (
      token &&
      !mutation.isPending &&
      !mutation.isSuccess &&
      !mutation.isError
    ) {
      mutation.mutateAsync();
    }
  }, [mutation, token]);

  return (
    <Layout
      pageTitle={
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text fontWeight={'bold'}>請求先情報の変更</Text>
        </VStack>
      }
      hasBackButton={Path.configuration}
    >
      {!token ? (
        <Text>URLが不正です</Text>
      ) : mutation.isPending ? (
        <Spinner />
      ) : mutation.data?.ok ? (
        mutation.data.body.ok ? (
          <VStack alignItems={'flex-start'} spacing={'20px'}>
            <Text>請求先情報の変更が完了しました</Text>
            <HStack>
              <Text>・請求先：</Text>
              <Text>{mutation.data.body.invoiceName}</Text>
            </HStack>
            <HStack>
              <Text>・請求先メールアドレス：</Text>
              <Text>{mutation.data.body.invoiceEmail}</Text>
            </HStack>
          </VStack>
        ) : (
          <Text>
            請求先情報の変更に失敗しました：
            {mutation.data.body.error}
          </Text>
        )
      ) : (
        <Text>
          請求先情報の変更に失敗しました：
          {String(mutation.error || mutation.data?.error)}
        </Text>
      )}
    </Layout>
  );
};
