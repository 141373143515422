import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { MdError } from 'react-icons/md';
import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { needPayment } from '../../commons/paymentUtil';
import { FixedRight } from '../../components/ui/Fixed';
import { PageTitle } from '../../components/ui/PageTitle';
import { RegistrationLayout } from '../../components/ui/RegistrationLayout';
import { RegistrationStepLineAttach } from '../../components/ui/RegistrationSteps';
import {
  RightArrowBlack,
  RightArrowWhite,
} from '../../components/ui/RightArrow';
import { WebLink } from '../../components/ui/WebLink';
import { useAuthState } from '../../context/providerAccount';
import { FirebaseUser } from '../../firebase/firebaseInit';
import { Path } from '../../routers/Path';
import {
  LineOfficialAccountIntro,
  Number1,
  Number2,
  Number3,
  Number4,
  StepTitle,
} from './components';

const _tag = '[line-oa-link]';

export const LineOALinkModuleApi: FC = () => {
  const { firebaseUser, providerAccount, initialized, status } = useAuthState();
  const toast = useToast();

  console.info(`${_tag}`, {
    providerAccountId: providerAccount?.id,
    firebaseUserId: firebaseUser?.uid,
    initialized,
    status,
  });

  setDocumentTitle('ポチコLINE連携');

  if (initialized && !firebaseUser) {
    toast({
      title:
        'ログインに失敗しました。セッション切れの可能性があるので、次のページから再度ログインしてください。',
      status: 'error',
    });
    return <Navigate to={Path.login} />;
  } else if (initialized && providerAccount) {
    if (needPayment(providerAccount)) {
      return <Navigate to={Path.unpaid} />;
    } else if (
      providerAccount.lineChannelType === 'moduleApi' &&
      providerAccount.status === 'attached'
    ) {
      toast({
        title: 'すでにLINE連携が完了しています',
        status: 'info',
      });
      return <Navigate to={Path.home} />;
    } else if (providerAccount.lineChannelType === 'messagingApi') {
      // Messaging API -> Module APIは連携方式を変更できない
      toast({
        title: 'Messaging APIで連携済みです',
        status: 'info',
      });
      return <Navigate to={Path.home} />;
    }
  }

  return (
    <RegistrationLayout showLogoutButton={true}>
      <VStack alignItems={'center'} paddingX={'16px'}>
        {!initialized ? (
          <HStack
            alignItems={'center'}
            justifyContent={'center'}
            height={'30vh'}
            fontSize={'2xl'}
          >
            <Spinner />
            <Text>Loading... ステータス: {status}</Text>
          </HStack>
        ) : (
          <LineAttach initialized={initialized} firebaseUser={firebaseUser} />
        )}
      </VStack>
    </RegistrationLayout>
  );
};

const LineAttach: React.FC<{
  initialized: boolean;
  firebaseUser: FirebaseUser | undefined;
}> = ({ initialized, firebaseUser }) => {
  return (
    <Flex
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      maxWidth={'990px'}
      marginX={'auto'}
    >
      <Spacer mt={10} />
      <RegistrationStepLineAttach />
      <Spacer mt={10} />
      <PageTitle>つづいてLINE連携を行います</PageTitle>
      <Spacer mt={8} />
      <Text whiteSpace={'pre-wrap'}>
        手順をご確認の上、「LINE連携をする」ボタンを押し、移動した画面で設定をお願いいたします。
        <br />
        LINE公式アカウントがない方は事前に開設が必要です。
      </Text>
      <Spacer mt={8} />
      <PageTitle>LINE連携の流れ</PageTitle>

      <HStack
        backgroundColor={'gray.100'}
        overflowX={'scroll'}
        w={{ base: 'fit-content', md: 'max-content' }}
        padding={8}
        spacing={4}
      >
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number1 />
            <StepTitle>LINEアカウントにログイン</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_attach_step1.png'}
              alt={'LINEアカウントにログイン'}
              maxHeight={{ base: '60vh', md: '477px' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number2 />
            <StepTitle>紐付けるアカウントを選択</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_attach_step2.png'}
              alt={'紐付けるアカウントを選択'}
              maxHeight={{ base: '60vh', md: '477px' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number3 />
            <StepTitle>「連携」をタップ</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_attach_step3.png'}
              alt={'「連携」をタップ'}
              maxHeight={{ base: '60vh', md: '477px' }}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number4 />
            <StepTitle>連携完了</StepTitle>
          </HStack>
          <Box>
            <Image
              src={'/assets/line_attach_step4.png'}
              alt={'連携完了'}
              maxHeight={{ base: '60vh', md: '477px' }}
            />
          </Box>
        </VStack>
      </HStack>

      <Spacer mt={8} />
      <Text>
        連携がスムーズにできない場合は
        <WebLink
          href="https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1"
          target="_blank"
          rel="noopener noreferrer"
          color={'black'}
        >
          ヘルプ
        </WebLink>
        をご参照ください。
      </Text>
      <Spacer mt={12} />

      <VStack
        alignItems={'center'}
        w={'full'}
        paddingX={'16px'}
        spacing={'24px'}
      >
        <Link
          to={`${GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN}/attach/${GLOBAL_CONFIG.SERVICE_NAME.UNIQ_NAME_EN}/auth?uid=${firebaseUser?.uid}`}
        >
          <Button
            isLoading={!initialized}
            // disabled={isDisabled}
            colorScheme={'green'}
            width={['full', '452px']}
            borderRadius={20}
          >
            {'自分でLINE連携をする'}
            <FixedRight>
              <RightArrowWhite />
            </FixedRight>
          </Button>
        </Link>

        {/* <Link
          to={
            'https://docs.google.com/forms/d/e/1FAIpQLSdXxA91PSNGvQx5K079MMPOzwb_vrD7h79z23bYOOfmRBXB7w/viewform'
          }
          target="_blank"
        >
          <Button
            isLoading={!initialized}
            // disabled={isDisabled}
            colorScheme={'green'}
            width={['full', '452px']}
            borderRadius={20}
          >
            {'LINE連携を代行依頼する（無料）'}
            <FixedRight>
              <RightArrowWhite />
            </FixedRight>
          </Button>
        </Link> */}
      </VStack>

      {/* 
      Messaging APIをセルフサインアップ出来るようにするときに使う
      <WebLink
        fontSize={'sm'}
        href={Path.lineOaLinkMessagingApi}
        marginTop={8}
        width={['full', '452px']}
      >
        {'LINE Messaging APIを使った連携をする'}
      </WebLink> */}
      <Spacer mt={12} />
      <HStack
        alignItems={'flex-start'}
        w={'full'}
        p={'25px'}
        borderWidth={'1px'}
        borderColor={'#CCCCCC'}
        borderRadius={'10px'}
      >
        <Icon as={MdError} color={'yellow.400'} boxSize={'32px'} />
        <VStack alignItems={'flex-start'} gap={'8px'}>
          <Text fontWeight={'bold'}>LINE連携が不安な方・うまくいかない方</Text>
          <HStack w={'full'} gap={'12px'}>
            <Text textAlign={'left'}>
              ポチコスタッフが連携をサポートします。用語がわからない、初めてなので不安がある、変なページになってしまった等ありましたらお気軽にご依頼ください。
            </Text>
            <Box h={'full'} w={{ base: 'full', md: '280px' }}>
              <Button
                w={{ base: 'full', md: '280px' }}
                // colorScheme={'white'}
                to={
                  'https://docs.google.com/forms/d/e/1FAIpQLSdXxA91PSNGvQx5K079MMPOzwb_vrD7h79z23bYOOfmRBXB7w/viewform'
                }
                target={'_blank'}
                as={Link}
                color={'black'}
                backgroundColor={'white'}
                width={'full'}
                height={'50px'}
                borderRadius={40}
                border={'1px solid #000'}
                fontSize={'16px'}
              >
                連携代行を依頼
                <FixedRight>
                  <RightArrowBlack />
                </FixedRight>
              </Button>
            </Box>
          </HStack>
        </VStack>
      </HStack>
      <Spacer mt={12} />

      <LineOfficialAccountIntro />
    </Flex>
  );
};
