import {
  Box,
  Button,
  Checkbox,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMenuState,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  dateTimeStringWithWeekDay,
  LineUser,
  ProviderAccount,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { MdExpandMore } from 'react-icons/md';
import {
  BulkActionContextProvider,
  useBulkActionContext,
} from '../../../context/bulkAction';
import { DisplayLineUser } from '../../../firebase/types';
import { paginationAtom } from '../../../helpers/perPage';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import {
  useBulkUpdateLineUserMutation,
  useFetchLineUserCount,
  useFetchLineUsers,
} from '../../../hooks/lineUser';
import { createLocationFrom, LocationFrom } from '../../../hooks/locationState';
import { useIsPC } from '../../../hooks/useIsPC';
import { useShowTooManyResultWarning } from '../../../hooks/useShowTooManyResultWarning';
import { useUrlParamState } from '../../../hooks/useUrlParamState';
import { resourcePath } from '../../../hooks/useUrlPath';
import { getLineUserList } from '../../../providers/dataProvider/lineUser';
import {
  CsvDownloadButton,
  CsvDownloadHeader,
} from '../../ui/CsvDownloadButton';
import { Layout } from '../../ui/Layout';
import { useLoadingOverlayContext } from '../../ui/LoadingOverlay';
import { NavigationButton } from '../../ui/NavigationButton';
import { PaginationIndicator } from '../../ui/PaginationIndicator';
import { SortableTableHeader } from '../../ui/SortableTableHeader';
import { LineUserFilterUI } from '../LineUsers';
import { LineUserFilterInputs } from './LineUserFilterInputs';

const lineUserListPagingAtom = paginationAtom<LineUser>({
  page: 1,
  sort: {
    field: 'lastBookedAt',
    direction: 'desc',
  },
});
export const LineUserList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const now = React.useMemo(() => dayjs(), []);
  const [paging, setPaging] = useAtom(lineUserListPagingAtom);
  const { urlState: filter, setUrlState: setFilter } =
    useUrlParamState<LineUserFilterUI>(
      ['displayName', 'displayNameByProvider', 'status', 'lastBookedAt'],
      {
        defaultValue: {
          status: 'active',
          lastBookedAt: {
            start: now.add(-6, 'month').format('YYYY-MM-DD'),
            end: now.format('YYYY-MM-DD'),
          },
        },
      }
    );
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });

  const countQuery = useFetchLineUserCount({
    providerAccount,
    filter: {
      ...filter,
      archived: false,
    },
  });
  const query = useFetchLineUsers({
    providerAccount,
    filter: {
      ...filter,
      archived: false,
    },
    perPage: paging.perPage,
    sort: paging.sort,
    page: paging.page || 1,
  });
  const onClickColumnHeader = React.useCallback(
    (column: keyof LineUser) => {
      // 同じカラムを選択すると昇順降順を入れ替える
      // 別のカラムなら昇順に
      const direction =
        paging.sort.field === column
          ? paging.sort.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';
      setPaging((paging) => ({
        ...paging,
        page: 1,
        lastCursor: undefined,
        sort: {
          field: column,
          direction,
        },
      }));
    },
    [paging.sort.direction, paging.sort.field, setPaging]
  );

  // const overlayContext = useLoadingOverlayContext();
  // React.useEffect(() => {
  //   if (query.isFetchingPage && !overlayContext.isOpen) {
  //     overlayContext.onOpen();
  //     return;
  //   }
  //   if (!query.isFetchingPage && overlayContext.isOpen) {
  //     overlayContext.onClose();
  //   }
  // }, [overlayContext, query.isFetchingPage, query.isLoading]);

  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          providerAccount,
          resourceName: 'lineUser',
          action: 'list',
          urlParams: {
            displayName: filter.displayName || '',
            displayNameByProvider: filter.displayNameByProvider || '',
            lastBookedAt: JSON.stringify(filter.lastBookedAt),
            status: filter.status || '',
          },
        })
      ),
    [filter, providerAccount]
  );
  const isPC = useIsPC();

  useShowTooManyResultWarning(countQuery.data);

  return (
    <BulkActionContextProvider>
      <Layout
        pageTitle={`LINEユーザーの管理`}
        headerAction={
          isPC &&
          countQuery.data !== undefined && (
            <LineUserListMenu
              providerAccount={providerAccount}
              filter={filter}
              totalCount={countQuery.data}
            />
          )
        }
      >
        {query.error && <Text>エラーが発生しました {String(query.error)}</Text>}
        <VStack alignItems={'flex-start'} spacing={'16px'}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <LineUserFilterInputs
              providerAccount={providerAccount}
              filter={filter}
              onFilterChange={(newFilter) => {
                setFilter(newFilter);
                setPaging((paging) => ({
                  ...paging,
                  page: 1,
                  lastCursor: undefined,
                }));
              }}
            />
            {!isPC && countQuery.data !== undefined && countQuery.data > 0 && (
              <LineUserListMenu
                providerAccount={providerAccount}
                filter={filter}
                totalCount={countQuery.data}
              />
            )}
          </HStack>
          {bookingMenuQuery.error || countQuery.error || query.error ? (
            <VStack alignItems={'flex-start'}>
              <Text>エラーが発生しました</Text>
              <Text>
                {`${bookingMenuQuery.error || countQuery.error || query.error}`}
              </Text>
            </VStack>
          ) : !query.isFetching &&
            !countQuery.isFetching &&
            !bookingMenuQuery.isFetching ? (
            <>
              <LineUserListView
                providerAccount={providerAccount}
                lineUsers={query.data || []}
                locationFrom={locationFrom}
                totalCount={countQuery.data || 0}
                onClickColumnHeader={onClickColumnHeader}
              />
              {/* <HStack w={'full'} justifyContent={'center'}>
                {paginationIndicator}
              </HStack> */}
            </>
          ) : (
            <Spinner />
          )}
        </VStack>
      </Layout>
    </BulkActionContextProvider>
  );
};

const LineUserListMenu: React.FC<{
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  totalCount: number;
}> = ({ providerAccount, filter, totalCount }) => {
  return (
    <Box>
      <Menu isLazy initialFocusRef={undefined}>
        <MenuButton
          as={Button}
          variant={'soft-fill'}
          colorScheme="blue"
          leftIcon={<MdExpandMore />}
        >
          その他の機能
        </MenuButton>
        <MenuList autoFocus={false}>
          <MenuItem closeOnSelect={false} autoFocus={false} as={Box} p={0}>
            <LineUserCsvDownloadButton
              providerAccount={providerAccount}
              filter={filter}
              totalCount={totalCount}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const LineUserListView: React.FC<{
  providerAccount: ProviderAccount;
  lineUsers: DisplayLineUser[];
  totalCount: number;
  locationFrom: LocationFrom;
  onClickColumnHeader: (column: keyof LineUser) => void;
}> = ({
  providerAccount,
  lineUsers,
  locationFrom,
  totalCount,
  onClickColumnHeader,
}) => {
  const isPC = useIsPC();
  const { selectedIds, clearSelectedIds, bulkAction } = useBulkActionContext();
  const bulkUpdateMutation = useBulkUpdateLineUserMutation(providerAccount);
  const toast = useToast();

  const overlayContext = useLoadingOverlayContext();
  const onClickDelete = React.useCallback(
    () =>
      bulkAction((lineUserIds: string[]) => {
        return {
          title: `${lineUserIds.length}件のLINEユーザーを削除しますか？`,
          size: '2xl',
          body: (
            <VStack w={'full'} alignItems={'flex-start'}>
              <Text>
                削除すると、LINEユーザーの一覧ページ等で表示されなくなります。
              </Text>
              <Text>・すでに取られている予約はそのまま残ります。</Text>
              <Text>・削除されていても新しく予約を取ることは可能です。</Text>
              <Text>
                ・新規で予約を取れないようにするためにはブロック機能をお使いください。
              </Text>
            </VStack>
          ),
          cancelText: 'キャンセル',
          submitText: '削除する',
          onSubmit: async () => {
            overlayContext.onOpen();
            const params = lineUserIds.map((id) => ({
              id,
              archived: true,
            }));
            return bulkUpdateMutation
              .mutateAsync(params)
              .then(() => {
                clearSelectedIds();
                toast({
                  title: `選択したLINEユーザーを削除しました`,
                  status: 'success',
                });
              })
              .catch((e) => {
                toast({
                  title: `選択したLINEユーザーの削除に失敗しました ${e}`,
                  status: 'error',
                });
              })
              .finally(overlayContext.onClose);
          },
        };
      }),
    [bulkAction, bulkUpdateMutation, clearSelectedIds, overlayContext, toast]
  );
  const [paging, setPaging] = useAtom(lineUserListPagingAtom);
  const paginationIndicator = React.useMemo(() => {
    return (
      <PaginationIndicator
        total={totalCount}
        perPage={paging.perPage}
        page={paging.page || 1}
        isLoading={false}
        onChange={setPaging}
      />
    );
  }, [paging.page, paging.perPage, setPaging, totalCount]);

  return (
    <VStack spacing={'8px'} w={'full'}>
      {isPC && (
        <HStack w={'full'} alignItems={'center'}>
          {selectedIds.length > 0 && (
            <Button
              minW={'fit-content'}
              colorScheme="red"
              onClick={onClickDelete}
              isLoading={bulkUpdateMutation.isPending}
              isDisabled={selectedIds.length === 0}
            >
              選択した{selectedIds.length}件のLINEユーザーを削除する
            </Button>
          )}
          {paginationIndicator}
        </HStack>
      )}
      <LineUserListTable
        providerAccount={providerAccount}
        lineUsers={lineUsers}
        locationFrom={locationFrom}
        onClickColumnHeader={onClickColumnHeader}
      />
      {isPC ? (
        paginationIndicator
      ) : selectedIds.length > 0 ? (
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text fontWeight={'bold'}>
            {selectedIds.length}件のLINEユーザーを選択中
          </Text>

          <Button
            w={'fit-content'}
            colorScheme={'red'}
            onClick={onClickDelete}
            isLoading={bulkUpdateMutation.isPending}
            isDisabled={selectedIds.length === 0}
          >
            削除する
          </Button>
        </HStack>
      ) : (
        paginationIndicator
      )}
    </VStack>
  );
};

const LineUserListTable: React.FC<{
  providerAccount: ProviderAccount;
  lineUsers: DisplayLineUser[];
  locationFrom: LocationFrom;
  onClickColumnHeader: (column: keyof LineUser) => void;
}> = ({ providerAccount, lineUsers, locationFrom, onClickColumnHeader }) => {
  const { sort } = useAtomValue(lineUserListPagingAtom);
  const { setSelectedIds, clearSelectedIds } = useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds(lineUsers.map((b) => b.id));
      } else {
        clearSelectedIds();
      }
    },
    [clearSelectedIds, lineUsers, setSelectedIds]
  );
  const onClickColumnToChangeSort = React.useCallback(
    (column: keyof LineUser) => {
      return () => onClickColumnHeader(column);
    },
    [onClickColumnHeader]
  );

  return (
    <TableContainer w={'full'}>
      <Table variant="pochico-striped">
        <Thead>
          <Tr height={'3rem'} paddingY={'10px'}>
            <Th textAlign={'center'} minWidth={'fit-content'}>
              <Checkbox bg={'white'} onChange={onCheckboxClicked} />
            </Th>
            <Th>アイコン</Th>
            <Th>LINEユーザー名</Th>
            <Th>ポチコ上の別名</Th>
            <Th>ステータス</Th>
            <SortableTableHeader
              onClick={onClickColumnToChangeSort('lastBookedAt')}
              sortDirection={
                sort.field === 'lastBookedAt' ? sort.direction : undefined
              }
            >
              最終予約作成日
            </SortableTableHeader>
            <Th w={'full'}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {lineUsers.length > 0 ? (
            lineUsers.map((lineUser) => {
              return (
                <LineUserTableRow
                  lineUser={lineUser}
                  key={lineUser.id}
                  providerAccount={providerAccount}
                  locationFrom={locationFrom}
                />
              );
            })
          ) : (
            <Tr>
              <Td colSpan={7}>データがありません</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const LineUserTableRow: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: DisplayLineUser;
  locationFrom: LocationFrom;
}> = ({ providerAccount, lineUser, locationFrom }) => {
  const { selectedIds, setSelectedIds } = useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds([...selectedIds, lineUser.id]);
      } else {
        setSelectedIds(selectedIds.filter((id) => id !== lineUser.id));
      }
    },
    [setSelectedIds, selectedIds, lineUser.id]
  );
  const checked = React.useMemo(() => {
    return selectedIds.includes(lineUser.id);
  }, [lineUser.id, selectedIds]);

  return (
    <Tr justifyContent={'center'} alignSelf={'center'}>
      <Td textAlign={'center'} minWidth={'fit-content'}>
        <Checkbox
          bg={'white'}
          onChange={onCheckboxClicked}
          isChecked={checked}
        />
      </Td>
      <Td verticalAlign={'middle'}>
        <Image src={lineUser.pictureUrlLarge} borderRadius={'full'} />
      </Td>
      <Td verticalAlign={'middle'}>{lineUser.displayName}</Td>
      <Td verticalAlign={'middle'}>{lineUser.displayNameByProvider}</Td>
      <Td verticalAlign={'middle'}>
        {lineUser.status === 'blocked' ? 'ブロック' : '有効'}
      </Td>
      <Td verticalAlign={'middle'}>
        {dateTimeStringWithWeekDay(dayjs(lineUser.lastBookedAt))}
      </Td>
      <Td verticalAlign={'middle'} textAlign={'right'}>
        <NavigationButton
          size={'sm'}
          variant="white-blue"
          to={{
            providerAccount: providerAccount,
            resourceName: 'lineUser',
            resourceId: lineUser.id,
            action: 'show',
          }}
          locationFrom={locationFrom}
        >
          詳細
        </NavigationButton>
      </Td>
    </Tr>
  );
};

const LineUserCsvDownloadButton = ({
  providerAccount,
  filter,
  totalCount,
}: {
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  totalCount: number;
}) => {
  const { onClose } = useMenuState();
  const [paging] = useAtom(lineUserListPagingAtom);
  const client = useQueryClient();
  const createHeaders = React.useCallback(() => {
    return [
      { field: 'id', label: 'LINEユーザーID' },
      { field: 'displayName', label: '名前' },
      {
        field: (lineUser) => lineUser.displayNameByProvider || '',
        label: 'ポチコ上の別名',
      },
      {
        field: (lineUser) =>
          lineUser.status === 'blocked' ? 'ブロック' : '有効',
        label: 'ステータス',
      },
      {
        field: (lineUser) => lineUser.providerMemo || '',
        label: 'メモ',
      },
      {
        field: (lineUser) =>
          lineUser.lastBookedAt
            ? dayjs(lineUser.lastBookedAt).format('YYYY-MM-DD HH:mm:ss')
            : '',
        label: '最終予約作成日',
      },
    ] as CsvDownloadHeader<LineUser>;
  }, []);
  const query = React.useCallback(async () => {
    const headers = createHeaders();
    return getLineUserList(client, providerAccount.id, {
      filter: {
        ...filter,
        archived: false,
      },
      pagination: {
        perPage: totalCount,
        sort: paging.sort,
      },
    }).then(({ data }) => {
      return { data, headers };
    });
  }, [
    client,
    createHeaders,
    filter,
    paging.sort,
    providerAccount.id,
    totalCount,
  ]);
  return (
    <CsvDownloadButton<LineUser>
      variant={'soft-fill'}
      colorScheme="transparent"
      onDownloadComplete={onClose}
      isDisabled={totalCount === 0}
      m={undefined}
      queryKey="lineUser"
      query={query}
    />
  );
};
