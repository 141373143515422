import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingMenuCreateParams } from '../../../firebase/types';
import { useCreateBookingMenu } from '../../../hooks/bookingMenus';
import { useIsPC } from '../../../hooks/useIsPC';
import { RESOURCE_NAME } from '../../../providers/dataProvider';
import { Layout } from '../../ui/Layout';
import { LimitDateTimeInput } from '../../ui/LimitDateTimeInput';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { useImageInputForm } from '../../ui/useImageInputForm';
import { BookingMenuPreview } from './BookingMenuPreview';
import { BookingSuccessPreview } from './BookingSuccess';
import { BookingSuccessMessageInput } from './BookingSuccessMessageInput';

export const BookingMenuCreate: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const toast = useToast();
  const resource: string = React.useMemo(() => {
    if (providerAccount) {
      return `${providerAccount.id}/${providerAccount.botId}/${RESOURCE_NAME.BOOKING_MENU}`;
    } else {
      return '';
    }
  }, [providerAccount]);

  const form = useForm<BookingMenuCreateParams & { useImage: boolean }>({
    defaultValues: {
      status: 'suspended',
      displayPriority: 1,
      customText: {
        bookingSuccess: providerAccount?.customText?.bookingSuccess, // providerAccount単位で設定されているものがあればそちらを表示する
      },
      useImage: true,
    },
  });

  // サイズに対するバリデーションは一旦なし
  // https://developers.line.biz/flex-simulator/
  const { component: ImageForm, file: imageFile } = useImageInputForm({
    form,
    formLabel: '画像(横:縦 = 2:1)',
    imageUrl: undefined,
    defaultImagePath: '/assets/booking-menu-default-image.png',
    validator: async (f) => {
      return undefined;
    },
  });
  const { control } = form;

  const [inputName, inputDescription, inputBookingSuccessMessage, useImage] =
    useWatch({
      control,
      name: ['name', 'description', 'customText.bookingSuccess', 'useImage'],
    });
  const inputImageUrl = React.useMemo(() => {
    return imageFile ? URL.createObjectURL(imageFile) : undefined;
  }, [imageFile]);

  const navigate = useNavigate();
  const mutation = useCreateBookingMenu(providerAccount);
  const isPC = useIsPC();

  const onSubmit = React.useCallback(
    async (inputValue: BookingMenuCreateParams) => {
      const imageData = imageFile
        ? {
            imageData: {
              rawFile: imageFile,
              title: imageFile?.name,
            },
          }
        : {};
      const data: BookingMenuCreateParams = {
        ...inputValue,
        ...imageData,
      };

      return mutation
        .mutateAsync(data)
        .then(async ({ data }) => {
          toast({
            title: '予約メニューを作成しました。',
            status: 'success',
          });
          navigate(`/${resource}/${data.id}/show`);
        })
        .catch((e) => {
          toast({
            title: `予約メニューの作成に失敗しました error: ${e}`,
            status: 'error',
          });
        });
    },
    [imageFile, mutation, navigate, resource, toast]
  );

  return (
    <Layout
      pageTitle="予約メニューの作成"
      hasBackButton={{
        action: 'list',
        resourceName: 'bookingMenu',
        providerAccount,
      }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            width: '100%',
          }}
        >
          {isPC ? (
            <HStack
              w={'full'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'32px'}
            >
              <VStack
                width={'full'}
                spacing={'20px'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexGrow={1}
              >
                <VStack
                  alignItems={'flex-start'}
                  backgroundColor={'white'}
                  py={'16px'}
                  w={'full'}
                  m={0}
                  divider={<StackDivider borderColor="gray.200" />}
                  borderWidth={'1px'}
                  borderColor={'gray.200'}
                  spacing={'12px'}
                >
                  <BookingMenuBasicInformationEdit ImageForm={ImageForm} />
                  <BookingMenuConfigurationEdit />

                  <Box p={'16px'} w={'full'}>
                    <BookingSuccessMessageInput
                      providerAccount={providerAccount}
                    />
                  </Box>
                </VStack>
                <HStack w={'full'} mt={'24px'} justifyContent={'flex-end'}>
                  <Button
                    type={'submit'}
                    variant={'blue-fill'}
                    isLoading={mutation.isPending}
                  >
                    保存する
                  </Button>
                </HStack>
              </VStack>
              <VStack
                flexGrow={1}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                p={'20px'}
                alignItems={'flex-start'}
                {...(providerAccount.needBookingMenu
                  ? {}
                  : { display: 'none' })}
                spacing={'24px'}
                width={'full'}
                backgroundColor={'white'}
              >
                <Text fontSize={'lg'} fontWeight={'bold'}>
                  プレビュー
                </Text>
                <PreviewContainer label={'予約メニュー'}>
                  <BookingMenuPreview
                    bookingMenu={{
                      name: inputName || '',
                      description: inputDescription || '',
                      imageUrl: useImage ? inputImageUrl || '' : undefined,
                    }}
                    showImage={useImage}
                  />
                </PreviewContainer>

                <BookingSuccessPreview
                  providerAccount={providerAccount}
                  bookingSuccessMessage={inputBookingSuccessMessage}
                />
              </VStack>
            </HStack>
          ) : (
            <VStack
              width={'full'}
              spacing={'20px'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              flexGrow={1}
            >
              <VStack
                alignItems={'flex-start'}
                backgroundColor={'white'}
                py={'16px'}
                w={'full'}
                m={0}
                divider={<StackDivider borderColor="gray.200" />}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                spacing={'12px'}
              >
                <BookingMenuBasicInformationEdit ImageForm={ImageForm} />
                <VStack
                  flexGrow={1}
                  borderWidth={'1px'}
                  borderColor={'gray.200'}
                  p={'20px'}
                  alignItems={'flex-start'}
                  {...(providerAccount.needBookingMenu
                    ? {}
                    : { display: 'none' })}
                  spacing={'24px'}
                  width={'full'}
                  backgroundColor={'white'}
                >
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    プレビュー
                  </Text>
                  <VStack spacing={0} w={'full'}>
                    <Box w={'full'} p={'8px'} bgColor={'gray.100'}>
                      <Text
                        color={'gray.500'}
                        textAlign={'center'}
                        w={'full'}
                        fontWeight={'bold'}
                      >
                        予約メニュー
                      </Text>
                    </Box>
                    <BookingMenuPreview
                      bookingMenu={{
                        name: inputName || '',
                        description: inputDescription || '',
                        imageUrl: useImage ? inputImageUrl || '' : undefined,
                      }}
                      showImage={useImage}
                    />
                  </VStack>
                </VStack>
                <BookingMenuConfigurationEdit />

                <Box p={'16px'} w={'full'}>
                  <BookingSuccessMessageInput
                    providerAccount={providerAccount}
                  />
                </Box>
                <Box p={'16px'} w={'full'}>
                  <BookingSuccessPreview
                    providerAccount={providerAccount}
                    bookingSuccessMessage={inputBookingSuccessMessage}
                  />
                </Box>
              </VStack>
              <HStack w={'full'} mt={'24px'} justifyContent={'flex-end'}>
                {/* <DeleteBookingMenuButton
                  providerAccount={providerAccount}
                  bookingMenu={bookingMenu}
                /> */}
                <Button
                  type={'submit'}
                  variant={'blue-fill'}
                  w={'full'}
                  isLoading={mutation.isPending}
                >
                  保存する
                </Button>
              </HStack>
            </VStack>
          )}
        </form>
      </FormProvider>
    </Layout>
  );
};

const BookingMenuBasicInformationEdit: React.FC<{
  ImageForm: React.FC;
}> = ({ ImageForm }) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BookingMenuCreateParams & { useImage: boolean }>();
  const useImage = useWatch({ control, name: 'useImage' });

  return (
    <VStack w={'full'} alignItems={'flex-start'} p={'16px'} spacing={'16px'}>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        基本情報
      </Text>
      <FormControl isRequired={true} isInvalid={!!errors.name}>
        <FormLabel fontWeight={'bold'}>メニュー名</FormLabel>
        <Input
          maxWidth={'full'}
          type={'text'}
          placeholder={'パーソナルトレーニング'}
          {...register('name', {
            required: true,
            maxLength: {
              value: 200,
              message: '200文字以内で入力してください。',
            },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={false} isInvalid={!!errors.description}>
        <FormLabel fontWeight={'bold'}>説明文</FormLabel>
        <Textarea
          maxWidth={'full'}
          placeholder={
            '専属トレーナーがお客様に最適なトレーニングプログラムを作成します。'
          }
          {...register('description', {
            required: false,
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <VStack w={'full'} alignItems={'flex-start'}>
        <HStack
          w={'full'}
          justifyContent={{
            base: 'space-between',
            md: 'flex-start',
          }}
          alignItems={'center'}
          spacing={{ base: undefined, md: '36px' }}
        >
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              画像エリアの表示
            </Text>
            <Text fontSize={'sm'}>メニューエリア内に画像を表示します。</Text>
          </VStack>
          <Switch
            isChecked={useImage}
            onChange={() => setValue('useImage', !useImage)}
          />
        </HStack>
        {useImage && (
          <Box maxW={'full'}>
            <ImageForm />
          </Box>
        )}
        {/* <Icon as={HighlightOff} color={'red'} /> */}
      </VStack>
    </VStack>
  );
};

const BookingMenuConfigurationEdit: React.FC = () => {
  return (
    <VStack
      w={'full'}
      p={0}
      divider={<StackDivider borderColor="gray.200" />}
      spacing={'12px'}
    >
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput source="bookingStart" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput source="bookingEnd" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput source="cancelEnd" />
      </Box>
    </VStack>
  );
};

//       <VStack alignItems={'flex-start'} w={'full'} mt={'24px'}>
//         <FormProvider {...form}>
//           <form
//             onSubmit={form.handleSubmit(onSubmit)}
//             style={{
//               width: '100%',
//             }}
//           >
//             <VStack alignItems={'flex-start'} minWidth={'full'}>
//               <Text size={'3xl'} fontWeight={'bold'}>
//                 トーク画面の表示設定
//               </Text>
//               <Flex
//                 backgroundColor={'white'}
//                 direction={{ base: 'column', lg: 'row' }}
//                 width={'full'}
//                 justifyContent={'space-between'}
//                 alignItems={'flex-start'}
//                 borderRadius={'4px'}
//                 border={{ base: undefined, lg: '1px solid #DEDEDE' }}
//                 padding={'16px'}
//                 gap={'32px'}
//               >
//                 <VStack
//                   alignItems={'flex-start'}
//                   spacing={'24px'}
//                   width={{ base: 'full', lg: '60%' }}
//                   backgroundColor={'white'}
//                 >
//                   <VStack w={'full'} alignItems={'flex-start'}>
//                     <HStack
//                       w={'full'}
//                       justifyContent={'space-between'}
//                       alignItems={'center'}
//                     >
//                       <VStack alignItems={'flex-start'} whiteSpace={'nowrap'}>
//                         <Text fontSize={'lg'} fontWeight={'bold'}>
//                           画像エリアの表示
//                         </Text>
//                         <Text fontSize={'sm'}>
//                           メニューエリア内に画像を表示します。
//                         </Text>
//                       </VStack>
//                       <Switch
//                         isChecked={useImage}
//                         onChange={() => setUseImage(!useImage)}
//                       />
//                     </HStack>
//                     {useImage && (
//                       <Box maxW={'full'}>
//                         <ImageForm />
//                       </Box>
//                     )}
//                     {/* <Icon as={HighlightOff} color={'red'} /> */}
//                   </VStack>
//                   <FormControl isRequired={true} isInvalid={!!errors.name}>
//                     <FormLabel>メニュー名</FormLabel>
//                     <Input
//                       maxWidth={'full'}
//                       type={'text'}
//                       placeholder={'パーソナルトレーニング'}
//                       {...register('name', {
//                         required: true,
//                       })}
//                     />
//                     <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
//                   </FormControl>
//                   <FormControl
//                     isRequired={false}
//                     isInvalid={!!errors.description}
//                   >
//                     <FormLabel>説明文</FormLabel>
//                     <Textarea
//                       maxWidth={'full'}
//                       placeholder={
//                         '専属トレーナーがお客様に最適なトレーニングプログラムを作成します。'
//                       }
//                       {...register('description', {
//                         required: false,
//                       })}
//                     />
//                     <FormErrorMessage>
//                       {errors.description?.message}
//                     </FormErrorMessage>
//                   </FormControl>
//                 </VStack>
//                 <Spacer marginTop={'12px'} />

//                 <Box px={'16px'}>
//                   <BookingMenuPreview
//                     bookingMenu={{
//                       name: inputName || '',
//                       description: inputDescription || '',
//                       imageUrl: useImage ? inputImageUrl || '' : undefined,
//                     }}
//                     showImage={useImage}
//                   />
//                 </Box>
//               </Flex>

//               <VStack alignItems={'flex-start'} w={'full'} pt={'18px'}>
//                 <Text size={'3xl'} fontWeight={'bold'}>
//                   予約できる条件の設定
//                 </Text>
//                 <VStack
//                   backgroundColor={'white'}
//                   alignItems={'flex-start'}
//                   w={'full'}
//                   borderWidth={'1px'}
//                   padding={'12px'}
//                   spacing={'16px'}
//                   borderColor={'gray.200'}
//                   borderRadius={'4px'}
//                   border={{ base: undefined, lg: '1px solid #DEDEDE' }}
//                   divider={<Divider borderColor={'gray.300'} />}
//                 >
//                   <LimitDateTimeInput source="bookingStart" />
//                   <LimitDateTimeInput source="bookingEnd" />
//                   <LimitDateTimeInput source="cancelEnd" />
//                   <BookingSuccessMessageInput
//                     providerAccount={providerAccount}
//                   />
//                 </VStack>
//               </VStack>
//               <HStack w={'full'} justifyContent={'flex-end'}>
//                 <Button
//                   type={'submit'}
//                   size={'md'}
//                   variant={'blue-fill'}
//                   isLoading={mutation.isPending}
//                 >
//                   保存する
//                 </Button>
//               </HStack>
//             </VStack>
//           </form>
//         </FormProvider>
//       </VStack>
//     </Layout>
//   );
// };
