import { Flex } from '@chakra-ui/react';
import React from 'react';

export const LineBackground: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex
      padding={'6'}
      backgroundColor={'line.talk'}
      borderRadius={'base'}
      width={'full'}
      justifyContent={'center'}
    >
      {children}
    </Flex>
  );
};
