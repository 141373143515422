import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const WebLink = (props: LinkProps) => {
  return (
    <ChakraLink
      as={RouterLink}
      to={props.href}
      textDecoration={'underline'}
      color={'blue'}
      {...props}
    />
  );
};
