import { Heading, HeadingProps } from '@chakra-ui/react';
import React from 'react';

export const PageTitle = (
  props: HeadingProps & { children: React.ReactNode }
) => {
  return (
    <Heading as="h1" fontSize={'3xl'} fontWeight={'bold'} my={3} {...props}>
      {props.children}
    </Heading>
  );
};
