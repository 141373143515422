import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Booking,
  LineUser,
  ProviderAccount,
  isNotNullOrUndefined,
} from '@pochico/shared';
import React from 'react';
import CONSTANTS from '../../commons/constants';
import { DisplaySpot } from '../../firebase/types';
import { useFetchLineUsers } from '../../hooks/lineUser';
import { LocationFrom } from '../../hooks/locationState';
import { useIsPC } from '../../hooks/useIsPC';
import { resourcePath } from '../../hooks/useUrlPath';
import { useDialogDispatcher } from './Dialog';
import { Link } from './Link';

export const BookingUserList: React.FC<{
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  bookings: Booking[];
  locationFrom: LocationFrom;
}> = ({ providerAccount, spot, bookings, locationFrom }) => {
  const lineUsers = useFetchLineUsers({
    providerAccount,
    filter: {
      ids:
        bookings
          ?.map((booking) => booking.lineUserId)
          .filter(isNotNullOrUndefined) || [],
      archived: false,
    },
    perPage: bookings.length,
    sort: { field: 'id', direction: 'asc' },
    page: 1,
  });

  const isPC = useIsPC();
  const elements = React.useMemo(() => {
    if (lineUsers.isLoading) {
      return '';
    }
    return bookings
      .slice(
        0,
        isPC
          ? CONSTANTS.MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_PC
          : CONSTANTS.MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_SP
      )
      .map((booking) => {
        const key = `booking-${booking.id}-${
          booking.lineUserId || booking.userName
        }`;
        return (
          <BookedUserBadge
            key={key}
            providerAccount={providerAccount}
            booking={booking}
            lineUser={lineUsers.data?.find(
              (user) => user.id === booking.lineUserId
            )}
            locationFrom={locationFrom}
          />
        );
      });
  }, [
    bookings,
    isPC,
    lineUsers.data,
    lineUsers.isLoading,
    locationFrom,
    providerAccount,
  ]);
  return (
    <HStack
      spacing={'2px'}
      w={isPC ? '300px' : '180px'}
      maxW={isPC ? '300px' : '180px'}
      // minW={'fit-content'}
      px={'8px'}
      py={'8px'}
      // overflowX={'scroll'}
      // overflowWrap={'break-word'}
      display={'flex'} // コピーしたときに改行が入らないようにするためにはinline要素にしたい
      // overflowY={'hidden'}
      boxSizing={'content-box'}
      // whiteSpace={'nowrap'}
      flexWrap={'wrap'}
      gap={'4px'}
      style={{
        scrollbarWidth: 'thin',
        scrollPaddingLeft: '8px',
      }}
      divider={
        // <>&nbsp;</>
        // コピーしたときにスペースが入るように
        <Box
          display={'inline'}
          bgColor={'white'}
          borderWidth={0}
          borderColor={'transparent'}
        >
          {' '}
        </Box>
      }
    >
      {elements}
      <ShowMoreButton
        providerAccount={providerAccount}
        spot={spot}
        bookings={bookings}
        lineUsers={lineUsers.data || []}
        locationFrom={locationFrom}
      />
    </HStack>
  );
};

const ShowMoreButton: React.FC<{
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  bookings: Booking[];
  lineUsers: LineUser[];
  locationFrom: LocationFrom;
}> = ({ providerAccount, spot, bookings, lineUsers, locationFrom }) => {
  const { open } = useShowMoreDialog({
    providerAccount,
    spot,
    bookings,
    lineUsers,
    locationFrom,
  });
  const isPC = useIsPC();
  const maxLen = React.useMemo(() => {
    return isPC
      ? CONSTANTS.MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_PC
      : CONSTANTS.MAX_BOOKING_USER_NUM_PER_SPOT_IN_LIST_VIEW_SP;
  }, [isPC]);
  if (bookings.length <= maxLen) {
    return <></>;
  }
  return (
    <Button
      borderRadius={'20px'}
      borderWidth={'1px'}
      borderColor={'gray.200'}
      variant={'white-selectable'}
      onClick={open}
      color={'gray.500'}
      px={'12px'}
      py={'4px'}
    >
      {bookings.length - maxLen}+
    </Button>
  );
};

export const BookedUserBadge: React.FC<{
  providerAccount: ProviderAccount;
  booking: Booking;
  lineUser: LineUser | undefined;
  locationFrom: LocationFrom;
  to?: 'lineUser' | 'booking';
}> = ({ providerAccount, booking, lineUser, locationFrom, to }) => {
  const href = React.useMemo(() => {
    return resourcePath({
      action: 'show',
      providerAccount,
      ...(to === 'lineUser' && lineUser
        ? { resourceName: 'lineUser', resourceId: lineUser.id }
        : {
            resourceName: 'booking',
            resourceId: booking.id,
          }),
    });
  }, [booking.id, lineUser, providerAccount, to]);

  // if (booking.userName) {
  //   return <Text>{booking.userName}</Text>;
  // }

  return (
    <Link
      locationFrom={locationFrom}
      variant={'subtle'}
      colorScheme={booking.userName ? 'gray' : 'blue'}
      // display={'inline-list-item'}
      to={href}
      borderRadius={'2px'}
      // minW={'5em'}
      w={'fit-content'}
      // w={'full'}
      px={'4px'}
      py={'3px'}
      minH={'20px'}
      h={'fit-content'}
      textDecoration={'underline'}
      whiteSpace={'nowrap'}
      textAlign={'left'}
    >
      {booking.userName
        ? booking.userName
        : lineUser
        ? lineUser.displayNameByProvider || lineUser.displayName
        : booking.lineUserId}
    </Link>
  );
};

const useShowMoreDialog = ({
  providerAccount,
  spot,
  bookings,
  lineUsers,
  locationFrom,
}: {
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  bookings: Booking[];
  lineUsers: LineUser[];
  locationFrom: LocationFrom;
}) => {
  const { openDialog, isOpen } = useDialogDispatcher();
  const open = React.useCallback(async () => {
    if (isOpen) {
      return;
    }
    return openDialog({
      size: 'lg',
      closeOnOverlayClick: true,
      body: (
        <DialogBody
          providerAccount={providerAccount}
          spot={spot}
          bookings={bookings}
          lineUsers={lineUsers}
          locationFrom={locationFrom}
        />
      ),
    });
  }, [
    isOpen,
    openDialog,
    providerAccount,
    spot,
    bookings,
    lineUsers,
    locationFrom,
  ]);
  return {
    open,
  };
};

const DialogBody = ({
  providerAccount,
  spot,
  bookings,
  lineUsers,
  locationFrom,
}: {
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  bookings: Booking[];
  lineUsers: LineUser[];
  locationFrom: LocationFrom;
}) => {
  const { closeDialog } = useDialogDispatcher();
  return (
    <AlertDialogContent marginX={'16px'} maxH={'80vh'} overflowY={'auto'}>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" alignItems={'center'}>
        <VStack w={'full'} alignItems={'flex-start'} justifyContent={'center'}>
          <Text fontSize={'xl'} fontWeight={'bold'} color={'gray.800'}>
            予約ユーザー一覧
          </Text>

          <HStack w={'full'} justifyContent={'space-between'}>
            <Text fontSize={'md'}>
              {spot.displayDate} {spot.startTime}~
            </Text>
            <Text fontSize={'sm'} color={'gray.500'}>
              枠数：{spot.maxBookings}件 現在の予約数{bookings.length}件
            </Text>
          </HStack>
        </VStack>
        <AlertDialogCloseButton />
      </AlertDialogHeader>

      <AlertDialogBody>
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          divider={<Divider borderColor={'gray.200'} />}
          spacing={'6px'}
        >
          {bookings.map((booking, i) => {
            const lineUser = lineUsers.find(
              (user) => user.id === booking.lineUserId
            );
            return (
              <HStack
                key={booking.id}
                alignItems={'center'}
                justifyContent={'flex-start'}
                w={'full'}
              >
                <Text>{i + 1}. </Text>
                {booking.userName ? (
                  <Text>{booking.userName}</Text>
                ) : (
                  <Link
                    color="blue"
                    textDecoration={'underline'}
                    onClick={closeDialog}
                    locationFrom={locationFrom}
                    to={resourcePath({
                      action: 'show',
                      resourceName: 'booking',
                      resourceId: booking.id,
                      providerAccount,
                    })}
                  >
                    {lineUser?.displayNameByProvider ||
                      lineUser?.displayName ||
                      booking.lineUserId}
                  </Link>
                )}
              </HStack>
            );
          })}
        </VStack>
      </AlertDialogBody>
      <AlertDialogFooter />
    </AlertDialogContent>
  );
};
