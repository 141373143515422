import { Flex, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from 'firebase/firestore';
import CONSTANTS from '../../../commons/constants';
import { getRemainDates } from '../../../commons/paymentUtil';
import { db } from '../../../firebase/firebaseInit';
import { RESOURCE_NAME } from '../../../providers/dataProvider';
import { BeginnerMark } from './BeginnerMark';
import { OnboardingStep } from './OnboardingStep';

type Props = {
  providerAccount: ProviderAccount;
};
export const CheckList: React.FC<Props> = ({ providerAccount }) => {
  const { remain } = getRemainDates(providerAccount);
  const [doneStep1, Step1] = useStep1({ providerAccount });
  const [doneStep2, Step2] = useStep2({ providerAccount });
  const [doneStep3, Step3] = useStep3({ providerAccount });
  const [doneStep4, Step4] = useStep4({ providerAccount });

  const statusList = [doneStep1, doneStep2, doneStep3, doneStep4];
  if (remain < 0 && statusList.every((s) => s === 'done')) {
    // 無料トライアルが終わっている && 全部終わってたら出さない
    return <></>;
  } else if (statusList.includes('loading')) {
    return <Spinner />;
  }

  return (
    <Flex
      backgroundColor={'#92C9B2'}
      paddingX={2}
      paddingY={10}
      justifyContent={'center'}
      maxW={'full'}
    >
      <VStack
        maxW={'full'}
        justifyContent={'center'}
        backgroundColor={'white'}
        borderRadius={'2xl'}
        padding={{ base: 4, sm: 8 }}
        spacing={8}
      >
        <HStack
          justifyContent={'center'}
          backgroundColor={'#EFF5F3'}
          paddingTop={'3'}
          paddingBottom={'3'}
          width={'full'}
        >
          <Text>はじめての方へ</Text>
          <BeginnerMark />{' '}
        </HStack>
        <VStack spacing={4}>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
        </VStack>
      </VStack>
    </Flex>
  );
};

type StepStatus = 'loading' | 'not yet' | 'done';
type UseOnboardingStep<Props> = (prop: Props) => [StepStatus, React.VFC];

const useStep1: UseOnboardingStep<Props> = ({}) => [
  'done',
  () => (
    <OnboardingStep
      done={true}
      stepIndex={1}
      text={'ポチコとLINEを連携させてみよう'}
    />
  ),
];

const useStep2: UseOnboardingStep<Props> = ({ providerAccount }) => {
  const [status, setStatus] = useState<StepStatus>('loading');

  useEffect(() => {
    if (!providerAccount.needBookingMenu) {
      // 予約メニューのON/OFFを切り替えている場合(デフォルトではONになっている)
      setStatus('done');
      return;
    }

    const _collection = collection(
      db,
      CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
      providerAccount.id,
      CONSTANTS.COLLECTION.SUB_BOOKING_MENU
    );

    const nonDefaultMenuP = getDocs(
      query(
        _collection,
        where('id', '!=', CONSTANTS.DEFAULT_BOOKING_MENU_ID),
        limit(1)
      )
    );

    const defaultMenuP = getDoc(
      doc(_collection, CONSTANTS.DEFAULT_BOOKING_MENU_ID)
    );

    Promise.all([nonDefaultMenuP, defaultMenuP]).then(
      ([menuSnap, defaultSnap]) => {
        if (menuSnap.size > 0) {
          setStatus('done');
          return;
        }
        const defaultMenu = defaultSnap.data();
        if (defaultMenu && defaultMenu.createTime !== defaultMenu.updateTime) {
          setStatus('done');
          return;
        }

        setStatus('not yet');
      }
    );
  }, [providerAccount]);

  return [
    status,
    () => (
      <OnboardingStep
        done={status === 'done'}
        stepIndex={2}
        text={'予約メニューを作ってみよう'}
        destinationPath={`/${providerAccount.id}/${providerAccount.botId}/${RESOURCE_NAME.BOOKING_MENU}`}
        helpPageLink={
          'https://help.pochico.app/02419971aee24ae78437a6e8e4597c89'
        }
      />
    ),
  ];
};

const useStep3: UseOnboardingStep<Props> = ({ providerAccount }) => {
  const [status, setStatus] = useState<StepStatus>('loading');

  useEffect(() => {
    getDocs(
      query(
        collection(
          db,
          CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
          providerAccount.id,
          CONSTANTS.COLLECTION.SUB_SPOTS
        ),
        limit(1)
      )
    ).then((snap) => {
      setStatus(snap.size > 0 ? 'done' : 'not yet');
    });
  }, [providerAccount]);

  return [
    status,
    () => (
      <OnboardingStep
        done={status === 'done'}
        stepIndex={3}
        text={'予約枠をつくってみよう'}
        destinationPath={`/${providerAccount.id}/${providerAccount.botId}/${RESOURCE_NAME.SPOT}`}
        helpPageLink={
          'https://help.pochico.app/f486f1db04024b7ea4286e96174aeceb'
        }
      />
    ),
  ];
};

const useStep4: UseOnboardingStep<Props> = ({ providerAccount }) => {
  const [status, setStatus] = useState<StepStatus>('loading');

  useEffect(() => {
    getDocs(
      query(
        collection(
          db,
          CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
          providerAccount.id,
          CONSTANTS.COLLECTION.SUB_BOOKINGS
        ),
        limit(1)
      )
    ).then((snap) => {
      setStatus(snap.size > 0 ? 'done' : 'not yet');
    });
  }, [providerAccount]);

  return [
    status,
    () => (
      <OnboardingStep
        done={status === 'done'}
        stepIndex={4}
        text={'ためしに予約を入れてみよう'}
        helpPageLink={
          'https://help.pochico.app/4a4554a246e347a4bb97a1cee3bb0974'
        }
      />
    ),
  ];
};
