import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';

import { Link } from 'react-router-dom';
import { SmileCheckbox } from './SmileCheckbox';

type Props = {
  done: boolean;
  stepIndex: number;
  text: string;
  helpPageLink?: string;
  destinationPath?: string;
};
export const OnboardingStep: React.FC<Props> = (props) => {
  const { done, stepIndex, text, helpPageLink } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const onClick = React.useCallback(() => {
    if (helpPageLink) {
      onOpen();
    }
  }, [helpPageLink, onOpen]);
  return (
    <Box
      width={'full'}
      height={'fit-content'}
      padding={2}
      backgroundColor={'gray.50'}
      borderRadius={'3xl'}
      as={helpPageLink ? Button : Box} // clickableな感じの挙動をあてる
      fontWeight={'normal'}
    >
      <HStack
        direction={'row'}
        width={'full'}
        alignItems={'center'}
        spacing={'4px'}
        onClick={onClick}
        whiteSpace={'pre-wrap'}
        textAlign={'left'}
      >
        <Box margin={2}>
          <SmileCheckbox checked={done} />
        </Box>
        <VStack
          height={'full'}
          alignItems={'flex-start'}
          spacing={1.5}
          marginRight={4}
        >
          <Text>STEP {stepIndex}</Text>
          <Text>{text}</Text>
        </VStack>
        <Spacer />
        <Box>
          <Arrow enabled={!!helpPageLink} />
        </Box>
      </HStack>
      <HelpPageDrawer {...props} onClose={onClose} isOpen={isOpen} />
    </Box>
  );
};

const HelpPageDrawer: React.FC<
  Props & { onClose: () => void; isOpen: boolean }
> = (props) => {
  return (
    <Drawer
      // motionPreset={'slideInBottom'}
      // isCentered={true}
      onClose={props.onClose}
      blockScrollOnMount={true}
      placement={'bottom'}
      // scrollBehavior={'inside'}
      size={'xl'}
      isOpen={props.isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>{props.text}</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Center>
            <VStack
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              maxW={'900px'}
              height={'80vh'}
              paddingBottom={4}
            >
              <Box
                as={'iframe'}
                width={'100%'}
                maxW={'900px'}
                height={'inherit'}
                src={props.helpPageLink}
                border={'2px'}
                borderColor={'gray.200'}
                borderRadius={'xl'}
              />
              {props.destinationPath && (
                <Link to={props.destinationPath}>
                  <Button colorScheme={'green'} marginTop={4}>
                    {props.text}
                  </Button>
                </Link>
              )}
            </VStack>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const Arrow: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  const fill = enabled ? '#239D6B' : '#D9D9D9';
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23047 20.73L8.00047 22.5L18.0005 12.5L8.00047 2.5L6.23047 4.27L14.4605 12.5L6.23047 20.73Z"
        fill={fill}
      />
    </svg>
  );
};
