import { Checkbox } from './Checkbox';
import { linkStyle } from './Link';
import { Select } from './Select';
import { Box } from './box';
import { buttonStyle } from './button';
import { Input } from './input';
import { Menu } from './menu';
import { tableStyle } from './table';
import { Textarea } from './textArea';

export const components = {
  Button: buttonStyle,
  ...tableStyle,
  Box,
  Select,
  Input,
  Checkbox,
  Link: linkStyle,
  Menu,
  Textarea,
};
