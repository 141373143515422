import {
  Box,
  ListItem,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { PaymentDateCalculator, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { WebLink } from '../../ui/WebLink';

const information: {
  title: string;
  body: (providerAccount: ProviderAccount) => React.ReactElement;
  condition?: (providerAccount: ProviderAccount) => boolean;
  startDate: string;
  endDate: string;
}[] = [
  {
    title: '🍒 管理者ツールがリニューアルしました！ 🍒',
    body: (providerAccount) => (
      <Text fontSize={'sm'}>
        今後より機能を拡充していくため、デザイン・レイアウトの変更を行いました。
        <br />
        「ホーム」や「予約メニュー」の画面が使いやすくなっております。
        {/* <br /> */}
        ＜主な変更点は
        <WebLink
          href={'https://help.pochico.app/20240702-release-note'}
          target={'_blank'}
        >
          こちら
        </WebLink>
        ＞
        <br />
        今回のリニューアルについて、ぜひ皆さまの率直な感想をお聞かせください！
        <WebLink
          href={`https://docs.google.com/forms/d/e/1FAIpQLSfBhyhhHBUEpLwP303z-aVsqD_KDfsbZLeCOh_XtAmUuKjc0w/viewform?usp=pp_url&entry.439717851=${providerAccount.id}`}
          target={'_blank'}
        >
          アンケート
        </WebLink>
      </Text>
    ),
    startDate: '2024-06-02',
    endDate: '2024-08-01',
  },
  {
    title: '📢ポチコ運営よりお知らせ📢',
    condition: (providerAccount) => {
      const calc = new PaymentDateCalculator(providerAccount, dayjs());
      return calc.remainFreeDays > 0;
    },
    body: () => {
      return (
        <VStack alignItems={'flex-start'}>
          <Box fontWeight={'bold'}>
            【
            <Text display={'inline'} color={'green.500'}>
              友だち登録
            </Text>
            で個別サポート！💬】
          </Box>
          <Text>LINEチャットでの相談を受け付けています。</Text>
          <UnorderedList pl={'1em'}>
            <ListItem>初期設定の仕方</ListItem>
            <ListItem>業態にあわせた活用方法</ListItem>
          </UnorderedList>
          <Box>
            など個別にサポートいたしますので、
            まずは専用のLINE公式アカウントと友だち登録をお願いします！ ＜
            <WebLink
              href={`https://play-tech.notion.site/LINE-5600f2f8e1c2437cb7950b318b3aa5b2`}
              target={'_blank'}
            >
              詳しくはこちら
            </WebLink>
            ＞
          </Box>
        </VStack>
      );
    },
    startDate: '2024-08-26',
    endDate: '2024-12-31',
  },
];

export const Information: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const targets = React.useMemo(() => {
    const today = dayjs().format('YYYY-MM-DD');
    return information.filter(({ condition, startDate, endDate }) => {
      return (
        today >= startDate &&
        today <= endDate &&
        (!condition || condition(providerAccount))
      );
    });
  }, [providerAccount]);
  if (targets.length === 0) {
    return null;
  }

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      borderColor={'gray.300'}
      borderWidth={'1px'}
      borderRadius={'4px'}
      w={'full'}
      bgColor={'white'}
      divider={<StackDivider borderColor="gray.200" />}
    >
      {targets.map(({ title, body }, i) => (
        <VStack
          key={`information-${i}`}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          w={'full'}
          bgColor={'white'}
          borderRadius={'4px'}
          padding={4}
        >
          <Text fontWeight={'bold'}>{title}</Text>
          {body(providerAccount)}
        </VStack>
      ))}
    </VStack>
  );
};
