import { Box } from '@chakra-ui/react';
import * as React from 'react';

export const SmileCheckbox: React.FC<{ checked: boolean }> = ({ checked }) => {
  if (checked) {
    return (
      <svg
        width="62"
        height="48"
        viewBox="0 0 62 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#F8B5E9" />
        <rect
          x="13.3955"
          y="12.2793"
          width="1.67442"
          height="7.81395"
          rx="0.837209"
          fill="white"
        />
        <rect
          x="29.5811"
          y="12.2793"
          width="1.67442"
          height="7.81395"
          rx="0.837209"
          fill="white"
        />
        <path
          d="M31.814 26.6271C31.814 31.0329 28.1906 34.6045 23.721 34.6045C19.2513 34.6045 15.6279 31.0329 15.6279 26.6271C15.6279 26.1039 19.2513 27.2809 23.721 27.2809C28.1906 27.2809 31.814 25.45 31.814 26.6271Z"
          fill="white"
        />
        <line
          x1="38.5875"
          y1="36.2783"
          x2="44.6246"
          y2="42.3154"
          stroke="#56B15A"
          strokeWidth="8"
          strokeLinecap="round"
        />
        <line
          x1="44.6514"
          y1="42.3431"
          x2="57.4131"
          y2="29.5814"
          stroke="#56B15A"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </svg>
    );
  } else {
    return (
      // ↑のとwidthをあわせる
      <Box paddingRight="14px" justifyContent={'center'}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="24" fill="#D9D9D9" />
          <rect
            x="13.3955"
            y="12.2793"
            width="1.67442"
            height="7.81395"
            rx="0.837209"
            fill="white"
          />
          <rect
            x="29.5811"
            y="12.2793"
            width="1.67442"
            height="7.81395"
            rx="0.837209"
            fill="white"
          />
          <path
            d="M19.3251 32.2512C18.2357 31.763 18.5924 30.1329 19.7861 30.1443L23.442 30.1791L27.3619 29.9166C28.6321 29.8315 29.1587 31.511 28.0674 32.1665C27.8453 32.2998 27.5865 32.3586 27.3286 32.3342L24.4829 32.0648C23.8389 32.0038 23.1904 32.006 22.5468 32.0715L19.8871 32.3419C19.6951 32.3615 19.5012 32.3302 19.3251 32.2512Z"
            fill="white"
          />
        </svg>
      </Box>
    );
  }
};
