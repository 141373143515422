// import firebase from 'firebase/compat';

import {
  EmailAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth';
import { myAuth } from '../firebase/firebaseInit';

const authProvider = {
  currentUser: () => {
    return myAuth.currentUser;
  },
  // ...firebaseAuthProvider,
  buildErrorMessage: (e: { code: string }): string => {
    // https://firebase.google.com/docs/auth/admin/errors?hl=ja
    switch (e.code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'メールアドレスまたはパスワードが間違っています';
      case 'auth/invalid-email':
        return 'メールアドレスの形式が正しくありません';
      case 'auth/email-already-in-use':
        return '既に登録されているメールアドレスです';
      case 'auth/weak-password':
        return 'パスワードが簡単すぎます';
      case 'auth/too-many-requests':
        return 'ログイン試行回数が多すぎます。時間を空けて再度お試しください';
      case 'auth/requires-recent-login':
        return '画面をリロードして再度お試しください';
      default:
        return e.code;
    }
  },
  sendPasswordResetEmail: async (params: { email: string }) => {
    return await sendPasswordResetEmail(myAuth, params.email, {
      url: `${window.location.origin}/login`,
    }).catch((e: any) => {
      if ('code' in e) {
        return Promise.reject(authProvider.buildErrorMessage(e));
      }
      return Promise.reject(e);
    });
  },
  signUp: async (params: {
    email: string;
    displayName: string;
    password: string;
  }): Promise<UserCredential> => {
    const result = await createUserWithEmailAndPassword(
      myAuth,
      params.email,
      params.password
    )
      .then(async (userCredential) => {
        if (!userCredential.user) {
          return Promise.reject('failed to createUserWithEmailAndPassword');
        }
        await updateProfile(userCredential.user, {
          displayName: params.displayName,
        });
        await sendEmailVerification(userCredential.user);
        return userCredential;
      })
      .catch((e: any) => {
        if ('code' in e) {
          return Promise.reject(authProvider.buildErrorMessage(e));
        }
        return Promise.reject(e);
      });
    return result;
  },
  login: async (params: {
    email: string;
    password: string;
  }): Promise<UserCredential> => {
    const result = await signInWithCredential(
      myAuth,
      EmailAuthProvider.credential(params.email, params.password)
    ).catch((e: any) => {
      console.error(`[auth]login ${e}`, { e });
      if ('code' in e) {
        return Promise.reject(authProvider.buildErrorMessage(e));
      }
      return Promise.reject(e);
    });
    return result;
  },
  logout: async (params: any) => {
    console.log(`[auth]logout ${JSON.stringify(params)}`);
    // /logoutに遷移してから`useAuthentication.clear`を呼ぶため、ここでは何もしない
    // await firebaseAuthProvider.logout(params);
    return signOut(myAuth);
    // await myAuth.signOut();
    // return '/logout';
  },
  checkError: async (error: any) => {
    // よくわからん
    console.log(`[auth]checkError ${error}(${JSON.stringify(error)})`, error);
    // return firebaseAuthProvider.checkError(error);
    return;
  },
  checkAuth: async (params: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      // 参考: https://github.com/benwinding/react-admin-firebase/blob/4e684327394662de9c9700bf25ee9d0531bc6faf/src/providers/database/firebase/FirebaseWrapper.ts#L133
      // return myAuth.currentUser
      //   ? Promise.resolve()
      //   : Promise.reject('auth.auth_check_error');
      if (myAuth.currentUser) {
        resolve();
      }
      const unsubscribe = myAuth.onAuthStateChanged((user) => {
        console.log(`[auth]checkAuth`, { unsubscribe, user });
        unsubscribe?.();
        if (user) {
          resolve();
        } else {
          reject('auth.auth_check_error');
        }
      });
    });
  },
  getIdentity: async () => {
    console.log('[auth]getIdentity');
    const user = myAuth.currentUser;
    if (user) {
      return {
        id: user.uid,
        fullName: user?.displayName ?? undefined,
      };
    } else {
      return Promise.reject('auth.auth_check_error');
    }
    // if (firebaseAuthProvider.getIdentity) {
    //   return firebaseAuthProvider.getIdentity();
    // } else {
    //   console.log({
    //     message: 'firebaseAuthProvider.getIdentity is missing',
    //     firebaseAuthProvider,
    //   });
    //   throw new Error(`firebaseAuthProvider.getIdentity is missing`);
    // }
  },
  getPermissions: async (params: any): Promise<any> => {
    console.log(`[auth]getPermissions ${JSON.stringify(params)}`);
    const token = await myAuth.currentUser?.getIdTokenResult();
    return token?.claims;
    // const userDetail = (await firebaseAuthProvider.getPermissions(
    //   params
    // )) as UserDetail;

    // // TODO: ここ以下をhooksで取りに行ようにしてpermissionではやらないほうがログインまわり安定するかも
    // const providerStaff = await db
    //   .collection(CONSTANTS.COLLECTION.PROVIDER_STAFFS)
    //   .doc(userDetail.user_id)
    //   .get()
    //   .then((doc) => {
    //     return (doc.exists ? doc.data() : undefined) as
    //       | ProviderStaff
    //       | undefined;
    //   });

    // const providerAccount = providerStaff?.providerAccounts?.[0];
    // console.log('[auth]getPermissions  ■providerAccount', providerAccount);

    // return {
    //   ...userDetail,
    //   providerAccountId: providerAccount?.id,
    //   botId: providerAccount?.botId,
    //   isAdmin: providerAccount?.isAdmin,
    // };
  },
  changePassword: async (params: {
    currentPassword: string;
    newPassword: string;
  }) => {
    if (!myAuth.currentUser || !myAuth.currentUser.email) {
      return Promise.reject('auth.auth_check_error');
    }
    const { user } = await authProvider.login({
      email: myAuth.currentUser.email,
      password: params.currentPassword,
    });
    if (!user) {
      return Promise.reject('auth.auth_check_error');
    }
    return updatePassword(user, params.newPassword).catch((e: any) => {
      if ('code' in e) {
        return Promise.reject(authProvider.buildErrorMessage(e));
      }
      return Promise.reject(e);
    });
  },
  sendEmailVerification: async () => {
    if (!myAuth.currentUser || !myAuth.currentUser.email) {
      return Promise.reject('auth.auth_check_error');
    }
    await sendEmailVerification(myAuth.currentUser);
    return { email: myAuth.currentUser.email };
  },
};
export default authProvider;
