import React from 'react';

import {
  MdOutlineCalendarMonth,
  MdOutlineCalendarToday,
  MdOutlineEventAvailable,
  MdOutlineHome,
  MdOutlinePerson,
  MdOutlineRestaurantMenu,
  MdOutlineSettings,
  MdRefresh,
} from 'react-icons/md';

import {
  Box,
  Button,
  HStack,
  IconButton,
  Text,
  VStack,
  keyframes,
  useMediaQuery,
} from '@chakra-ui/react';
import { waitAtLeast } from '@pochico/shared';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from '../../context/providerAccount';
import { useIsPC } from '../../hooks/useIsPC';

const localStorageKey = 'sidebarOpen';
type ConsoleSidebarContextType = {
  isOpen: boolean;
  pageTitle: string | undefined;
  setPageTitle: (title: string) => void;
  toggleOpen: () => void;
};
export const ConsoleSidebarContext =
  React.createContext<ConsoleSidebarContextType>({
    isOpen: false,
    pageTitle: undefined,
    setPageTitle: () => {
      return;
    },
    toggleOpen: () => {
      return;
    },
  });

export const useConsoleSidebarContext = () =>
  React.useContext(ConsoleSidebarContext);

export const ConsoleSidebarContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState<string>();
  const toggleOpen = React.useCallback(() => {
    setIsOpen(!isOpen);
    localStorage.setItem(localStorageKey, (!isOpen).toString());
  }, [isOpen]);
  React.useEffect(() => {
    const shouldOpen = localStorage.getItem(localStorageKey) === 'true';
    if (shouldOpen) {
      toggleOpen();
    }
  }, []);

  return (
    <ConsoleSidebarContext.Provider
      value={{
        isOpen,
        pageTitle,
        setPageTitle,
        toggleOpen,
      }}
    >
      {children}
    </ConsoleSidebarContext.Provider>
  );
};

export const ConsoleSidebar: React.FC = () => {
  const { providerAccount } = useAuthState();

  const isPC = useIsPC();
  const { isOpen } = useConsoleSidebarContext();
  const showNav = (!isPC && isOpen) || isPC;
  const prefix = `/${providerAccount?.id}/${providerAccount?.botId}`;
  const location = useLocation();
  const selectedMenu = React.useMemo(() => {
    const segments = location.pathname.split('/');
    if (segments.length === 2) {
      if (segments[1] === '') {
        return 'home';
      } else {
        return segments[1];
      }
    } else if (segments.length < 4) {
      return segments[segments.length - 1];
    } else {
      return segments[3];
    }
  }, [location.pathname]);

  return showNav ? (
    <VStack
      as={'nav'}
      zIndex={100}
      position={'fixed'}
      justifyContent={'space-between'}
      // minHeight={'200vh'}
      // height={'200%'}
      // width={'fit-content'}
      w={isOpen ? '200px' : '64px'}
      margin={0}
      minH={'100vh'}
      alignSelf={'flex-start'}
      backgroundColor={'gray.100'}
      transition={'all 0.5s ease'}
      boxShadow={'lg'}
      // pb={'20px'}
      // style={{
      //   transition: 'all 0.3s ease',
      //   transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
      // }}
    >
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        borderRightWidth={'1px'}
        borderRightColor={'gray.200'}
        w={'full'}
      >
        <MenuItem
          to="/"
          label="ホーム"
          icon={<MdOutlineHome size={16} />}
          isActive={selectedMenu === 'home'}
        />
        <MenuItem
          to={`${prefix}/bulkSpots`}
          icon={<MdOutlineCalendarMonth />}
          label={'予約枠の一括登録'}
          isActive={selectedMenu === 'bulkSpots'}
        />
        <MenuItem
          to={`${prefix}/spot`}
          icon={<MdOutlineCalendarToday />}
          label={'予約枠を管理'}
          isActive={selectedMenu === 'spot'}
        />
        <MenuItem
          to={`${prefix}/booking`}
          icon={<MdOutlineEventAvailable />}
          label={'予約の管理'}
          isActive={selectedMenu === 'booking'}
        />
        ,
        <MenuItem
          to={`${prefix}/lineUser`}
          icon={<MdOutlinePerson />}
          label={'ユーザー一覧'}
          isActive={selectedMenu === 'lineUser'}
        />
        <MenuItem
          to={`${prefix}/bookingMenu`}
          icon={<MdOutlineRestaurantMenu />}
          label={'予約メニュー'}
          isActive={selectedMenu === 'bookingMenu'}
        />
        <MenuItem
          to="/configuration"
          label="各種設定"
          icon={<MdOutlineSettings />}
          isActive={selectedMenu === 'configuration'}
        />
      </VStack>
      <Box position={'absolute'} bottom={'64px'} left={'16px'}>
        <RefreshButton width="20px" height="20px" />
      </Box>
    </VStack>
  ) : (
    <></>
  );
};

const MenuItem: React.FC<{
  to: string;
  icon: React.ReactElement;
  label: string;
  isActive: boolean;
  target?: string;
}> = ({ target, to, icon, label, isActive }) => {
  const { isOpen, setPageTitle, toggleOpen } = useConsoleSidebarContext();
  const [isPC] = useMediaQuery('(min-width: 768px)');
  const onClick = React.useCallback(() => {
    if (!target) {
      setPageTitle(label);
    }
    if (isPC) {
      return;
    } else {
      toggleOpen();
    }
  }, [isPC, label, setPageTitle, target, toggleOpen]);

  return (
    <Box w={'full'} justifyContent={'flex-start'}>
      <Link target={target} to={to}>
        <Button
          variant={isActive ? 'sidebar-active' : 'sidebar'}
          borderRadius={0}
          size={'lg'}
          w={'full'}
          onClick={onClick}
          justifyContent={'flex-start'}
        >
          <HStack spacing={isOpen ? undefined : 0}>
            <Box fontSize={'16px'}>{icon}</Box>
            <Box
              transition={'all 0.4s ease-in-out'}
              style={
                isOpen
                  ? {
                      fontSize: '16px',
                    }
                  : {
                      fontSize: 0,
                    }
              }
            >
              <Text>{label}</Text>
            </Box>
          </HStack>
        </Button>
      </Link>
    </Box>
  );
};

// const Footer = () => {
//   return (
//     <Box>
//       <HStack justifyContent={'flex-end'} gap={'4px'} alignItems={'center'}>
//         Powered by かんたん予約ぽちこ[${__COMMIT_HASH__}]
//         <RefreshButton width={'18px'} />
//       </HStack>
//     </Box>
//   );
// };

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;
const RefreshButton: React.FC<{ width?: string; height?: string }> = (
  props
) => {
  const { width, height } = props;
  const [refreshing, setRefreshing] = React.useState(false);
  const client = useQueryClient();
  const refresh: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(async () => {
      if (refreshing) {
        return;
      }
      setRefreshing(true);
      await waitAtLeast(client.invalidateQueries(), 1500);
      // client.clear();
      setRefreshing(false);
      // return sleep(1000).then(() => {
      //   localStorage.clear();
      //   window.location.reload();
      // });
    }, [client, refreshing]);

  return (
    <IconButton
      icon={<MdRefresh />}
      aria-label="refresh"
      variant={'transparent-clickable'}
      onClick={refresh}
      animation={refreshing ? `${spin} infinite 0.75s linear` : undefined}
      cursor={'pointer'}
      // enableBackground="new 0 0 41 34"
      width={width || '41px'}
      height={height || '34px'}
    />
  );
};
