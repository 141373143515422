import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { MACRO, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CONSTANTS from '../../../commons/constants';
import { BookingMenuUpdateParams } from '../../../firebase/types';
import { HintTooltip } from '../../ui/HintTooltip';

// 予約完了メッセージの入力フォーム
export const BookingSuccessMessageInput: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [cursorPosition, setCursorPosition] = React.useState<number>(0);

  const {
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<BookingMenuUpdateParams>();
  const bookingSuccess = useWatch({
    control,
    name: 'customText.bookingSuccess',
  });
  const [visible, setVisible] = React.useState<boolean>(
    !!getValues('customText.bookingSuccess')
  );

  const onChange = React.useCallback(() => {
    const newVisible = !visible;
    console.log(`[BookingSuccessMessageInput] onChange: ${newVisible}`);
    // チェックボックスがtrueの時だけ入力欄を有効化する
    if (newVisible) {
      // editの際はすでにある値を使う or デフォルト値を使う
      setValue(
        'customText.bookingSuccess',
        bookingSuccess ||
          providerAccount?.customText?.bookingSuccess ||
          CONSTANTS.DEFAULT_BOOKING_SUCCESS,
        {
          shouldDirty: true,
        }
      );
    } else {
      setValue('customText.bookingSuccess', undefined, { shouldDirty: true });
    }
    setVisible(newVisible);
  }, [
    bookingSuccess,
    providerAccount?.customText?.bookingSuccess,
    setValue,
    visible,
  ]);

  const { ref, ..._register } = register('customText.bookingSuccess', {
    required: visible,

    onBlur: () => {
      if (textareaRef.current) {
        setCursorPosition(textareaRef.current.selectionStart);
      }
    },
  });

  const onClickInsertDateButton = React.useCallback(() => {
    const [left, right] = [
      bookingSuccess?.slice(0, cursorPosition),
      bookingSuccess?.slice(cursorPosition),
    ];
    setValue('customText.bookingSuccess', `${left}${MACRO.fullDateJa}${right}`);
  }, [bookingSuccess, cursorPosition, setValue]);

  const hintText = React.useMemo(() => {
    const base = `[予約日時] と入力すると予約完了メッセージ送信時に置換されて予約日時が挿入されます。`;
    if (providerAccount?.customText?.bookingSuccess) {
      // return (
      //   <>
      //     ・{base}
      //     <br />
      //     ・一度サポート経由で予約完了時のメッセージを編集依頼をされたことがある場合は、システムのデフォルト文言は表示されないためサポートにリセット依頼をいただくかカスタマイズにて各メニューごとの編集をしてください。
      //   </>
      // );
    }
    return base;
  }, [providerAccount?.customText?.bookingSuccess]);

  return (
    <Flex direction={'column'} w={'full'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          {/* <Icon as={MessageOutlined} color={'black'} /> */}
          <Text fontWeight={'bold'}>
            予約完了したときのメッセージをカスタマイズする
          </Text>
        </HStack>
        <HStack spacing={2}>
          <Text>{visible ? 'ON' : 'OFF'}</Text>
          <Switch
            defaultChecked={visible}
            checked={visible}
            onChange={onChange}
          />
        </HStack>
      </HStack>
      {visible && (
        <Flex
          mt={'8px'}
          direction={{ base: 'column', lg: 'row' }}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          // minHeight={'20rem'}
        >
          <Box
            marginRight={'16px'}
            w={{ base: 'full', lg: 'full' }}
            minWidth={{ base: 'full', lg: '20rem' }}
          >
            <FormControl
              isRequired={false}
              isInvalid={!!errors.customText?.bookingSuccess}
            >
              <VStack spacing={'4px'} alignItems={'flex-start'}>
                <HStack alignItems={'center'} justifyContent={'flex-start'}>
                  <FormLabel margin={'4px'}>説明文</FormLabel>
                  <HintTooltip text={hintText} />
                </HStack>
                <Textarea
                  maxWidth={'full'}
                  minHeight={{ base: '8rem' }}
                  placeholder={
                    'ご予約ありがとうございます！\n当日は、どうぞお気をつけてお越しくださいませ。\nお会いできることを楽しみにしております！'
                  }
                  {..._register}
                  ref={(e) => {
                    ref(e);
                    textareaRef.current = e;
                  }}
                />
                <HStack
                  w={'full'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant={'white-blue'}
                    size={'sm'}
                    onClick={onClickInsertDateButton}
                  >
                    予約日時を挿入
                  </Button>
                </HStack>
                <FormErrorMessage>
                  {errors.customText?.bookingSuccess?.message}
                </FormErrorMessage>
              </VStack>
            </FormControl>
          </Box>
          {/* <Flex
            marginTop={'2rem'}
            w={'full'}
            minWidth={{ base: 'full', lg: '20rem' }}
            minHeight={{ base: '8rem', lg: '10rem' }}
          >
            <BookingSuccessMessagePreview
              bookingSuccess={bookingSuccess || ''}
            />
          </Flex> */}
        </Flex>
      )}
    </Flex>
  );
};
