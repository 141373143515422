import { Button, Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineHelp } from 'react-icons/md';

export const HintTooltip: React.FC<{ text: React.ReactNode }> = ({ text }) => {
  return (
    <Tooltip alignItems={'center'} label={text} pointerEvents={'all'}>
      {/* <Box width={'24px'} height={'24px'}> */}
      <Button
        width={'24px'}
        height={'24px'}
        borderRadius={'full'}
        backgroundColor={'transparent'}
      >
        <Icon width={'20px'} height={'20px'} as={MdOutlineHelp} />
      </Button>
      {/* </Box> */}
    </Tooltip>
  );
};
