import {
  Button,
  Divider,
  HStack,
  Icon,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { useIsPC } from '../../../hooks/useIsPC';
import { TextEllipsis } from '../../ui/TextEllipsis';
import { SavedFilterKey, useSavedFilterContext } from './SavedFilter';
import { SavedFilterDrawerForSP } from './SavedFilterDrawerForSP';
import { SavedFiltersForPC } from './SavedFiltersForPC';

// 一覧ページにおける絞り込み条件を表示するコンポーネント
// PC/SPで表示を切り替える
// 保存した条件を使いたい場合はsavedFilterをpropsに渡す
export const ResourceFilter = <T,>({
  children,
  defaultValue,
  savedFilter,
  applyFilter,
  filterToString,
}: {
  children: (props: {
    filter: T;
    setFilter: (newFilter: T) => void;
  }) => React.ReactNode; // フィルタの入力フォーム
  defaultValue: T;
  savedFilter?: {
    filterKey: SavedFilterKey;
    toComponent: (filter: T) => React.ReactElement; // PC向け表示で保存した検索条件を一覧表示するための関数
  };
  applyFilter: (newFilter: T) => void;
  filterToString: (filter: T) => string;
}) => {
  const [_filter, _setFilter] = React.useState<T>(defaultValue);
  const savedFilterContext = useSavedFilterContext<T>(
    savedFilter?.filterKey,
    savedFilter?.toComponent,
    applyFilter
  );
  const isPC = useIsPC();
  const filter = savedFilterContext?.state.selectedFilter?.filter || _filter;
  const setFilter = React.useCallback(
    (filter: T) => {
      _setFilter(filter);
      savedFilterContext?.dispatcher.clearSelection();
      if (isPC) {
        applyFilter(filter);
      }
    },
    [isPC, applyFilter, savedFilterContext?.dispatcher]
  );
  const disclosure = useDisclosure();
  const filterButtonLabel = React.useMemo(() => {
    if (savedFilterContext?.state.selectedFilter?.name) {
      return savedFilterContext.state.selectedFilter.name;
    }
    if (filter) {
      return filterToString(filter);
    }
    return '絞り込み';
  }, [filterToString, filter, savedFilterContext?.state.selectedFilter?.name]);

  if (isPC) {
    return (
      <VStack
        alignItems={'flex-start'}
        bgColor={'gray.50'}
        w={'full'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        borderRadius={'4px'}
        spacing={'16px'}
        px={'16px'}
        py={'16px'}
      >
        <Text fontWeight={'bold'}>検索条件</Text>
        <HStack
          spacing={'24px'}
          justifyContent={'center'}
          alignItems={{ base: 'flex-start', lg: 'center' }}
          divider={<Divider orientation={'vertical'} />}
          fontSize={{ base: 'sm', lg: 'md' }}
        >
          {children({ filter, setFilter })}
          {savedFilterContext && (
            <Button
              variant={'blue-fill'}
              minW={'fit-content'}
              onClick={() =>
                savedFilterContext.dispatcher.openAddDialog(filter)
              }
            >
              現在の検索条件を保存
            </Button>
          )}
        </HStack>

        {savedFilterContext && (
          <SavedFiltersForPC context={savedFilterContext} />
        )}
      </VStack>
    );
  } else {
    return (
      <Button
        colorScheme="blue"
        variant={'soft-fill'}
        onClick={disclosure.onOpen}
      >
        <HStack alignItems={'center'}>
          <Icon as={MdOutlineFilterAlt} boxSize={'16px'} />
          <TextEllipsis textAlign={'left'}>{filterButtonLabel}</TextEllipsis>
          <SavedFilterDrawerForSP
            context={savedFilterContext}
            filter={filter}
            applyFilter={(filter) => {
              setFilter(filter);
              applyFilter(filter);
            }}
            disclosure={disclosure}
          >
            {children({ filter, setFilter })}
          </SavedFilterDrawerForSP>
        </HStack>
      </Button>
    );
  }
};
