import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { shadows } from './shadows';

export const customTheme = extendTheme({
  colors,
  shadows,
  components: components,
  breakpoints,
});
