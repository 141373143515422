import {
  Badge,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  BookingMenu,
  ProviderAccount,
  dateStringWithWeekDay,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { DisplaySpot } from '../../../firebase/types';
import { createLocationFrom } from '../../../hooks/locationState';
import { useFetchSpots } from '../../../hooks/spots';
import { useUrlParamState } from '../../../hooks/useUrlParamState';
import { resourcePath } from '../../../hooks/useUrlPath';
import { CalendarCell, CalendarView } from '../../ui/CalendarView';
import { NavigationButton } from '../../ui/NavigationButton';
import { SpotFilter } from '../Spots';

type SpotCalendarViewFilter = {
  'calendar.bookingMenuId'?: string;
  yearMonth: {
    year: number;
    month: number;
  };
};

export const SpotListCalendarView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const now = React.useMemo(() => dayjs(), []);
  const { urlState: _filter, setUrlState: setFilter } =
    useUrlParamState<SpotCalendarViewFilter>(
      ['calendar.bookingMenuId', 'yearMonth'],
      {
        defaultValue: {
          yearMonth: {
            year: now.year(),
            month: now.month() + 1,
          },
        },
      }
    );
  const filter = React.useMemo<SpotFilter | undefined>(() => {
    const startOfMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .startOf('month');

    if (!_filter['calendar.bookingMenuId']) {
      return undefined;
    }
    return {
      bookingMenuId: _filter['calendar.bookingMenuId'],
      displayDate: {
        start: startOfMonth.format('YYYY-MM-DD'),
        end: startOfMonth.endOf('month').format('YYYY-MM-DD'),
      },
    };
  }, [
    _filter['calendar.bookingMenuId'],
    _filter.yearMonth.month,
    _filter.yearMonth.year,
  ]);

  const { toNextMonth, toPrevMonth } = React.useMemo(() => {
    const nextMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .add(1, 'month');
    const prevMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .subtract(1, 'month');
    return {
      toNextMonth: () =>
        setFilter({
          ...filter,
          yearMonth: {
            year: nextMonth.year(),
            month: nextMonth.month() + 1,
          },
        }),
      toPrevMonth: () =>
        setFilter({
          ...filter,
          yearMonth: {
            year: prevMonth.year(),
            month: prevMonth.month() + 1,
          },
        }),
    };
  }, [_filter.yearMonth.month, _filter.yearMonth.year, filter, setFilter]);

  const query = useFetchSpots({
    providerAccount,
    filter: filter!,
    perPage: 1000,
    direction: 'asc',
    page: 1,
    enabled: Boolean(filter),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedCell, setClickedCell] = React.useState<{
    date: dayjs.Dayjs;
    spots: DisplaySpot[];
  } | null>(null);
  const onClickCell = React.useCallback(
    (date: dayjs.Dayjs, spots: DisplaySpot[]) => {
      setClickedCell({ date, spots });
      onOpen();
    },
    [onOpen]
  );
  const locationFrom = React.useMemo(() => {
    return createLocationFrom(
      resourcePath({
        providerAccount,
        resourceName: 'spot',
        action: 'list',
        urlParams: {
          bookingMenuId: _filter['calendar.bookingMenuId'] || '',
          yearMonth: JSON.stringify(_filter.yearMonth),
          tab: 'calendar',
        },
      })
    );
  }, [_filter, providerAccount]);

  React.useEffect(() => {
    if (!_filter['calendar.bookingMenuId'] && bookingMenuList.length > 0) {
      setFilter({
        ..._filter,
        'calendar.bookingMenuId': bookingMenuList[0].id,
      });
    }
  }, [_filter, bookingMenuList, setFilter]);

  return (
    <VStack
      alignItems={'center'}
      width={'full'}
      padding={0}
      gap={0}
      // paddingTop={'16px'}
    >
      <VStack w={'max-content'} alignItems={'center'} gap={'24px'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text>予約メニュー</Text>
          <Select
            value={_filter['calendar.bookingMenuId']}
            w={'full'}
            maxW={{ base: '90vw', md: '50vw' }}
            placeholder="選択してください"
            onChange={(e) => {
              setFilter({
                ..._filter,
                'calendar.bookingMenuId': e.target.value,
              });
            }}
          >
            {/* <option value={''}>選択してください</option> */}
            {bookingMenuList.map((menu) => (
              <option key={menu.id} value={menu.id}>
                {menu.name}
              </option>
            ))}
          </Select>
        </VStack>

        {query.isFetching ? (
          <Spinner />
        ) : query.error ? (
          <VStack alignItems={'flex-start'}>
            <Text>エラーが発生しました</Text>
            <Text>{String(query.error)}</Text>
          </VStack>
        ) : query.isSuccess ? (
          <VStack>
            <HStack
              w={'full'}
              justifyContent={'center'}
              alignItems={'center'}
              // p={'12px'}
              // borderRadius={'4px'}
              whiteSpace={'nowrap'}
            >
              <IconButton
                onClick={toPrevMonth}
                variant={'transparent-clickable'}
                icon={<MdKeyboardArrowLeft />}
                aria-label={'先月'}
              />
              <Text fontWeight={'bold'} width={'6em'} textAlign={'center'}>
                {_filter.yearMonth.year}年{_filter.yearMonth.month}月
              </Text>
              <IconButton
                onClick={toNextMonth}
                variant={'transparent-clickable'}
                icon={<MdKeyboardArrowRight />}
                aria-label={'翌月'}
              />
            </HStack>
            <CalendarView
              yearMonth={{
                year: _filter.yearMonth.year,
                month: _filter.yearMonth.month,
              }}
              dateComponent={(date) => {
                const dateString = date.format('YYYY-MM-DD');
                const spots =
                  query.data?.filter((spot) => spot.date === dateString) || [];
                return (
                  <CalendarCell
                    key={`spot-list-cell-${dateString}`}
                    date={date}
                    onClick={() => onClickCell(date, spots)}
                    count={spots.length}
                  />
                );
              }}
            />
          </VStack>
        ) : (
          <></>
        )}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent paddingY={6} justifyContent={'center'}>
          <ModalHeader justifyContent={'center'}>
            {clickedCell && dateStringWithWeekDay(clickedCell.date)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {clickedCell && (
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                borderRadius={'lg'}
                spacing={'8px'}
              >
                <VStack
                  w={'full'}
                  alignItems={'flex-start'}
                  borderRadius={'lg'}
                  spacing={'8px'}
                >
                  {(clickedCell.spots || []).length === 0 ? (
                    <Text>設定なし</Text>
                  ) : (
                    (clickedCell.spots || [])
                      .sort((a, b) => a.startTime.localeCompare(b.startTime))
                      .map((spot: DisplaySpot, index: number) => {
                        return (
                          <NavigationButton
                            w={'full'}
                            key={`button-${clickedCell?.date || ''}-${index}`}
                            variant={'transparent-clickable'}
                            // border={'1px solid red'}
                            px={'12px'}
                            py={'8px'}
                            borderRadius={'4px'}
                            borderWidth={'1px'}
                            borderColor={'gray.300'}
                            locationFrom={locationFrom}
                            to={{
                              providerAccount,
                              resourceName: 'spot',
                              action: 'show',
                              resourceId: spot.id,
                            }}
                          >
                            <HStack
                              w={'full'}
                              justifyContent={'flex-start'}
                              alignItems={'center'}
                              spacing={'8px'}
                            >
                              <Text>{spot.startTime}~</Text>
                              <Badge colorScheme={'blue'} borderRadius={'5px'}>
                                {spot.maxBookings}枠
                              </Badge>
                            </HStack>
                          </NavigationButton>
                        );
                      })
                  )}
                </VStack>
                <HStack w={'full'} justifyContent={'flex-end'}>
                  <NavigationButton
                    locationFrom={locationFrom}
                    to={{
                      providerAccount,
                      resourceName: 'spot',
                      action: 'create',
                      urlParams: {
                        date: clickedCell.date.format('YYYY-MM-DD'),
                        bookingMenuId: _filter['calendar.bookingMenuId'] || '',
                      },
                    }}
                    variant={'white-blue'}
                  >
                    予約枠を作成
                  </NavigationButton>
                </HStack>
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
