import { Divider, HStack, Input, Select, Stack, Text } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import dayjs from 'dayjs';
import { useIsPC } from '../../../hooks/useIsPC';
import { LineUserFilterUI } from '../LineUsers';
import { ResourceFilter } from '../resourceFilter/ResourceFilter';

const lineUserStatusChoices = [
  { id: '', name: 'フィルタなし' },
  { id: 'active', name: '有効' },
  { id: 'blocked', name: 'ブロック' },
];

export const LineUserFilterInputs: React.FC<{
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  onFilterChange: (filter: LineUserFilterUI) => void;
}> = ({ providerAccount, filter, onFilterChange }) => {
  const filterToString = React.useMemo(
    () => (filter: LineUserFilterUI) => {
      const strs = [];
      if (filter.displayName) {
        strs.push(`${filter.displayName}`);
      }
      if (filter.displayNameByProvider) {
        strs.push(filter.displayNameByProvider);
      }
      if (filter.status) {
        strs.push(filter.status === 'active' ? '有効' : 'ブロック');
      }
      if (filter.lastBookedAt) {
        const { start, end } = filter.lastBookedAt;
        if (start && end) {
          strs.push(`${start} ~ ${end}`);
        } else if (start) {
          strs.push(`${start} ~`);
        } else if (end) {
          strs.push(`~ ${end}`);
        }
      }
      return strs.join(' / ');
    },
    []
  );
  return (
    <ResourceFilter
      defaultValue={filter}
      applyFilter={onFilterChange}
      filterToString={filterToString}
    >
      {({ filter, setFilter }) => (
        <LineUserFilterInputInner
          providerAccount={providerAccount}
          filter={filter}
          onFilterChange={setFilter}
        />
      )}
    </ResourceFilter>
  );
};
const LineUserFilterInputInner: React.FC<{
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  onFilterChange: (filter: LineUserFilterUI) => void;
}> = ({ providerAccount, filter, onFilterChange }) => {
  const isPC = useIsPC();
  const today = React.useMemo(() => dayjs(), []);
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '8px', md: '8px' }}
      justifyContent={'center'}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      divider={<Divider orientation={isPC ? 'vertical' : 'horizontal'} />}
      fontSize={{ base: 'sm', lg: 'md' }}
    >
      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          LINEユーザー名
        </Text>

        <Input
          placeholder={'フィルタなし'}
          textAlign={'left'}
          type={'text'}
          defaultValue={filter.displayName}
          onChange={(e) => {
            onFilterChange({
              ...filter,
              displayName: e.target.value,
            });
          }}
        />
      </Stack>
      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          ポチコ上の別名
        </Text>

        <Input
          placeholder={'フィルタなし'}
          textAlign={'left'}
          type={'text'}
          defaultValue={filter.displayNameByProvider}
          onChange={(e) => {
            onFilterChange({
              ...filter,
              displayNameByProvider: e.target.value,
            });
          }}
        />
      </Stack>

      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          ステータス
        </Text>

        <Select
          // placeholder={'フィルタなし'}
          defaultValue={filter.status}
          onChange={(e) => {
            onFilterChange({
              ...filter,
              status: e.target.value as 'active' | 'blocked',
            });
          }}
        >
          {lineUserStatusChoices.map((choice) => (
            <option key={choice.id} value={choice.id}>
              {choice.name}
            </option>
          ))}
        </Select>
      </Stack>

      <Stack
        direction={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        spacing={'4px'}
      >
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          最終予約作成日時
        </Text>

        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Input
            paddingX={'2px'}
            w={'8em'}
            textAlign={'center'}
            type={'date'}
            value={
              filter.lastBookedAt?.start ||
              today.add(-6, 'month').format('YYYY-MM-DD')
            }
            // value={filter.lastBookedAt.start}
            onChange={(e) => {
              const start = e.target.value;
              const end = (() => {
                if (
                  filter.lastBookedAt?.end &&
                  start > filter.lastBookedAt.end
                ) {
                  return dayjs(start).add(1, 'day').format('YYYY-MM-DD');
                } else {
                  return filter.lastBookedAt?.end;
                }
              })();
              onFilterChange({
                ...filter,
                lastBookedAt: { start, end },
              });
            }}
          />

          <HStack>
            <Text>〜</Text>
            <Input
              paddingX={'2px'}
              w={'8em'}
              textAlign={'center'}
              type={'date'}
              value={filter.lastBookedAt?.end || today.format('YYYY-MM-DD')}
              // value={filter.lastBookedAt.end}
              onChange={(e) => {
                const end = e.target.value;
                const start = (() => {
                  if (
                    filter.lastBookedAt?.start &&
                    end < filter.lastBookedAt?.start
                  ) {
                    return dayjs(end).add(-1, 'day').format('YYYY-MM-DD');
                  } else {
                    return filter.lastBookedAt?.start;
                  }
                })();
                onFilterChange({
                  ...filter,
                  lastBookedAt: { start, end },
                });
              }}
            />
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
