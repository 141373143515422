import {
  AspectRatio,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MdOutlineFace, MdOutlineKey, MdOutlineMail } from 'react-icons/md';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { getAdditionalUserInfo } from 'firebase/auth';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { FixedRight } from '../components/ui/Fixed';
import { Loading } from '../components/ui/Loading';
import { RegistrationLayout } from '../components/ui/RegistrationLayout';
import { RegistrationStepSignUp } from '../components/ui/RegistrationSteps';
import { RequiredTag } from '../components/ui/RequiredTag';
import { RightArrowWhite } from '../components/ui/RightArrow';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { useLineChannelTypeParameter } from '../hooks/useLineChannelTypeParameter';
import authProvider from '../providers/authProvider';
import { Path } from '../routers/Path';

type SignUpProps = {
  email: string;
  password: string;
  displayName: string;
};

const SignUp: FC = () => {
  const navigate = useNavigate();
  const { initialized, providerAccount } = useAuthState();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignUpProps>();
  const toast = useToast();

  const lineChannelType = useLineChannelTypeParameter();
  const onSubmit = React.useCallback(
    async (data: SignUpProps) => {
      setSubmitting(true);
      toast.closeAll();
      try {
        const user = await authProvider.signUp({
          email: data.email,
          displayName: data.displayName,
          password: data.password,
        });
        console.log(`succeeded to signUp`, { user });
        if (getAdditionalUserInfo(user)?.isNewUser) {
          if (lineChannelType === 'messagingApi') {
            navigate(Path.lineOaLinkMessagingApi);
          } else {
            navigate(Path.lineOaLink);
          }
        } else {
          navigate(Path.home);
        }
      } catch (e: any) {
        toast({
          title: `新規登録に失敗しました エラー: ${e}`,
          status: 'error',
        });
        setSubmitting(false);
      }
    },
    [lineChannelType, navigate, toast]
  );

  setDocumentTitle('ポチコ新規登録');

  React.useEffect(() => {
    if (initialized && providerAccount) {
      console.log('already logged-in.', { providerAccount });
      navigate(Path.home);
    }
  }, [initialized, navigate, providerAccount]);

  if (!initialized) {
    return <Loading />;
  }
  const formContainerWidth = ['full', '20em'];

  return (
    <RegistrationLayout showLogoutButton={false}>
      <VStack paddingX={'16px'}>
        <Flex
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'990px'}
          marginX={'auto'}
          textAlign={'center'}
        >
          <Spacer mt={2} />
          <RegistrationStepSignUp />
          <Spacer mt={8} />
          <Text whiteSpace={'pre-wrap'}>
            {`『${GLOBAL_CONFIG.SERVICE_NAME.UNIQ_NAME_JP}』を利用するために、会員登録が必要です。\n登録したメールアドレスとパスワードを使って、管理者ツールへのサインインができるようになります。`}
          </Text>
          <Spacer mt={8} />

          <Flex direction="column" w="full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack
                spacing={6}
                padding={6}
                marginBottom={6}
                border={'1px solid #DDDDDD'}
                borderRadius={'10px'}
              >
                <FormControl isInvalid={!!errors.displayName}>
                  <Stack direction={['column', 'row']} w={'full'} spacing={4}>
                    <HStack w={formContainerWidth}>
                      <FormLabel margin={0}>管理者の名前</FormLabel>
                      <RequiredTag />
                    </HStack>
                    <VStack w={'full'} alignItems={'flex-start'}>
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <Icon
                              as={MdOutlineFace}
                              w={6}
                              h={6}
                              color="blue.600"
                            />
                          }
                        />
                        <Input
                          {...register('displayName', {
                            required: '名前の入力は必須です',
                          })}
                          type="displayName"
                          placeholder="田中 太郎"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.displayName?.message}
                      </FormErrorMessage>
                    </VStack>
                  </Stack>
                </FormControl>

                <FormControl isInvalid={!!errors.email}>
                  <Stack direction={['column', 'row']} w={'full'} spacing={4}>
                    <HStack w={formContainerWidth}>
                      <FormLabel margin={0}>メールアドレス</FormLabel>
                      <RequiredTag />
                    </HStack>
                    <VStack w={'full'} alignItems={'flex-start'}>
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <Icon
                              as={MdOutlineMail}
                              w={6}
                              h={6}
                              color="blue.600"
                            />
                          }
                        />
                        <Input
                          {...register('email', {
                            required: 'メールアドレスの入力は必須です',
                          })}
                          type="email"
                          placeholder="example@example.co.jp"
                          autoComplete={'email'}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.email?.message || ''}
                      </FormErrorMessage>
                    </VStack>
                  </Stack>
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <Stack direction={['column', 'row']} w={'full'} spacing={4}>
                    <HStack w={formContainerWidth}>
                      <FormLabel margin={0}>パスワード</FormLabel>
                      <RequiredTag />
                    </HStack>
                    <VStack w={'full'} alignItems={'flex-start'}>
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <Icon
                              as={MdOutlineKey}
                              w={6}
                              h={6}
                              color="blue.600"
                            />
                          }
                        />
                        <Input
                          {...register('password', {
                            required: 'パスワードの入力は必須です',

                            validate: (value) => {
                              if (value.length < 8 || value.length > 30) {
                                return 'パスワードは8文字以上30文字以下で入力してください';
                              }
                              // const alpha = /[a-zA-Z]/;
                              // const numeric = /[0-9]/;
                              // if (!alpha.test(value) || !numeric.test(value)) {
                              //   return 'パスワードには半角英数字記号のみつかえます';
                              // }
                              return true;
                            },
                          })}
                          type="password"
                          autoComplete="new-password"
                          placeholder="8文字以上"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.password?.message}
                      </FormErrorMessage>
                    </VStack>
                  </Stack>
                </FormControl>
              </VStack>

              <Text whiteSpace={'pre-wrap'} fontSize={'sm'} marginY={4}>
                アカウントを作成すると、利用規約およびプライバシーポリシーに同意したことになります。
              </Text>
              <Button
                type="submit"
                isLoading={submitting}
                colorScheme={'green'}
                width={['full', '452px']}
                borderRadius={20}
              >
                {/* <Image src={'/assets/images/signup_submit.png'} /> */}
                <Text>利用規約に同意して新規登録する</Text>
                <FixedRight>
                  <RightArrowWhite />
                </FixedRight>
              </Button>

              <Spacer mt={2} />
              {/* <WebLink href={'https://pochico.app/tos'} target={'_blank'}>
                利用規約
              </WebLink> */}
              <Spacer height={4} />
              <WebLink href="/login" color={'black'}>
                ログインはこちら
              </WebLink>
            </form>
          </Flex>

          {/* <Spacer mt={8} /> */}

          {/* <Text whiteSpace={'pre-wrap'}>
          会員情報は本サービスのためのみに行います。ご不明点等ありましたら、お問い合わせください。
        </Text> */}
        </Flex>
        {/* <Footer /> */}
      </VStack>
    </RegistrationLayout>
  );
};
export default SignUp;

const TutorialVideo = () => {
  return (
    <AspectRatio ratio={720 / 900}>
      <video
        controls
        preload={'metadata'}
        poster="/assets/pochico_tutorial_thumbnail.png"
      >
        <source src="/assets/pochico_tutorial_1.mov#t=0" type="video/mp4" />
      </video>
    </AspectRatio>
  );
};
