import { Link, LinkProps } from '@chakra-ui/react';
import React from 'react';

import CONSTANTS from '../../commons/constants';

export const LinkToSupportMail: React.FC<
  LinkProps & {
    label?: string;
  }
> = (props) => (
  <Link
    target="_blank"
    href={CONSTANTS.MAILTO_SUPPORT}
    rel="noopener"
    fontSize={'xs'}
    {...props}
  >
    {props.label || 'お問い合わせ'}
  </Link>
);
