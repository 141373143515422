import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

import { LinkToSupportMail } from '../ui/LinkToSupportMail';

const Footer: FC = () => (
  <Flex
    position={'relative'}
    direction={'row'}
    background={'#ffffff'}
    width={'full'}
    padding={'55px 0'}
    marginTop={'100px'}
    borderTop={'1px solid #bbbbbb'}
  >
    <ColumnContainer>
      <Column>
        <Title>プロダクト</Title>
        <Link target="_blank" href="https://pochico.app" rel="noopener">
          かんたん予約アプリ『ポチコ』
        </Link>
        <Link target="_blank" href="https://pochico.app/terms/" rel="noopener">
          利用規約
        </Link>
        <Link
          target="_blank"
          href="https://pochico.app/transaction_law/"
          rel="noopener"
        >
          特定商取引法に基づく表示
        </Link>
      </Column>
      <Column>
        <Title>運営会社</Title>
        <Link target="_blank" href="https://play-tech.jp" rel="noopener">
          株式会社Play Technologies
        </Link>
        <Link
          target="_blank"
          href="https://play-tech.jp/privacy-policy"
          rel="noopener"
        >
          プライバシーポリシー
        </Link>
        <LinkToSupportMail />
      </Column>
    </ColumnContainer>
  </Flex>
);

export default Footer;

const Container = styled.footer`
  flex-wrap: nowrap;
  position: relative;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  width: 100%;
  padding: 55px 0;
  margin-top: 100px;
  border-top: 1px solid #bbbbbb;
`;

const ColumnContainer = styled.div`
  display: flex;
  margin: 0 auto;
`;

const Column = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 230px;
  margin: 0 15px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin: 0px 0px 20px 0px;
`;

const Link = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: slategrey;
  line-height: 1.2;
  display: block;
  margin-bottom: 20px;
`;
