import { Box, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { MdInfo } from 'react-icons/md';
import { useCheckUnpaid } from '../../hooks/useCheckUnpaid';
import { Path } from '../../routers/Path';

export const CheckUnpaid: React.FC = () => {
  const location = useLocation();
  const { customerPortalUrl, status, loading } = useCheckUnpaid();

  if (
    ([Path.paymentRegistration, Path.churn, Path.unpaid] as string[]).includes(
      location.pathname
    )
  ) {
    return null;
  }
  if (loading || status === undefined || status === 'ok') {
    return <Box display={'none'} />;
  }
  if (status === 'ng') {
    return <Navigate to={Path.unpaid} />;
  }

  return (
    <HStack
      w={'full'}
      bgColor={'red.100'}
      py={'12px'}
      px={'12px'}
      justifyContent={'space-between'}
    >
      <HStack w={'full'} alignItems={'flex-start'}>
        <Icon as={MdInfo} color={'red.500'} boxSize={'24px'} />
        <VStack alignItems={'flex-start'} spacing={'0.2em'}>
          <Text fontWeight={'bold'}>お支払いが遅延しています</Text>
          <Text color={'gray.700'}>
            ポチコの機能はまもなく停止します。お支払い手続きは
            <Link
              fontWeight={'bold'}
              color={'blue.600'}
              href={customerPortalUrl}
              textDecoration={'underline'}
            >
              決済ポータル
            </Link>
            をご確認ください。
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
};
