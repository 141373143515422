import { Text } from '@chakra-ui/react';
import React from 'react';

export const TextWithNewLine: React.FC<{ children: string }> = ({
  children,
}) => (
  <Text
    maxWidth={'90vw'}
    dangerouslySetInnerHTML={{
      __html: children.replace(new RegExp('\n', 'g'), '<br />'),
    }}
  ></Text>
);
