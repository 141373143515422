import {
  Box,
  HStack,
  Image,
  StackDivider,
  Switch,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useSetBookingMenuEnablePerProviderAccount } from '../../../../hooks/bookingMenus';
import { useConfirmationDialog } from '../../../ui/ConfirmationDialog';
import { useLoadingOverlayContext } from '../../../ui/LoadingOverlay';

export const NeedBookingMenuSwitcher: React.FC<{
  providerAccount: ProviderAccount;
  // onClick: (isCurrentChecked: boolean) => Promise<void>;
}> = ({ providerAccount }) => {
  const needBookingMenu = providerAccount.needBookingMenu;
  const { openConfirmationDialog } = useConfirmationDialog();
  const switchRef = React.useRef<HTMLInputElement>(null);
  const toast = useToast();
  const overlayContext = useLoadingOverlayContext();

  const providerAccountMutation =
    useSetBookingMenuEnablePerProviderAccount(providerAccount);
  const onClick = React.useCallback(
    async (isCurrentChecked: boolean) => {
      overlayContext.onOpen();
      return providerAccountMutation
        .mutateAsync(
          { enabled: isCurrentChecked },
          { onSettled: () => overlayContext.onClose() }
        )
        .then(() => {
          toast.closeAll();
          toast({
            title: `予約メニューの表示を${
              isCurrentChecked ? 'ON' : 'OFF'
            }にしました`,
            status: 'success',
          });
        })
        .then(() => {
          return;
        })
        .catch((e: any) => {
          console.error(`[onClickNeedBookingMenuSwitcher]error`, {
            providerAccount: providerAccount?.id,
            error: e,
            isCurrentChecked,
          });
          toast({
            title: `エラーが発生しました ${e}`,
            status: 'error',
          });
        })
        .finally(() => {
          overlayContext.onClose();
        });
    },
    [overlayContext, providerAccount?.id, providerAccountMutation, toast]
  );

  const onSwitchClicked = React.useCallback(() => {
    const newValue = !needBookingMenu;
    const flag = newValue ? 'ON' : 'OFF';

    openConfirmationDialog({
      title: `予約メニュー機能を${flag}にしますか？`,
      size: '3xl',
      body: (
        <VStack>
          {/* LINE上で予約を受け付ける際に、お客様に予約メニューを選択してもらう機能を
                      {flag}にします。 */}
          {newValue ? (
            <Text>
              予約メニュー機能をONにすると、LINE上で予約を受け付ける際に、お客様に複数のメニューから選択してもらうことができます。
              <br />
              ※予約メニュー機能を利用する場合、それぞれの予約メニューで別々に予約枠を作成する必要があります。
            </Text>
          ) : (
            <Text>
              予約メニュー機能をOFFにすると、1つの予約メニューだけが使用され、他の予約メニューは予約できなくなります。他の予約メニューの予約受け付けは、再び予約メニュー機能をONにすることで再開することができます。
              <br />
              （OFF時に使用される予約メニューは、ポチコ利用開始時の最初の予約メニューとなります）
            </Text>
          )}
          <HStack
            alignItems={'flex-start'}
            justifyContent={'center'}
            divider={<StackDivider borderColor="gray.500" />}
          >
            <VStack alignItems={'center'} justifyContent={'center'}>
              <Text>Before</Text>
              {newValue ? <NeedBookingMenuOff /> : <NeedBookingMenuOn />}
            </VStack>
            <VStack alignItems={'center'} justifyContent={'center'}>
              <Text>After</Text>
              {newValue ? <NeedBookingMenuOn /> : <NeedBookingMenuOff />}
            </VStack>
          </HStack>
        </VStack>
      ),
      cancelText: 'キャンセル',
      submitText: `${flag}にする`,
      onSubmit: async () => {
        await onClick(newValue);
      },
    });
  }, [needBookingMenu, onClick, openConfirmationDialog]);

  return (
    <HStack
      alignItems={'center'}
      justifyContent={'space-between'}
      // padding={'16px'}
      // borderWidth={'1px'}
      // borderColor={'gray.200'}
      // borderRadius={'4px'}
      w={'full'}
    >
      {/* <VStack alignItems={'flex-start'} justifyContent={'center'}>
        <Text fontSize={'xl'} fontWeight={'bold'}>
          予約メニュー機能
        </Text>
        {needBookingMenu ? (
          <Text fontSize={'sm'}>
            (予約を開始すると、予約メニューを選んでから日時選択します。)
          </Text>
        ) : (
          <Text fontSize={'sm'}>
            (予約を開始すると、予約メニューの選択は無く、「デフォルト」に紐ついた予約枠から日時選択します。)
          </Text>
        )}
      </VStack> */}
      <HStack alignItems={'center'}>
        <Text>{needBookingMenu ? 'ON' : 'OFF'}</Text>
        <Switch
          // defaultChecked={needBookingMenu}
          isChecked={needBookingMenu}
          colorScheme="blue"
          ref={switchRef}
          onChange={(event) => {
            event.stopPropagation();
            const isCurrentChecked = event.currentTarget.checked;
            if (needBookingMenu === isCurrentChecked) {
              if (switchRef.current) {
                switchRef.current.checked = needBookingMenu; // 戻す
              }
              return;
            }
            onSwitchClicked();
          }}
        />
      </HStack>
    </HStack>
  );
};

const NeedBookingMenuOn = () => {
  return (
    <Box w={{ base: '150px', md: '300px' }}>
      <Image src={'/assets/bookingMenu_on.png'} />
    </Box>
  );
};

const NeedBookingMenuOff = () => {
  return (
    <Box w={{ base: '150px', md: '300px' }}>
      <Image src={'/assets/bookingMenu_off.png'} />
    </Box>
  );
};
