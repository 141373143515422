import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BulkAddSpotsInput, dateFormat } from './types';

export const ExcludeDaysInput: React.FC<{ now: dayjs.Dayjs }> = ({ now }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<BulkAddSpotsInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `excludeDays`,
  });

  const onClickAdd = React.useCallback(() => {
    append({ date: now.format(dateFormat) });
  }, [append, now]);

  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <Text fontSize={'16px'} fontWeight={'bold'}>
        ③ 除外したい日（祝日など）を設定してください。
      </Text>
      <Text color={'gray.600'} fontSize={'xs'}>
        ここで指定した日は登録されません。休業日などを記載してください。
        <br />
        (参考：
        <Link
          color={'blue'}
          textDecoration={'underline'}
          href="https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html"
          target={'_blank'}
        >
          日本の祝日
        </Link>
        )
      </Text>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        borderRadius={'4px'}
        w={'full'}
      >
        <HStack flexWrap={'wrap'} w={'full'} spacing={0} gap={3}>
          {fields.map((field, index) => {
            return (
              <HStack w={'fit-content'} spacing={0} key={`${field.id}`}>
                <FormControl isInvalid={!!errors.excludeDays?.[index]?.date}>
                  <Input
                    width={'8.5em'}
                    px={1}
                    textAlign={'center'}
                    borderRadius={'6px'}
                    type="date"
                    size={'md'}
                    fontSize={{ base: 'sm', lg: 'md' }}
                    backgroundColor={'white'}
                    {...register(`excludeDays.${index}.date`, {
                      validate: dateTimeStringValidation,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.excludeDays?.[index]?.date?.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  color={'red'}
                  variant={'transparent-clickable'}
                  // backgroundColor={'alpha'}
                  size={'sm'}
                  onClick={() => remove(index)}
                >
                  ×
                </Button>
              </HStack>
            );
          })}
        </HStack>
        <Button
          variant={'soft-fill'}
          colorScheme={'blue'}
          size={'sm'}
          w={'fit-content'}
          paddingX={2}
          paddingY={1}
          onClick={onClickAdd}
        >
          ＋ 除外日を追加
        </Button>
      </VStack>
    </VStack>
  );
};

const dateTimeStringValidation = (date: any) => {
  if (!date) {
    return '必須項目です';
  }
  const parsed = dayjs(date);
  if (!parsed) {
    return 'フォーマットが不正です';
  }
  return;
};
