import {
  Booking,
  chunk,
  getRandomString,
  isNotNullOrUndefined,
  isNullOrUndefinedArgs,
  ProviderAccount,
  undefinedToNull,
} from '@pochico/shared';
import dayjs from 'dayjs';
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  Query,
  query,
  QueryDocumentSnapshot,
  runTransaction,
  Transaction,
  where,
} from 'firebase/firestore';

import CONSTANTS from '../../commons/constants';
import { BookingFilter } from '../../components/features/Bookings';
import { db } from '../../firebase/firebaseInit';
import {
  BookingCreateParams,
  BookingUpdateParams,
  DisplayBooking,
  FromFSBooking,
  ToFSBooking,
} from '../../firebase/types';
import {
  convertBookingMenuToToFSBookingMenu,
  convertFromFSBookingMenuToBookingMenu,
  getBookingMenuRef,
} from './bookingMenu';
import { getCount, getList, getOne } from './helper';
import { getLineUsersRef } from './lineUser';
import { getFullSpotsRef } from './spot';
import { Pagination } from './type';

const COLLECTION = CONSTANTS.COLLECTION;

export const getBookingsRef = (providerId: string) => {
  return collection(
    db,
    COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    COLLECTION.SUB_BOOKINGS
  );
};

export const convertBookingToToFSBooking = (booking: Booking): ToFSBooking => {
  return {
    id: booking.id,
    botId: booking.botId,
    bookingMenu: convertBookingMenuToToFSBookingMenu(booking.bookingMenu),
    date: booking.date,
    dateForSort: booking.dateForSort,
    dateTimeForSort: booking.dateTimeForSort,
    spotId: booking.spotId,
    startTime: booking.startTime,
    lineUserId: undefinedToNull(booking.lineUserId),
    userName: undefinedToNull(booking.userName),
    providerMemo: booking.providerMemo,
    status: booking.status,
    createTime: booking.createTime,
    updateTime: booking.updateTime,
  };
};

export const filteredBookingRef = (
  providerAccountId: string,
  filter?: BookingFilter
): Query<DisplayBooking>[] => {
  const wheres = (() => {
    const base = [
      where('status', '==', 'active'),
      filter?.displayDate?.start
        ? where(
            'dateTimeForSort',
            '>=',
            Number(dayjs(filter.displayDate.start).format('YYYYMMDD0000'))
          )
        : undefined,

      filter?.displayDate?.end
        ? where(
            'dateTimeForSort',
            '<=',
            Number(
              dayjs(filter.displayDate.end).endOf('day').format('YYYYMMDDHHmm')
            )
          )
        : undefined,

      filter?.bookingMenuId
        ? where('bookingMenu.id', '==', filter.bookingMenuId)
        : undefined,

      filter?.lineUserId
        ? where('lineUserId', '==', filter.lineUserId)
        : undefined,

      // filter?.spotId ? where('spotId', '==', filter.spotId) : undefined,
    ].filter(isNotNullOrUndefined);
    const filter_ids = filter?.ids || [];
    if (filter_ids.length === 0) {
      return [base];
    }
    return chunk(filter_ids, 10).map((ids) => {
      return [...base, where('id', 'in', ids)];
    });
  })();

  return wheres.length > 0
    ? wheres.map((where, i) => {
        return query(getFullBookingsRef(providerAccountId), ...where);
      })
    : [query(getFullBookingsRef(providerAccountId))];
};

const getFullBookingsRef = (providerId: string) => {
  return collection(
    db,
    COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    COLLECTION.SUB_BOOKINGS
  ).withConverter({
    toFirestore(doc: DisplayBooking): ToFSBooking {
      return {
        ...doc,
        bookingMenu: convertBookingMenuToToFSBookingMenu(doc.bookingMenu),
      };
    },
    fromFirestore(snap: QueryDocumentSnapshot<FromFSBooking>): DisplayBooking {
      const nullableDoc = snap.exists() ? snap.data() : undefined;
      if (
        !nullableDoc ||
        isNullOrUndefinedArgs(
          nullableDoc.botId,
          nullableDoc.spotId,
          nullableDoc.status,
          nullableDoc.dateForSort,
          nullableDoc.dateTimeForSort,
          nullableDoc.date,
          nullableDoc.startTime,
          nullableDoc.createTime
        )
      ) {
        throw new Error(
          'invalid args from firestore spot: ' + JSON.stringify(nullableDoc)
        );
      }
      const booking = {
        ...nullableDoc,
        bookingMenu: convertFromFSBookingMenuToBookingMenu(
          nullableDoc.bookingMenu
        ),
        createTime: nullableDoc.createTime?.toDate(),
        updateTime: nullableDoc.updateTime?.toDate(),
      } as Booking;
      return convertBookingToDisplayBooking(booking);
    },
  });
};

export const getBooking = async (providerAccountId: string, id: string) => {
  return getOne(getFullBookingsRef(providerAccountId), id).then((snap) => {
    return snap ? convertBookingToDisplayBooking(snap) : undefined;
  });
};
export const isBookingExisting = async (params: {
  providerAccountId: string;
  spotId: string;
  lineUserId: string;
}): Promise<boolean> => {
  // 重複した予約があるかどうか
  const { providerAccountId, spotId, lineUserId } = params;
  const count = await getCountFromServer(
    query(
      getBookingsRef(providerAccountId),
      where('status', '==', 'active'),
      where('spotId', '==', spotId),
      where('lineUserId', '==', lineUserId)
    )
  );
  return count.data().count > 0;
};

export const getBookingList = async (
  providerAccountId: string,
  options: {
    filter: BookingFilter;
    pagination: Pagination<DisplayBooking>;
  }
): Promise<{ data: DisplayBooking[] }> => {
  if (options.filter.ids?.length === 0) {
    return { data: [] };
  }
  const { filter, pagination } = options;
  const sort = pagination?.sort || {
    field: 'dateTimeForSort',
    direction: 'asc',
  };

  const queries = filteredBookingRef(providerAccountId, filter);
  if (queries.length > 1 && pagination.lastCursor) {
    return Promise.reject(
      'in句とページネーションを同時に指定することはできません'
    );
  }

  const procs = queries.map(async (query) => {
    return getList(query, pagination);
  });
  const results = await Promise.all(procs);
  return {
    data: results.map(({ data }) => data).flat(),
  };
};

// paginationのために件数取得に使う
export const getBookingCount = async (
  providerAccountId: string,
  filter?: BookingFilter
): Promise<number> => {
  const refs = filteredBookingRef(providerAccountId, filter);
  return Promise.all(refs.map(getCount)).then((p) =>
    p.reduce((a, b) => a + b, 0)
  );
};

export const convertBookingToDisplayBooking = (
  booking: Booking
): DisplayBooking => {
  return {
    ...booking,
    displayDate: dayjs(booking.date).locale('ja').format('YYYY/MM/DD(ddd)'),
  };
};

export const createBooking = async (
  providerAccount: ProviderAccount,
  bookingData: BookingCreateParams
) => {
  if (
    bookingData.bookingMenuId == '' ||
    bookingData.spotId == '' ||
    (!bookingData.lineUserId && !bookingData.userName)
  ) {
    return Promise.reject(new Error('入力に誤りがあります'));
  }

  const bookingMenuRef = getBookingMenuRef(providerAccount.id);
  const bookingMenu = (
    await getDoc(doc(bookingMenuRef, bookingData.bookingMenuId))
  ).data();

  if (!bookingMenu) {
    return Promise.reject(new Error('予約メニューが見つかりませんでした'));
  }
  const spotRef = getFullSpotsRef(providerAccount.id);
  const spot = (await getDoc(doc(spotRef, bookingData.spotId))).data();
  if (!spot) {
    return Promise.reject(new Error('予約枠が見つかりませんでした'));
  }

  if (spot.bookingIds.length == spot.maxBookings) {
    return Promise.reject(new Error('すでに枠が埋まっています'));
  }
  const user = bookingData.lineUserId
    ? { lineUserId: bookingData.lineUserId }
    : { userName: bookingData.userName! };

  const now = new Date();

  const booking: Booking = {
    id: getRandomString(20),
    botId: providerAccount.id,
    bookingMenu,
    spotId: spot.id,
    date: spot.date,
    startTime: spot.startTime,
    dateForSort: spot.dateForSort,
    dateTimeForSort: spot.dateTimeForSort,
    ...user,
    providerMemo: bookingData.providerMemo || '',
    status: 'active',
    createTime: now,
    updateTime: now,
  };
  const writeData = convertBookingToToFSBooking(booking);
  const bookingRef = getBookingsRef(providerAccount.id);
  const lineUserRef = getLineUsersRef(providerAccount.id);
  return runTransaction(db, async (t) => {
    if (providerAccount && providerAccount.calendar) {
      writeData.calendar = providerAccount.calendar;
    }
    t.set(doc(bookingRef, writeData.id), writeData, {
      merge: false,
    });
    t.update(doc(spotRef, spot.id), {
      bookingIds: spot.bookingIds.concat([writeData.id]),
    });
    if (bookingData.lineUserId) {
      t.update(doc(lineUserRef, booking.lineUserId), {
        lastBookedAt: now,
      });
    }
    return { data: writeData };
  });
};

export const updateBooking = ({
  providerAccount,
  updateInput,
}: {
  providerAccount: ProviderAccount;
  updateInput: BookingUpdateParams;
}): Promise<Booking> => {
  // メモだけ変更可能にする
  const bookingRef = getFullBookingsRef(providerAccount.id);
  // const afterMemo = params.data.providerMemo;
  // const updateData: Partial<Booking> = {
  //   ...(params.data.userName ? { userName: params.data.userName } : {}),
  //   providerMemo: afterMemo,
  //   updateTime: new Date(),
  // };
  // const result = { data: { id: documentId, ...updateData } };

  return runTransaction(db, async (t) => {
    return t.get(doc(bookingRef, updateInput.id)).then((fetchedBookingDoc) => {
      const fetchedBooking = fetchedBookingDoc?.exists()
        ? fetchedBookingDoc.data()
        : undefined;
      if (typeof fetchedBooking === 'undefined')
        return Promise.reject(new Error('cannot get lineUser.'));

      t.update(fetchedBookingDoc.ref, {
        ...(updateInput.userName ? { userName: updateInput.userName } : {}),
        providerMemo: updateInput.providerMemo,
        updateTime: new Date(),
      });
      return { ...fetchedBooking, ...updateInput };
    });
  });
};

export const deleteBookings = async (
  providerAccountId: string,
  bookingIds: string[]
) => {
  const spotRef = getFullSpotsRef(providerAccountId);
  const bookingRef = getFullBookingsRef(providerAccountId);

  const deleteBookingPromises = bookingIds.map(async (bookingId) => {
    const bookingData = await getDoc(doc(bookingRef, bookingId)).then(
      (snap) => snap.data() ?? undefined
    );

    if (!bookingData) {
      return Promise.reject('予約が存在しません');
    } else if (bookingData?.status === 'deleted') {
      return Promise.reject('予約はすでに削除されています');
    } else if (
      bookingData?.date &&
      dayjs(`${bookingData.date} ${bookingData.startTime}:00`) < dayjs()
    ) {
      // 管理者は削除出来るようにしておく
      // return Promise.reject('予約時間を過ぎているため削除できません');
    }

    return await runTransaction(db, async (t: Transaction) => {
      const _spotDoc = await t.get(doc(spotRef, bookingData.spotId));
      const spotData =
        _spotDoc && _spotDoc.exists() ? _spotDoc.data() : undefined;
      if (!spotData) {
        return Promise.reject('予約枠が存在しません');
      }
      const excludedBookingIds = spotData.bookingIds.filter(
        (id) => id !== bookingId
      );

      t.update(doc(bookingRef, bookingId), {
        status: 'deleted',
        updateTime: new Date(),
      });
      t.update(doc(spotRef, spotData.id), {
        bookingIds: excludedBookingIds,
      });
    });
  });
  return Promise.all(deleteBookingPromises).then(() => {
    return { data: bookingIds };
  });
};
