import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { LinkToSupportMail } from './LinkToSupportMail';

const Footer: FC = () => (
  <Flex
    position={'relative'}
    direction={'row'}
    background={'#ffffff'}
    width={'full'}
    padding={'55px 0'}
    marginTop={'100px'}
    borderTop={'1px solid #bbbbbb'}
  >
    <HStack
      justifyContent={'center'}
      alignItems={'flex-start'}
      w={'full'}
      spacing={'8px'}
      paddingX={'12px'}
    >
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        whiteSpace={'nowrap'}
      >
        <Text
          w={'full'}
          textAlign={'center'}
          fontSize={'md'}
          fontWeight={'bold'}
        >
          プロダクト
        </Text>
        <Link target="_blank" to="https://pochico.app" rel="noopener">
          <Text fontSize={'xs'} fontWeight={'bold'} color={'slategray'}>
            かんたん予約アプリ『ポチコ』
          </Text>
        </Link>
        <Link target="_blank" to="https://pochico.app/tos" rel="noopener">
          <Text fontSize={'xs'} fontWeight={'bold'} color={'slategray'}>
            利用規約
          </Text>
        </Link>
        <Link
          target="_blank"
          to="https://pochico.app/tokushouhou"
          rel="noopener"
        >
          <Text fontSize={'xs'} fontWeight={'bold'} color={'slategray'}>
            特定商取引法に基づく表示
          </Text>
        </Link>
      </VStack>

      <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
        <Text
          w={'full'}
          textAlign={'center'}
          fontSize={'md'}
          fontWeight={'bold'}
        >
          運営会社
        </Text>
        <Link target="_blank" to="https://play-tech.jp" rel="noopener">
          <Text fontSize={'xs'} fontWeight={'bold'} color={'slategray'}>
            株式会社Play Technologies
          </Text>
        </Link>
        <Link
          target="_blank"
          to="https://play-tech.jp/privacy-policy"
          rel="noopener"
        >
          <Text fontSize={'xs'} fontWeight={'bold'} color={'slategray'}>
            プライバシーポリシー
          </Text>
        </Link>
        <LinkToSupportMail />
      </VStack>
    </HStack>
  </Flex>
);

export default Footer;
