import { StyleFunctionProps, defineStyleConfig } from '@chakra-ui/react';

export const buttonStyle = defineStyleConfig({
  defaultProps: {
    size: 'sm',
  },
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    'pochico.green': {
      size: 'sm',
      bg: 'pochico.green.500',
      _hover: {
        bg: 'pochico.green.400',
      },
      _active: {
        bg: 'pochico.green.300',
      },
      _disabled: {
        bg: 'green.700',
        cursor: 'not-allowed',
      },
      color: 'white',
      textAlign: 'center',
    },
    black: {
      bg: 'blackAlpha.800',
      _hover: {
        _disabled: {
          bg: 'blackAlpha.800',
        },
        bg: 'blackAlpha.700',
      },
      _active: {
        _disabled: {
          bg: 'blackAlpha.800',
        },
        bg: 'blackAlpha.600',
      },
      color: 'white',
      textAlign: 'center',
    },
    transparent: {
      backgroundColor: 'transparent',
      _hover: {
        bg: 'transparent',
      },
      _active: {
        bg: 'transparent',
      },
    },
    sidebar: {
      backgroundColor: 'gray.100',
      _hover: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'gray.30',
      },
      fontWeight: 'normal',
      color: 'gray.700',
      borderWidth: '0',
    },
    'sidebar-active': {
      backgroundColor: 'white',
      _hover: {
        bg: 'gray.5',
      },
      _active: {
        bg: 'gray.10',
      },
      fontWeight: 'normal',
      color: 'gray.700',
      borderWidth: '0',
    },
    'white-blue': {
      backgroundColor: 'white',
      _hover: {
        bg: 'gray.30',
      },
      _active: {
        bg: 'gray.100',
      },
      color: 'blue.text',
      border: '1px solid #CCCCCC',
    },
    subtle: (props: StyleFunctionProps) => ({
      bg: `${props.colorScheme}.100`,
      _hover: {
        bg: `${props.colorScheme}.200`,
      },
      _active: {
        bg: `${props.colorScheme}.300`,
      },
      color: `${props.colorScheme}.800`,
    }),
    'soft-fill': (props: StyleFunctionProps) => ({
      bg: `${props.colorScheme}.50`,
      _hover: {
        bg: `${props.colorScheme}.100`,
        _loading: {
          bg: `${props.colorScheme}.200`,
        },
      },
      _active: {
        bg: `${props.colorScheme}.200`,
      },
      color: `${props.colorScheme}.500`,
    }),
    'white-red': {
      backgroundColor: 'white',
      _hover: {
        bg: 'gray.10',
        _disabled: {
          bg: 'gray.500',
        },
      },
      _active: {
        bg: 'gray.50',
      },
      _disabled: {
        bg: 'gray.500',
        color: 'white',
        cursor: 'not-allowed',
      },
      _loading: {
        bg: 'white',
      },
      color: 'red.500',
      border: '1px solid #CCCCCC',
    },
    'red-fill': {
      backgroundColor: 'red.500',
      _hover: {
        color: 'white',
        bg: 'red.400',
        _disabled: {
          bg: 'gray.400',
        },
      },
      _active: {
        bg: 'red.300',
      },
      _disabled: {
        bg: 'gray.400',
      },
      color: 'white',
    },
    'blue-navigation': {
      fontSize: 'sm',
      backgroundColor: 'none',
      _hover: {
        bg: 'gray.10',
      },
      _active: {
        bg: 'gray.50',
      },
      color: 'blue.text',
    },
    'blue-fill': {
      backgroundColor: 'blue.500',
      _hover: {
        color: 'white',
        bg: 'blue.400',
        _disabled: {
          bg: 'gray.400',
        },
      },
      _active: {
        bg: 'blue.300',
      },
      _disabled: {
        bg: 'gray.400',
      },
      color: 'white',
    },
    'gray-fill': {
      borderWidth: '1px',
      borderColor: 'gray.300',
      backgroundColor: 'gray.50',
      _hover: {
        bg: 'gray.100',
      },
      _active: {
        bg: 'gray.200',
      },
      color: 'gray.500',
    },
    'white-selectable': {
      size: 'sm',
      bg: 'white',
      _hover: {
        bg: 'gray.30',
      },
      _active: {
        bg: 'gray.100',
      },
      _disabled: {
        bg: 'gray.200',
        cursor: 'not-allowed',
      },
      color: 'black',
      textAlign: 'center',
    },
    link: {
      bg: 'transparent',
      textDecoration: 'underline',
      _hover: {
        bg: 'gray.50',
      },
      color: 'blue.500',
      cursor: 'pointer',
      px: '4px',
      py: '2px',
    },
    gray: {
      size: 'sm',
      bg: 'gray.50',
      _hover: {
        bg: 'gray.100',
      },
      _active: {
        bg: 'gray.200',
      },
      _disabled: {
        bg: 'gray.500',
        cursor: 'not-allowed',
      },
      color: 'black',
      textAlign: 'center',
    },
    'transparent-clickable': {
      backgroundColor: 'transparent',
      _hover: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'gray.100',
      },
    },
  },
});
