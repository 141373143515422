import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineInfo } from 'react-icons/md';

export const HelpSpot = () => {
  return (
    <Tooltip
      pointerEvents={'all'}
      label={
        <Box w={'fit-content'}>
          <Text>受付できる予約数の上限設定を表します。</Text>
          <Text>予約枠は、予約メニューごとに設定することができます。</Text>
          <Text>予約枠を設定しないと、予約の受付ができません。</Text>
        </Box>
      }
    >
      <Button variant={'transparent'}>
        <HStack spacing={'2px'} color={'gray.500'}>
          <MdOutlineInfo />
          <Text>予約枠とは？</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
};
