import {
  HStack,
  Input,
  InputGroup,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { useFetchSpotBulkAddHistories } from '../../../hooks/spotBulkAddHistories';
import { useIsPC } from '../../../hooks/useIsPC';
import { SpotFilter } from '../Spots';
import { dateTimeFormat } from '../bulkAddSpots/types';
import { ResourceFilter } from '../resourceFilter/ResourceFilter';

export const SpotFilterInputs: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  filter: SpotFilter;
  applyFilter: (filter: SpotFilter) => void;
}> = ({ providerAccount, bookingMenus, filter, applyFilter }) => {
  const isPC = useIsPC();
  const filterToComponent = React.useMemo(
    () => savedFilterToComponent(isPC, bookingMenus),
    [bookingMenus, isPC]
  );
  const filterToString = React.useMemo(
    () => (filter: SpotFilter) => {
      const bookingMenu = bookingMenus.find(
        (menu) => menu.id === filter.bookingMenuId
      );
      const strs = [];
      if (filter.displayDate) {
        const { start, end } = filter.displayDate;
        if (start && end) {
          strs.push(`${start} ~ ${end}`);
        } else if (start) {
          strs.push(`${start} ~`);
        } else if (end) {
          strs.push(`~ ${end}`);
        }
      }
      if (bookingMenu) {
        strs.push(bookingMenu.name);
      }
      return strs.join(' / ');
    },
    [bookingMenus]
  );
  return (
    <ResourceFilter
      defaultValue={filter}
      savedFilter={{
        filterKey: 'spot',
        toComponent: filterToComponent,
      }}
      applyFilter={applyFilter}
      filterToString={filterToString}
    >
      {({ filter, setFilter }) => (
        <SpotFilterInputInner
          providerAccount={providerAccount}
          bookingMenus={bookingMenus}
          filter={filter}
          onFilterChange={setFilter}
        />
      )}
    </ResourceFilter>
  );
};

const SpotFilterInputInner: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  filter: SpotFilter;
  onFilterChange: (filter: SpotFilter) => void;
}> = ({ providerAccount, bookingMenus, filter, onFilterChange }) => {
  const query = useFetchSpotBulkAddHistories({ providerAccount });
  const bulkAddHistories = query.data?.data || [];
  const isPC = useIsPC();
  return (
    <Stack direction={isPC ? 'row' : 'column'} spacing={isPC ? '16px' : '12px'}>
      <VStack alignItems={'flex-start'} spacing={'4px'}>
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          予約日指定
        </Text>

        <Stack alignItems={'flex-start'} direction={{ base: 'row', lg: 'row' }}>
          <Input
            paddingX={'2px'}
            w={'8em'}
            fontSize={isPC ? 'md' : 'sm'}
            textAlign={'center'}
            type={'date'}
            value={filter.displayDate.start}
            // value={filter.displayDate.start}
            onChange={(e) => {
              // clearSelection();
              const start = e.target.value;
              const end = (() => {
                if (filter.displayDate.end && start > filter.displayDate.end) {
                  return dayjs(start).add(1, 'day').format('YYYY-MM-DD');
                } else {
                  return filter.displayDate.end;
                }
              })();
              onFilterChange({
                ...filter,
                displayDate: { start, end },
              });
            }}
          />

          <HStack>
            <Text>〜</Text>
            <Input
              paddingX={'2px'}
              fontSize={isPC ? 'md' : 'sm'}
              minW={'8em'}
              w={'fit-content'}
              textAlign={'center'}
              type={'date'}
              value={filter.displayDate.end}
              // value={filter.displayDate.end}
              onChange={(e) => {
                const end = e.target.value;
                const start = (() => {
                  if (
                    filter.displayDate.start &&
                    end < filter.displayDate.start
                  ) {
                    return dayjs(end).add(-1, 'day').format('YYYY-MM-DD');
                  } else {
                    return filter.displayDate.start;
                  }
                })();
                onFilterChange({
                  ...filter,
                  displayDate: { start, end },
                });
              }}
            />
          </HStack>
        </Stack>
      </VStack>
      {bulkAddHistories.length > 0 && (
        <VStack minW={'fit-content'} alignItems={'flex-start'} spacing={'4px'}>
          <Text fontSize={'12px'} whiteSpace={'nowrap'}>
            一括登録日時
          </Text>
          <InputGroup>
            <Select
              placeholder={'フィルタなし'}
              value={filter.bulkSpotAddHistoryId}
              onChange={(e) => {
                // clearSelection();
                onFilterChange({
                  ...filter,
                  bulkSpotAddHistoryId: e.target.value,
                });
              }}
            >
              {bulkAddHistories.map((history) => (
                <option
                  key={history.id}
                  value={history.id}
                  color={'red !important'}
                >
                  {dayjs(history.createTime).format(dateTimeFormat)}
                </option>
              ))}
            </Select>
          </InputGroup>
        </VStack>
      )}
      <VStack alignItems={'flex-start'} spacing={'4px'}>
        <Text fontSize={'12px'} whiteSpace={'nowrap'}>
          予約メニュー
        </Text>

        <Select
          // w={'50%'}
          minW={'8em'}
          w={'fit-content'}
          placeholder={'フィルタなし'}
          // defaultValue={filter.bookingMenuId}
          value={filter.bookingMenuId}
          onChange={(e) => {
            onFilterChange({
              ...filter,
              bookingMenuId: e.target.value,
            });
            // clearSelection();
          }}
        >
          {bookingMenus.map((menu) => (
            <option key={menu.id} value={menu.id}>
              {menu.name}
            </option>
          ))}
        </Select>
      </VStack>
    </Stack>
  );
};

const savedFilterToComponent = (isPC: boolean, bookingMenus: BookingMenu[]) => {
  return (filter: SpotFilter) => {
    const bookingMenu = bookingMenus.find((b) => b.id === filter.bookingMenuId);
    if (isPC) {
      return (
        <VStack w={'full'} p={'16px'} bgColor={'gray.50'} color={'gray.600'}>
          <HStack w={'full'}>
            <Text>予約日指定：</Text>
            <Text>
              {filter.displayDate.start || '未指定'} ~{' '}
              {filter.displayDate.end || '未指定'}
            </Text>
          </HStack>
          <HStack w={'full'}>
            <Text>予約メニュー：</Text>
            <Text>{bookingMenu?.name || '未指定'}</Text>
          </HStack>
        </VStack>
      );
    } else {
      return <></>;
    }
  };
};
