import { Colors } from '@chakra-ui/react';

export const colors: Colors = {
  alpha: '#FFFFFF00',
  pochico: {
    light: '#6ecba4',
    main: '#239d6b',
  },
  'pochico.white': {
    100: '#c6f3e1',
    200: '#a0e6cd',
    300: '#7ad8b9',
    400: '#54cba5',
    500: '#239d6b',
    600: '#1d7e58',
    700: '#176046',
    800: '#114034',
    900: '#0b201f',
  },
  'pochico.green': {
    100: '#c6f3e1',
    200: '#a0e6cd',
    300: '#7ad8b9',
    400: '#54cba5',
    500: '#239d6b',
    600: '#1d7e58',
    700: '#176046',
    800: '#114034',
    900: '#0b201f',
  },
  gray: {
    5: '#FCFCFC',
    10: '#F9F9F9',
    30: '#F6F6F6',
    50: '#F7FAFC',
    120: '#DDDDDD',
  },
  green: {
    450: '#4D9B6F',
  },
  line: {
    talk: '#89A8D5',
  },
  blue: {
    // 233 240 253
    50: '#E9F2FD',
    fill: '#F4FAFF',
    text: '#177FCA',
  },
  transparent: {
    gray: '#F0F0F0CC',
    black: '#303030CC',
  },
};
