import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const useUrlParamState = <T extends { [key: string]: any }>(
  keys: (keyof T)[],
  opts?: { defaultValue: T }
) => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [urlState, _setState] = React.useState(() => {
    return keys.reduce((acc, key) => {
      const rawValue = urlParams.get(String(key));
      const value = (() => {
        if (!rawValue) {
          return opts?.defaultValue[key] || '';
        }
        if (rawValue?.startsWith('[') && rawValue.endsWith(']')) {
          return rawValue.slice(1, -1).split(',') as string[];
        } else if (rawValue?.startsWith('{') && rawValue.endsWith('}')) {
          const obj = JSON.parse(rawValue);
          return obj as any;
        } else if (rawValue === 'undefined') {
          return undefined;
        }
        return rawValue as string;
      })();
      return {
        ...acc,
        [key]: value,
      };
    }, {} as T);
  });
  const setUrlState = React.useCallback(
    (newParams: T) => {
      const newState = { ...urlState, ...newParams };
      _setState(newState);
      setUrlParams((prev) => {
        const param = new URLSearchParams(prev);
        keys.forEach((key) => {
          const value = newParams[key];
          if (Array.isArray(value)) {
            param.set(String(key), `[${value.join(',')}]`);
          } else if (typeof value === 'object') {
            param.set(String(key), JSON.stringify(value));
          } else {
            param.set(String(key), value);
          }
        });
        return param;
      });
    },
    [keys, setUrlParams, urlState]
  );
  return { urlState, setUrlState };
};
