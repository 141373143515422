import {
  Box,
  Button,
  Image as ChakraImage,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  form: UseFormReturn<any, any>;
  formLabel: React.ReactElement | string;
  imageUrl?: string; // 既存の画像のURL
  defaultImagePath?: string;
  validator?: (f: File) => Promise<string | undefined>; // エラーメッセージ
};
type Out = {
  file: File | undefined; // 選択されたファイル
  clear: () => void; // ファイルのクリア
  component: React.FC;
};

export const imageSizeRatioValidator = (
  input: { ratio: number } | { min: number; max: number }
) => {
  return async (f: File) => {
    const { width, height } = await imageSize(f);
    console.log({ f, width, height });
    if ('ratio' in input) {
      if (width / height != input.ratio) {
        return `サイズは${input.ratio}:1で指定してください(${width}x${height}(${
          width / height
        }:1))`;
      }
    } else {
      const { min, max } = input;
      if (width < height * min || height * max < width) {
        return `サイズは${min}:1~${max}:1で指定してください(${width}x${height}(${
          width / height
        }:1))`;
      }
    }
    return;
  };
};

export const useImageInputForm = ({
  form,
  formLabel,
  validator,
  imageUrl,
  defaultImagePath,
}: Props): Out => {
  const formName = '_imageUploadForm';
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [files, setFiles] = React.useState<FileList | undefined>();
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
  const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(() => {
    if (imageUrl) {
      return imageUrl;
    }
    return undefined;
  });
  console.log(`[useImageInputForm]previewUrl:${previewUrl}`, {
    defaultImagePath,
    files,
  });

  const setDefaultImageMutation = useMutation({
    mutationFn: async () => {
      console.log(`[setDefaultImageMutation]`);
      if (imageUrl || !defaultImagePath) {
        return;
      }
      return fetch(
        `${GLOBAL_CONFIG.MY_URL.CONSOLE.ORIGIN}/${defaultImagePath}`
      ).then(async (response) => {
        const blob = await response.blob();
        const defaultFile = new File([blob], 'default.png', {
          type: blob.type,
        });
        setSelectedFile(defaultFile);
        const dt = new DataTransfer();
        dt.items.add(defaultFile);
        setFiles(dt.files);
        setPreviewUrl(URL.createObjectURL(defaultFile));
      });
    },
  });

  React.useEffect(() => {
    setDefaultImageMutation.mutateAsync();
  }, [setDefaultImageMutation.mutateAsync]);

  const clear = React.useCallback(() => {
    setSelectedFile(undefined);
    setPreviewUrl(undefined);
  }, []);
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = form;

  const onFileChanged = React.useCallback(
    async (event?: any) => {
      // event.preventDefault()
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0]
      ) {
        setFiles(event.target.files);
        const selectedFile = event.target.files[0] as File;
        if (selectedFile) {
          const error = await validator?.(selectedFile);
          if (error) {
            setError(formName, { message: error });
            return;
          }
          clearErrors(formName);
          setSelectedFile(selectedFile);
          setPreviewUrl(URL.createObjectURL(selectedFile));
        } else {
          setSelectedFile(undefined);
          setPreviewUrl(undefined);
        }
      } else {
        console.log('imageFile missing');
      }
    },
    [validator, clearErrors, setError]
  );

  React.useEffect(() => {
    if (inputRef.current && files) {
      inputRef.current.files = files;
    }
  }, [files]);

  const Preview = React.useMemo(() => {
    console.log(`[preview]`, {
      previewUrl,
      selectedFile: selectedFile?.name,
    });
    return previewUrl ? (
      <>
        <ChakraImage
          w={['full']}
          maxWidth={'480px'}
          alt={selectedFile?.name}
          src={previewUrl}
        />
        <Text fontSize={'xs'}>{selectedFile?.name}</Text>
        {/* <Text fontSize={'xs'} color={'red.500'}>
        {files && `(size: ${(files[0].size / 1024).toFixed(2)}kb)`}
      </Text> */}
      </>
    ) : (
      <></>
    );
  }, [previewUrl, selectedFile?.name]);

  const formError = errors[formName];
  const component: React.FC = React.useMemo(() => {
    console.log(`[component]`, {
      formLabel,
      onFileChanged,
    });
    return () => (
      <Box w={'full'}>
        <FormControl isInvalid={!!formError}>
          <FormLabel>{formLabel}</FormLabel>
          {Preview}
          <Input
            id={formName}
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={onFileChanged}
            display={'none'}
            isDisabled={setDefaultImageMutation.isPending}
          />
          <Button
            mt={'4px'}
            variant={'white-blue'}
            size={'sm'}
            onClick={() => inputRef.current?.click?.()}
            isLoading={setDefaultImageMutation.isPending}
          >
            画像を選択
          </Button>
          <FormErrorMessage>{formError?.message as string}</FormErrorMessage>
        </FormControl>
      </Box>
    );
  }, [
    Preview,
    formError,
    // formLabel,
    // onFileChanged, // この2つを入れるとformが変わるたびになぜかPreviewが再レンダリングされる(謎)
    previewUrl,
    selectedFile?.name,
    setDefaultImageMutation.isPending,
  ]);

  return {
    file: selectedFile,
    component,
    clear,
  };
};

const imageSize = async (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const size = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };

      URL.revokeObjectURL(img.src);
      resolve(size);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = URL.createObjectURL(file);
  });
};
