import { ProviderAccount } from '@pochico/shared';
import {
  collection,
  doc,
  getDoc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';
import {
  FromFSProviderAccount,
  ToFSProviderAccount,
} from '../../firebase/types';

export const getProviderAccountRef = (providerId: string) => {
  return doc(
    collection(db, CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS),
    providerId
  ).withConverter({
    toFirestore(docData: ProviderAccount): ToFSProviderAccount {
      throw new Error(`ProviderAccount toFirestore not implemented`);
    },
    fromFirestore(
      snap: QueryDocumentSnapshot<FromFSProviderAccount>
    ): ProviderAccount {
      const _data = snap.exists() ? snap.data() : undefined;
      if (!_data) {
        throw new Error(`providerAccount ${providerId} not found`);
      }
      return {
        ..._data,
        createTime: _data.createTime!.toDate(),
        updateTime: _data.updateTime!.toDate(),
      } as ProviderAccount;
    },
  });
};

export const fetchProviderAccount = async (
  providerAccountId: string
): Promise<ProviderAccount | undefined> => {
  return getDoc(getProviderAccountRef(providerAccountId as string)).then(
    (doc) => {
      const providerAccount = doc.exists() ? doc.data() : undefined;
      if (providerAccount) {
        // console.log(`fetched ProviderAccount ${providerAccountId}`, {
        //   providerAccount,
        // });
        return providerAccount;
      }
      return;
    }
  );
};
