import { HStack, Icon, Text, useToast, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MdError } from 'react-icons/md';
import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import Footer from '../../components/features/Footer';
import Spacer from '../../components/fragments/Spacer';
import { Layout } from '../../components/ui/Layout';
import { Loading } from '../../components/ui/Loading';
import { WebLink } from '../../components/ui/WebLink';
import { useAuthState } from '../../context/providerAccount';
import { Path } from '../../routers/Path';
import { ChurnSurveyForm } from './ChurnSurvey';

// 解約ページ
export const ChurnPage: React.FC = () => {
  setDocumentTitle('かんたん予約アプリ『ポチコ』 解約ページ');

  const navigate = useNavigate();
  const { initialized, providerAccount } = useAuthState();
  // const { status, customerPortalUrl, loading } = useCheckUnpaid();
  const toast = useToast();

  React.useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!providerAccount) {
      toast({
        title: 'ログインしてください',
        status: 'warning',
      });
      navigate('/login');
      return;
    }
    // if (providerAccount.lineChannelType !== 'messagingApi') {
    //   toast({
    //     title: '設定画面から「ポチコの解約」をご確認ください',
    //     status: 'warning',
    //   });
    //   navigate(Path.configuration);
    //   return;
    // }
    // if (providerAccount?.paymentStatus === 'registered' && status === 'ok') {
    //   navigate('/');
    // }
  }, [initialized, navigate, providerAccount, toast]);

  if (!initialized || !providerAccount) {
    return <Loading />;
  }

  return (
    <Layout
      hasBackButton={Path.configuration}
      pageTitle={'かんたん予約アプリ『ポチコ』 解約ページ'}
    >
      <VStack
        alignItems={'flex-start'}
        w={{ base: 'full', md: '50vw' }}
        justifyContent={'flex-start'}
        spacing={'16px'}
      >
        <VStack alignItems={'flex-start'}>
          <Text fontWeight={'bold'}>
            お使いのアカウント: {providerAccount.displayName}
          </Text>
          <Text>
            連携方式:{' '}
            {providerAccount.lineChannelType === 'messagingApi'
              ? 'Messaging API'
              : 'LINEマーケットプレイス'}
          </Text>
        </VStack>
        <HStack
          borderRadius={'xl'}
          borderWidth={'1px'}
          borderColor={'gray.500'}
          bgColor={'gray.50'}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={'16px'}
          paddingY={'16px'}
        >
          <Icon as={MdError} color={'red.400'} boxSize={'32px'} />
          <VStack alignItems={'flex-start'}>
            <Text>・解約すると即座にポチコを利用できなくなります。</Text>
            {/* <Text>・ご利用料金の返金はございません。</Text> */}
          </VStack>
        </HStack>

        <SupportMailTo />
        <ChurnSurveyForm />
      </VStack>
      <Spacer height={'60px'} />
      <Footer />
    </Layout>
  );
};

const SupportMailTo = () => {
  return (
    <VStack
      alignItems={'flex-start'}
      gap={'8px'}
      borderWidth={'1px'}
      borderColor={'gray.500'}
      borderRadius={'8px'}
      bgColor={'gray.50'}
      p={'16px'}
    >
      <Text>
        「使い方が分からなかった・設定が複雑で難しかった」という方向けに、Zoom等にてサポートをさせていただきます。
        <br />
        まずはメールにてサポートご希望の旨をご連絡ください。
      </Text>
      <WebLink
        href={
          'mailto:support-pochico@play-tech.jp?subject=Zoom%E7%AD%89%E3%81%A7%E3%81%AE%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E5%B8%8C%E6%9C%9B%E3%81%99%E3%82%8B&body=%E2%80%BB%E3%81%93%E3%81%AE%E3%81%BE%E3%81%BE%E3%81%8A%E9%80%81%E3%82%8A%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84'
        }
      >
        サポートを希望 : support-pochico@play-tech.jp
      </WebLink>
      <Text>👆こちらに空メールをお送りください。</Text>
    </VStack>
  );
};
