import { Tag } from '@chakra-ui/react';
import React from 'react';

export const RequiredTag = () => {
  return (
    <Tag
      size={'md'}
      backgroundColor={'#FA6E78'}
      color={'white'}
      borderRadius={'20px'}
      minWidth={'fit-content'}
      justifyContent={'center'}
    >
      必須
    </Tag>
  );
};
