import {
  Button,
  ButtonProps,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { waitAtLeast } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { logEvent } from 'firebase/analytics';
import React from 'react';
import { CSVLink } from 'react-csv';
import { LinkProps } from 'react-csv/components/Link';
import { MdDownload } from 'react-icons/md';
import { useAuthState } from '../../context/providerAccount';
import { analytics } from '../../firebase/firebaseInit';

type Props<T> = ButtonProps & {
  queryKey: 'spot' | 'booking' | 'lineUser';
  label?: React.ReactElement;
  query: () => Promise<{ data: T[]; headers: CsvDownloadHeader<T> }>;
  onDownloadComplete?: () => void;
};
export type CsvDownloadHeader<T> = {
  field: keyof T | ((t: T) => string);
  label: string;
}[];
export const CsvDownloadButton = <T,>(props: Props<T>) => {
  const fileName = React.useMemo(
    () => `${props.queryKey}-${dayjs().format('YYYYMMDD_HHmmss')}.csv`,
    [props.queryKey]
  );

  return (
    <CsvDownloadButtonInner key={fileName} {...props} fileName={fileName} />
  );
};
const CsvDownloadButtonInner = <T,>({
  queryKey,
  query,
  fileName,
  label,
  onDownloadComplete,
  ...buttonProps
}: Props<T> & { fileName: string }) => {
  const _query = React.useCallback(async () => {
    const { data, headers } = await waitAtLeast(query(), 500);
    const csv = [
      headers.map((header) => header.label),
      ...data.map((result) =>
        headers.map(({ field }) => {
          if (typeof field === 'function') {
            return field(result);
          } else {
            return String(result[field]);
          }
        })
      ),
    ];
    return csv;
  }, [query]);
  const { error, data, refetch, isFetching } = useQuery({
    queryKey: [queryKey, fileName, 'csv'],
    queryFn: _query,
    enabled: false,
  });
  const { providerAccount } = useAuthState();

  const onClick = React.useCallback(async () => {
    if (isFetching) {
      return;
    }
    if (GLOBAL_CONFIG.ENV !== 'local') {
      logEvent(analytics, 'csv_download', {
        queryKey,
        providerAccountId: providerAccount?.id,
      });
    }
    return refetch().then(onDownloadComplete);
  }, [isFetching, onDownloadComplete, providerAccount?.id, queryKey, refetch]);

  if (error) {
    return <Text>{String(error)}</Text>;
  }
  return data ? (
    <Button isLoading={isFetching} {...buttonProps}>
      <CSVDownload
        key={fileName}
        data={data}
        target="_self"
        filename={fileName}
      />
      <CSVLink data={data} filename={fileName}>
        {label || <Label />}
      </CSVLink>
    </Button>
  ) : (
    <Button isLoading={isFetching} onClick={onClick} {...buttonProps}>
      {label || <Label />}
    </Button>
  );
};
const Label = () => {
  return (
    <HStack>
      <MdDownload />
      <Text>ダウンロード(CSV形式)</Text>
    </HStack>
  );
};

// react-csvのCSVDownloadはfilenameを与えられないため独自実装(https://github.com/react-csv/react-csv/issues/47#issuecomment-859555722)
const CSVDownload = ({ filename, data, target }: LinkProps) => {
  const btnRef = React.useRef<HTMLSpanElement>(null);
  const toast = useToast();

  React.useEffect(() => {
    if (btnRef.current) {
      btnRef.current.click();
      toast.closeAll();
      toast({
        title: (
          <VStack alignItems={'flex-start'}>
            <Text>現在の検索条件で「{filename}」をダウンロードしました</Text>
          </VStack>
        ),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    }
  }, [btnRef, filename, toast]);

  return (
    <CSVLink filename={filename} data={data} target={target}>
      <span ref={btnRef} />
    </CSVLink>
  );
};
