import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingMenuUpdateParams } from '../../../firebase/types';
import { useUpdateBookingMenu } from '../../../hooks/bookingMenus';
import { useIsPC } from '../../../hooks/useIsPC';
import { resourcePath } from '../../../hooks/useUrlPath';
import { Layout } from '../../ui/Layout';
import { LimitDateTimeInput } from '../../ui/LimitDateTimeInput';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { useImageInputForm } from '../../ui/useImageInputForm';
import { BookingMenuPreview } from './BookingMenuPreview';
import { BookingSuccessPreview } from './BookingSuccess';
import { BookingSuccessMessageInput } from './BookingSuccessMessageInput';

export const BookingMenuEdit: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  const toast = useToast();

  const form = useForm<BookingMenuUpdateParams & { useImage: boolean }>({
    defaultValues: {
      ...bookingMenu,
      customText: {
        bookingSuccess:
          bookingMenu.customText?.bookingSuccess ||
          providerAccount?.customText?.bookingSuccess, // providerAccount単位で設定されているものがあればそちらを表示する
      },
      useImage: Boolean(bookingMenu.imageUrl),
    },
  });

  // サイズに対するバリデーションは一旦なし
  // https://developers.line.biz/flex-simulator/
  const { component: ImageForm, file: imageFile } = useImageInputForm({
    form,
    formLabel: '画像(横:縦 = 2:1)',
    imageUrl: bookingMenu?.imageUrl,
    defaultImagePath: '/assets/booking-menu-default-image.png',
    // validator: async (f) => {
    //   return undefined;
    // },
  });
  const { control } = form;

  const [inputName, inputDescription, inputBookingSuccessMessage, useImage] =
    useWatch({
      control,
      name: ['name', 'description', 'customText.bookingSuccess', 'useImage'],
    });
  const inputImageUrl = React.useMemo(() => {
    return imageFile ? URL.createObjectURL(imageFile) : bookingMenu.imageUrl;
  }, [bookingMenu.imageUrl, imageFile]);

  const navigate = useNavigate();
  const isPC = useIsPC();

  const updateMutation = useUpdateBookingMenu(providerAccount);
  const onSubmit = React.useCallback(
    async (inputValue: BookingMenuUpdateParams) => {
      const imageData = imageFile
        ? {
            imageData: {
              rawFile: imageFile,
              title: imageFile?.name,
            },
          }
        : {};
      const data: BookingMenuUpdateParams = {
        ...inputValue,
        shouldNoImage: !useImage,
        ...imageData,
      };

      return updateMutation
        .mutateAsync({
          data,
        })
        .then(async (res) => {
          toast({
            title: '予約メニューを更新しました。',
            status: 'success',
          });
          const path = providerAccount.needBookingMenu
            ? resourcePath({
                providerAccount,
                resourceName: 'bookingMenu',
                resourceId: bookingMenu.id,
                action: 'show',
              })
            : resourcePath({
                providerAccount,
                resourceName: 'bookingMenu',
                action: 'list',
              });
          navigate(path);
        })
        .catch((e) => {
          toast({
            title: `予約メニューの更新に失敗しました error: ${e}`,
            status: 'error',
          });
        });
    },
    [
      imageFile,
      useImage,
      bookingMenu,
      updateMutation,
      toast,
      providerAccount,
      navigate,
    ]
  );

  return (
    <Layout
      pageTitle="予約メニュー編集"
      hasBackButton={{
        providerAccount,
        resourceName: 'bookingMenu',
        action: 'show',
        resourceId: bookingMenu.id,
      }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            width: '100%',
          }}
        >
          {isPC ? (
            <HStack
              w={'full'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'32px'}
            >
              <VStack
                width={'full'}
                spacing={'20px'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexGrow={1}
              >
                <VStack
                  alignItems={'flex-start'}
                  backgroundColor={'white'}
                  py={'16px'}
                  w={'full'}
                  m={0}
                  divider={<StackDivider borderColor="gray.200" />}
                  borderWidth={'1px'}
                  borderColor={'gray.200'}
                  spacing={'12px'}
                >
                  {providerAccount.needBookingMenu && (
                    <>
                      <BookingMenuBasicInformationEdit
                        providerAccount={providerAccount}
                        ImageForm={ImageForm}
                      />
                    </>
                  )}
                  <BookingMenuConfigurationEdit bookingMenu={bookingMenu} />

                  <Box p={'16px'} w={'full'}>
                    <BookingSuccessMessageInput
                      providerAccount={providerAccount}
                    />
                  </Box>
                </VStack>
                <HStack w={'full'} mt={'24px'} justifyContent={'flex-end'}>
                  <Button
                    type={'submit'}
                    variant={'blue-fill'}
                    isLoading={updateMutation.isPending}
                  >
                    保存する
                  </Button>
                </HStack>
              </VStack>
              <VStack
                flexGrow={1}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                p={'20px'}
                alignItems={'flex-start'}
                spacing={'24px'}
                width={'full'}
                backgroundColor={'white'}
              >
                <Text fontSize={'lg'} fontWeight={'bold'}>
                  プレビュー
                </Text>
                {providerAccount.needBookingMenu && (
                  <PreviewContainer label={'予約メニュー'}>
                    <BookingMenuPreview
                      bookingMenu={{
                        ...bookingMenu,
                        name: inputName || bookingMenu.name,
                        description:
                          inputDescription || bookingMenu.description,
                        imageUrl: useImage
                          ? inputImageUrl || bookingMenu.imageUrl
                          : undefined,
                      }}
                      showImage={Boolean(bookingMenu.imageUrl)}
                    />
                  </PreviewContainer>
                )}
                <BookingSuccessPreview
                  providerAccount={providerAccount}
                  bookingSuccessMessage={inputBookingSuccessMessage}
                />
              </VStack>
            </HStack>
          ) : (
            <VStack
              width={'full'}
              spacing={'20px'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              flexGrow={1}
            >
              <VStack
                alignItems={'flex-start'}
                backgroundColor={'white'}
                py={'16px'}
                w={'full'}
                m={0}
                divider={<StackDivider borderColor="gray.200" />}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                spacing={'12px'}
              >
                {providerAccount.needBookingMenu && (
                  <>
                    <BookingMenuBasicInformationEdit
                      providerAccount={providerAccount}
                      ImageForm={ImageForm}
                    />
                    <VStack
                      flexGrow={1}
                      borderWidth={'1px'}
                      borderColor={'gray.200'}
                      p={'20px'}
                      alignItems={'flex-start'}
                      {...(providerAccount.needBookingMenu
                        ? {}
                        : { display: 'none' })}
                      spacing={'24px'}
                      width={'full'}
                      backgroundColor={'white'}
                    >
                      <Text fontSize={'lg'} fontWeight={'bold'}>
                        プレビュー
                      </Text>
                      <PreviewContainer label={'予約メニュー'}>
                        <BookingMenuPreview
                          bookingMenu={{
                            ...bookingMenu,
                            name: inputName || bookingMenu.name,
                            description:
                              inputDescription || bookingMenu.description,
                            imageUrl: useImage
                              ? inputImageUrl || bookingMenu.imageUrl
                              : undefined,
                          }}
                          showImage={Boolean(bookingMenu.imageUrl)}
                        />
                      </PreviewContainer>
                    </VStack>
                  </>
                )}
                <BookingMenuConfigurationEdit bookingMenu={bookingMenu} />

                <Box p={'16px'} w={'full'}>
                  <BookingSuccessMessageInput
                    providerAccount={providerAccount}
                  />
                </Box>
                <Box p={'16px'} w={'full'}>
                  <BookingSuccessPreview
                    providerAccount={providerAccount}
                    bookingSuccessMessage={inputBookingSuccessMessage}
                  />
                </Box>
              </VStack>
              <HStack w={'full'} mt={'24px'} justifyContent={'flex-end'}>
                {/* <DeleteBookingMenuButton
                  providerAccount={providerAccount}
                  bookingMenu={bookingMenu}
                /> */}
                <Button
                  type={'submit'}
                  variant={'blue-fill'}
                  w={'full'}
                  isLoading={updateMutation.isPending}
                >
                  保存する
                </Button>
              </HStack>
            </VStack>
          )}
        </form>
      </FormProvider>
    </Layout>
  );
};

const BookingMenuBasicInformationEdit: React.FC<{
  providerAccount: ProviderAccount;
  ImageForm: React.FC;
}> = ({ providerAccount, ImageForm }) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BookingMenuUpdateParams & { useImage: boolean }>();
  const useImage = useWatch({ control, name: 'useImage' });

  return (
    <VStack w={'full'} alignItems={'flex-start'} p={'16px'} spacing={'16px'}>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        基本情報
      </Text>
      <FormControl isRequired={true} isInvalid={!!errors.name}>
        <FormLabel fontWeight={'bold'}>メニュー名</FormLabel>
        <Input
          maxWidth={'full'}
          type={'text'}
          placeholder={'パーソナルトレーニング'}
          {...register('name', {
            required: true,
            maxLength: {
              value: 200,
              message: '200文字以内で入力してください。',
            },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={false} isInvalid={!!errors.description}>
        <FormLabel fontWeight={'bold'}>説明文</FormLabel>
        <Textarea
          maxWidth={'full'}
          placeholder={
            '専属トレーナーがお客様に最適なトレーニングプログラムを作成します。'
          }
          {...register('description', {
            required: false,
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <VStack w={'full'} alignItems={'flex-start'}>
        <HStack
          w={'full'}
          justifyContent={{
            base: 'space-between',
            md: 'flex-start',
          }}
          alignItems={'center'}
          spacing={{ base: undefined, md: '36px' }}
        >
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              画像エリアの表示
            </Text>
            <Text fontSize={'sm'}>メニューエリア内に画像を表示します。</Text>
          </VStack>
          <Switch
            isChecked={useImage}
            onChange={() => setValue('useImage', !useImage)}
          />
        </HStack>
        {useImage && (
          <Box maxW={'full'}>
            <ImageForm />
          </Box>
        )}
        {/* <Icon as={HighlightOff} color={'red'} /> */}
      </VStack>
    </VStack>
  );
};

const BookingMenuConfigurationEdit: React.FC<{ bookingMenu: BookingMenu }> = ({
  bookingMenu,
}) => {
  return (
    <VStack
      w={'full'}
      p={0}
      divider={<StackDivider borderColor="gray.200" />}
      spacing={'12px'}
    >
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="bookingStart" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="bookingEnd" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="cancelEnd" />
      </Box>
    </VStack>
  );
};
