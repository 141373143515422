export const Path = {
  signup: '/signup',
  login: '/login',
  logout: '/logout',
  home: '/',
  reminder: '/reminder',
  reminderEdit: '/reminder/edit',
  configuration: '/configuration',
  resetPassword: '/reset-password',
  attachComplete: '/attach-complete',
  attachError: '/attach-error',
  gcalAuthComplete: '/gcal-auth-complete',
  gcalAuthAlreadyCreated: '/gcal-auth-already-created',
  gcalAuthAlreadyConnected: '/gcal-auth-already-connected',
  changePlan: '/change-plan',
  invoiceEmailVerified: '/payment/invoice-email-verified',
  changeInvoiceConfiguration: '/payment/change-invoice-configuration',
  changeInvoiceConfigurationComplete:
    '/payment/change-invoice-configuration/complete',
  paymentRegistration: '/payment/registration',
  paymentRegistrationComplete: '/payment/registration/complete',
  unpaid: '/unpaid',
  lineOaLink: '/line-oa-link',
  lineOaLinkMessagingApi: '/line-oa-link-messaging-api',
  lineLiffIdRegistration: '/line-liff-id-registration',
  churn: '/churn',
} as const;

export type Path = (typeof Path)[keyof typeof Path];
export const PathDictionary = Object.fromEntries(
  Object.entries(Path).map(([key, value]) => [value, key])
);
