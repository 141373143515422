import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';

import { useForm } from 'react-hook-form';
import CardSection from './CardSection';
import { PochicoPaymentMethod } from './createSubscription';

type Props = {
  visible: boolean;
  onSubmit: (paymentMethod: PochicoPaymentMethod) => Promise<void>;
};
export const CardForm: React.FC<Props> = ({ visible, onSubmit }) => {
  const stripe = useStripe();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ invoiceEmail: string }>({});
  const elements = useElements();
  const [isInputCompleted, setIsInputCompleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isButtonEnabled = stripe && isInputCompleted && !loading;

  const _onSubmit = React.useCallback(
    async (input: { invoiceEmail: string }) => {
      if (!stripe || !elements) {
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }
      setLoading(true);
      return stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then(({ paymentMethod, error }) => {
          if (error) {
            console.log('[createPaymentMethod error]', error);
            return Promise.reject(error);
          } else if (!paymentMethod) {
            console.log('[createPaymentMethod error]no payment method error');
            return Promise.reject('failed to save payment method');
          } else {
            return onSubmit({
              type: 'card',
              paymentMethodId: paymentMethod.id,
              invoiceEmail: input.invoiceEmail,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [stripe, elements, onSubmit]
  );

  return (
    <Box w={'full'} hidden={!visible}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(_onSubmit)}>
        <VStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={'20px'}
        >
          <FormControl isRequired isInvalid={!!errors.invoiceEmail}>
            <FormLabel>請求先メールアドレス</FormLabel>
            <Input
              {...register('invoiceEmail', {
                required: true,
              })}
              type={'email'}
            />
            <FormErrorMessage>{errors.invoiceEmail?.message}</FormErrorMessage>
          </FormControl>
          <CardSection
            onChangeCompleteStatus={(isCompleted: boolean) => {
              setIsInputCompleted(isCompleted);
            }}
          />
          <Text>
            ※年は西暦の下2桁を入力してください
            <br />
            ※CVCはカードの裏面に記載されている3桁または4桁のセキュリティコードです
          </Text>

          <Button
            type="submit"
            width={{ base: 'full', md: '240px' }}
            variant={'blue-fill'}
            isLoading={loading}
            isDisabled={!isButtonEnabled}
          >
            送信
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
