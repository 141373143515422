import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ProviderAccount, waitAtLeast } from '@pochico/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateDoc } from 'firebase/firestore';

import { useAuthState } from '../context/providerAccount';
import {
  BookingMenuCreateParams,
  BookingMenuUpdateParams,
} from '../firebase/types';
import {
  bulkUpdateBookingMenus,
  createBookingMenu,
  deleteBookingMenu,
  fetchBookingMenu,
  fetchBookingMenus,
  updateBookingMenu,
} from '../providers/dataProvider/bookingMenu';
import { getProviderAccountRef } from '../providers/dataProvider/providerAccount';

export const bookingMenusQueryKeys = createQueryKeys('bookingMenus', {
  list: (providerAccountId: string) => [providerAccountId],
  show: (providerAccountId: string, id: string) => [providerAccountId, id],
});

export const useFetchBookingMenu = ({
  providerAccount,
  id,
}: {
  providerAccount: ProviderAccount;
  id: string;
}) => {
  return useQuery({
    queryKey: bookingMenusQueryKeys.show(providerAccount.id, id).queryKey,
    queryFn: () => fetchBookingMenu(providerAccount, id),
    // placeholderData: keepPreviousData,
  });
};

export const useFetchBookingMenus = ({
  providerAccount,
  enabled,
}: {
  providerAccount: ProviderAccount;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: bookingMenusQueryKeys.list(providerAccount.id).queryKey,
    queryFn: async () => {
      return fetchBookingMenus(providerAccount);
    },
    enabled: enabled ?? true,
    // placeholderData: keepPreviousData,
  });
};

export const useUpdateBookingMenu = (providerAccount: ProviderAccount) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ data }: { data: BookingMenuUpdateParams }) => {
      return waitAtLeast(updateBookingMenu({ providerAccount, data }), 1000);
    },
    onSuccess: async () => {
      return client.invalidateQueries({
        queryKey: bookingMenusQueryKeys._def,
      });
    },
  });
};

export const useBulkUpdateBookingMenus = (providerAccount: ProviderAccount) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({
      bookingMenus,
    }: {
      bookingMenus: BookingMenuUpdateParams[];
    }) => {
      return waitAtLeast(
        bulkUpdateBookingMenus(providerAccount, bookingMenus),
        300
      );
    },
    onSuccess: () => {
      return client.invalidateQueries({
        queryKey: bookingMenusQueryKeys._def,
      });
    },
  });
};

export const useSetBookingMenuEnablePerProviderAccount = (
  providerAccount: ProviderAccount
) => {
  const { refetch } = useAuthState();
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ enabled }: { enabled: boolean }) => {
      const needMenu = providerAccount.needBookingMenu === true;
      if (needMenu === enabled) {
        return;
      }
      return waitAtLeast(
        updateDoc(getProviderAccountRef(providerAccount.id), {
          needBookingMenu: enabled,
        }),
        1000
      );
    },
    onSuccess: async () => {
      return Promise.all([
        refetch(),
        client.invalidateQueries({
          queryKey: bookingMenusQueryKeys._def,
        }),
      ]);
    },
  });
};

export const useCreateBookingMenu = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: BookingMenuCreateParams) => {
      return waitAtLeast(
        createBookingMenu({
          providerAccountId: providerAccount.id,
          botId: providerAccount.botId,
          bookingMenuData: input,
        }),
        1000
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: bookingMenusQueryKeys._def,
      });
    },
  });
};

export const useDeleteBookingMenu = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (bookingMenuId: string) => {
      return waitAtLeast(
        deleteBookingMenu(providerAccount.id, bookingMenuId),
        1000
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: bookingMenusQueryKeys._def,
      });
    },
  });
};
