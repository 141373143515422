import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { BookingCreateParams, DisplaySpot } from '../../../firebase/types';
import { useCreateBooking } from '../../../hooks/booking';
import { resourcePath } from '../../../hooks/useUrlPath';
import { isBookingExisting } from '../../../providers/dataProvider/booking';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { Layout } from '../../ui/Layout';
import { Link } from '../../ui/Link';
import { BookingUserInput } from './BookingUserInput';
import { SpotInput } from './SpotInput';

type BookingCreateDefaultValues = {
  bookingMenuId: string;
  date: string;
};
export const BookingCreate: React.FC<{
  providerAccount: ProviderAccount;
  spot: DisplaySpot | undefined;
  defaultValues: BookingCreateDefaultValues | undefined;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, spot, bookingMenus, defaultValues }) => {
  // const navigate = useNavigate();
  // const locationFrom = useLocationFrom({ fallbackPath: undefined });
  const mutation = useCreateBooking({ providerAccount });

  const form = useForm<BookingCreateParams>({
    mode: 'onChange',
    defaultValues: {
      bookingMenuId: spot?.bookingMenuId || defaultValues?.bookingMenuId,
    },
  });

  const toast = useToast();
  const { resetField } = form;
  const onSubmitConfirmed = React.useCallback(
    async (createBookingData: BookingCreateParams) => {
      return mutation
        .mutateAsync(createBookingData)
        .then(async ({ data }) => {
          const bookingShowPath = resourcePath({
            providerAccount,
            resourceName: 'booking',
            action: 'show',
            resourceId: data.id,
          });
          resetField('lineUserId');
          resetField('userName');
          resetField('providerMemo');
          const toastId = toast({
            status: 'success',
            title: (
              <VStack alignItems={'flex-start'}>
                <Text>予約を作成しました</Text>
                <Link
                  to={bookingShowPath}
                  color={'white'}
                  _hover={{
                    textDecoration: 'underline',
                    color: 'gray.200',
                  }}
                  textDecoration={'underline'}
                  onClick={() => toast.close(toastId)}
                >
                  作成した予約を確認する
                </Link>
              </VStack>
            ),
            duration: 5000,
          });
          // navigate(locationFrom?.path || bookingShowPath);
        })
        .catch((e) => {
          toast({
            title: `予約の作成に失敗しました error: ${e}`,
            status: 'error',
          });
        });
    },
    [mutation, providerAccount, resetField, toast]
  );

  const { openConfirmationDialog } = useConfirmationDialog();
  const onSubmit = React.useCallback(
    async (createBookingData: BookingCreateParams) => {
      if (!form.formState.isValid) {
        return;
      }
      if (createBookingData.lineUserId) {
        const existingBooking = await isBookingExisting({
          providerAccountId: providerAccount.id,
          spotId: createBookingData.spotId,
          lineUserId: createBookingData.lineUserId,
        });
        console.log('existingBooking', existingBooking);
        if (existingBooking) {
          openConfirmationDialog({
            title: '既存の予約があります',
            body: (
              <>
                <Text>
                  指定した日時、指定したユーザーですでに予約が存在します。
                  <br />
                  重複して予約を作成しますか？
                </Text>
              </>
            ),
            cancelText: '戻る',
            submitText: '作成する',
            onSubmit: () => onSubmitConfirmed(createBookingData),
          });
          return;
        }
      }
      onSubmitConfirmed(createBookingData);
    },
    [
      form.formState.isValid,
      onSubmitConfirmed,
      openConfirmationDialog,
      providerAccount.id,
    ]
  );

  return (
    <Layout hasBackButton pageTitle="予約の作成">
      {bookingMenus.length === 0 && <Text>予約メニューがありません</Text>}

      <FormProvider {...form}>
        <form style={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}>
          <VStack w={'full'} spacing={'16px'} alignItems={'flex-start'}>
            <VStack
              alignItems={'flex-start'}
              width={'full'}
              gap={0}
              backgroundColor={'white'}
            >
              <BookingCreateForm
                providerAccount={providerAccount}
                bookingMenuList={bookingMenus}
                defaultValues={spot || defaultValues}
              />
            </VStack>
            <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
              <Button
                variant={'blue-fill'}
                type={'submit'}
                size={'sm'}
                isLoading={mutation.isPending}
                isDisabled={!form.formState.isValid}
              >
                保存する
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  );
};

const BookingCreateForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  defaultValues: BookingCreateDefaultValues | undefined;
}> = ({ providerAccount, bookingMenuList, defaultValues }) => {
  const form = useFormContext<BookingCreateParams>();
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      gap={'12px'}
      whiteSpace={'nowrap'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      py={'16px'}
    >
      <VStack
        spacing={{ base: '8px', lg: '16px' }}
        px={'16px'}
        alignItems={'flex-start'}
      >
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.bookingMenuId}
        >
          <FormLabel>予約メニュー</FormLabel>
          <Select
            {...form.register('bookingMenuId')}
            bg={'white'}
            placeholder={'選択してください'}
            isDisabled={!!form.formState.defaultValues?.bookingMenuId}
          >
            {bookingMenuList.map((menu) => (
              <option key={menu.id} value={menu.id} defaultChecked={false}>
                {menu.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {form.formState.errors?.bookingMenuId?.message}
          </FormErrorMessage>
        </FormControl>

        <SpotInput
          providerAccount={providerAccount}
          defaultValues={defaultValues}
        />
      </VStack>
      <Divider borderColor={'gray.120'} />
      <Box px={'16px'}>
        <BookingUserInput providerAccount={providerAccount} />
      </Box>
      <Box w={'full'} px={'16px'}>
        <FormControl>
          <FormLabel w={'7rem'}>予約メモ</FormLabel>
          <Textarea {...form.register('providerMemo')} bg={'white'} />
        </FormControl>
      </Box>
    </VStack>
  );
};
