import { parseTimeString, Spot } from '@pochico/shared';
import dayjs from 'dayjs';
import CONSTANTS from '../../../commons/constants';

export type BulkAddSpotsInput = {
  bookingMenuId: string;
  duration: {
    startDate: string; // YYYY-MM-DD,
    endDate: string; // YYYY-MM-DD,
  };
  slots: {
    mon: SpotSlot[];
    tue: SpotSlot[];
    wed: SpotSlot[];
    thu: SpotSlot[];
    fri: SpotSlot[];
    sat: SpotSlot[];
    sun: SpotSlot[];
  };
  excludeDays: { date: string /* YYYY-MM-DD */ }[];
};
export type SpotSlot = {
  startTime: string; // HH:mm
  maxBookings: number;
};

export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const convertBulkAddSpotsToSpots = (params: BulkAddSpotsInput) => {
  const excludeDays = params.excludeDays.map(({ date }) => date);
  const dates = (() => {
    const start = dayjs(params.duration.startDate);
    const end = dayjs(params.duration.endDate);
    const days = end.diff(start, 'day');

    if (
      !start.isValid() ||
      !end.isValid() ||
      days < 0 ||
      days > CONSTANTS.MAX_BULK_SPOT_PERIOD_DAYS
    ) {
      throw new Error('指定された期間が不正です');
    }

    // 終了日を含めるため+1する
    return [...Array(days + 1)].flatMap((_, i) => {
      const date = start.add(i, 'day');
      const day = (() => {
        switch (date.day()) {
          case 0:
            return 'sun';
          case 1:
            return 'mon';
          case 2:
            return 'tue';
          case 3:
            return 'wed';
          case 4:
            return 'thu';
          case 5:
            return 'fri';
          case 6:
            return 'sat';
          default:
            throw new Error(`unknown day given (${date})`);
        }
      })();
      const slots = params.slots[day];
      if (
        slots.length === 0 ||
        excludeDays.includes(date.format('YYYY-MM-DD'))
      ) {
        return [];
      } else {
        return [{ date, slots }];
      }
    });
  })();

  const pad = (n: number) => String(n).padStart(2, '0');
  return dates.flatMap<
    Pick<
      Spot,
      'date' | 'startTime' | 'maxBookings' | 'dateForSort' | 'dateTimeForSort'
    >
  >(({ date, slots }) => {
    return slots.flatMap(({ startTime, maxBookings }) => {
      const parsed = parseTimeString(startTime);
      if (!parsed) {
        console.error({ message: `invalid time string ${startTime}` });
        return [];
      }
      const { hour, minute } = parsed;
      return [
        {
          date: date.format('YYYY-MM-DD'),
          startTime,
          maxBookings,
          dateForSort: Number(date.format('YYYYMMDD')),
          dateTimeForSort: Number(
            date.format(`YYYYMMDD${pad(hour)}${pad(minute)}`)
          ), // YYYYMMDDHHmmの整数値
        },
      ];
    });
  });
};
