import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const outline = definePartsStyle({
  // define the part you're going to style
  field: {
    borderWidth: '1px',
    borderColor: 'gray.300',
    bgColor: 'white',
    _focusVisible: {
      borderColor: 'blue.500',
    },
  },
});

export const Select = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    variant: 'outline',
  },
});
