import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingCreate } from '../../components/features/bookings/create';
import { Loading } from '../../components/ui/Loading';
import { useFetchBookingMenus } from '../../hooks/bookingMenus';
import { useFetchSpot } from '../../hooks/spots';
import { useUrlPath } from '../../hooks/useUrlPath';

export const BookingCreatePage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約の作成');
  const urlPath = useUrlPath();

  // spot詳細画面から予約を作成しようとするとURLパラメータにspotIdが付与される
  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    []
  );
  const spotId: string | undefined = React.useMemo(
    () => searchParams.get('spotId') || undefined,
    [searchParams]
  );
  const defaultValues = React.useMemo(() => {
    if (searchParams.has('date') && searchParams.has('bookingMenuId')) {
      return {
        date: searchParams.get('date')!,
        startTime: searchParams.get('startTime'),
        bookingMenuId: searchParams.get('bookingMenuId')!,
      };
    } else {
      return undefined;
    }
  }, [searchParams]);
  const spot = useFetchSpot({ providerAccount, id: spotId });
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });
  if (spot.isLoading || bookingMenuQuery.isLoading) {
    return <Loading />;
  }
  if (
    !urlPath ||
    urlPath.resourceName !== 'booking' ||
    urlPath.action !== 'create'
  ) {
    console.log('invalid url path', urlPath);
    return (
      <Navigate
        replace={true}
        to={`/${providerAccount.id}/${providerAccount.botId}/booking`}
      />
    );
  }

  return (
    <BookingCreate
      providerAccount={providerAccount}
      spot={spot.data}
      defaultValues={defaultValues}
      bookingMenus={bookingMenuQuery.data || []}
    />
  );
};
