import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { chunk } from '@chakra-ui/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import React from 'react';

import { dayOfWeeks } from '../../helpers/dayOfWeeks';

export type CalendarViewProps = {
  yearMonth: {
    year: number;
    month: number;
  };
  dateComponent: (date: dayjs.Dayjs) => React.ReactElement;
};
export const CalendarView: React.FC<CalendarViewProps> = ({
  yearMonth,
  dateComponent,
}) => {
  const headers = dayOfWeeks
    .slice()
    .sort((a, b) => a.day - b.day) // 日曜日始まり
    .map((d) => (
      <CalendarHeaderElement key={`calendar-header-${d.id}`} text={d.name} />
    ));
  const startOfMonth = dayjs()
    .year(yearMonth.year)
    .month(yearMonth.month - 1)
    .startOf('month');
  const endOfMonth = startOfMonth.endOf('month');

  // 1日までの空白(1日が日曜日なら0でよい)
  const startFillers = Array.from(Array(startOfMonth.day()).keys()).map((i) => {
    return (
      <CalendarFiller
        key={`startFiller-${yearMonth.year}-${yearMonth.month}-${i}`}
      />
    );
  });

  // 月初から月末
  const dayElements = Array.from(
    Array(1 + endOfMonth.diff(startOfMonth, 'day')).keys()
  ).map((day: number) => {
    const date = startOfMonth.add(day, 'day');
    return dateComponent(date);
  });

  // 月末以降の空白(トータルで最大6週間表示する)
  const endFillers = Array.from(
    Array(7 * 6 - startFillers.length - dayElements.length).keys()
  ).map((i) => (
    <CalendarFiller
      key={`endFiller-${yearMonth.year}-${yearMonth.month}-${i}`}
    />
  ));

  // 全部をくっつけて7日ごとに区切る
  const lineElements = chunk(
    [...headers, ...startFillers, ...dayElements, ...endFillers],
    7
  );

  return (
    <VStack maxW={'90vw'} spacing={0}>
      {lineElements.map((line, index) => {
        return (
          <Flex maxW={'90vw'} w={'full'} key={`calendar-line-${index}`}>
            {line}
          </Flex>
        );
      })}
    </VStack>
  );
};

const CalendarHeaderElement: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Flex
      {...calendarElementStyle}
      h={'28px'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      justifyContent={'center'}
      alignItems={'center'}
      bgColor={'gray.50'}
    >
      <Text>{text}</Text>
    </Flex>
  );
};

const CalendarFiller: React.FC = () => {
  return <Box {...calendarElementStyle} height={calendarElementStyle.h}></Box>;
};

const calendarElementStyle = {
  w: { base: '48px', md: '64px' },
  h: '50px',
  borderWidth: '1px',
  borderColor: 'gray.300',
  marginBottom: '-1px', // 重ならないように
  marginRight: '-1px',
  fontSize: 'sm',
  // paddingX: '12px',
  // paddingY: '12px',
};

export const CalendarCell: React.FC<{
  onClick?: () => void;
  date: dayjs.Dayjs;
  count: number;
}> = ({ onClick, date, count }) => {
  // 土日は文字色を変える
  const color =
    date.day() === 0 ? 'red.500' : date.day() === 6 ? 'blue.500' : 'black';

  return (
    <Flex {...calendarElementStyle}>
      <Button
        variant={'transparent-clickable'}
        borderRadius={'5px'}
        // backgroundColor={'alpha'}
        // _hover={{ bgColor: 'alpha' }}
        // _active={{ bgColor: 'alpha' }}
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'default'}
        w={'full'}
        h={'full'}
        px={'4px'}
      >
        <VStack
          alignItems={'center'}
          // px={'4px'}
          spacing={'2px'}
          w={'full'}
          fontSize={'sm'}
        >
          <Text color={color}>{date.date()}</Text>
          <Box
            // px={'6px'}
            w={'full'}
            borderRadius={'4px'}
            sx={
              count === 0
                ? {}
                : {
                    backgroundColor: `blue.50`,
                    _hover: {
                      backgroundColor: `blue.100`,
                    },
                    _active: {
                      bg: `blue.200`,
                    },
                    color: `blue.500`,
                  }
            }
          >
            <Text
              visibility={
                /*枠がなくてもレイアウト崩れないように隠して入れておく */
                count === 0 ? 'hidden' : 'visible'
              }
              fontWeight={'bold'}
            >
              {count}件
            </Text>
          </Box>
        </VStack>
      </Button>
    </Flex>
  );
};
