import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  // define the part you're going to style
  field: {
    borderWidth: '1px',
    borderColor: 'gray.300',
    color: 'gray.800',
    bgColor: 'white',
  },
});

export const Input = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    variant: 'outline',
  },
});
