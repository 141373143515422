import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LineUser, ProviderAccount, waitUntil } from '@pochico/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { BookingCreateParams, DisplayLineUser } from '../../../firebase/types';
import { lineUserQueryKey } from '../../../hooks/lineUser';
import { getLineUserList } from '../../../providers/dataProvider/lineUser';
import { Pagination } from '../../../providers/dataProvider/type';
import { AutoCompleteInput, Choice } from '../../ui/AutoCompleteInput';

const useWithCaching = <T,>() => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const withCaching = React.useCallback(
    async (key: QueryKey, f: () => Promise<T>) => {
      const state = queryClient.getQueryState(key);
      if (state && !state.isInvalidated) {
        if (state.fetchStatus === 'fetching') {
          await waitUntil(() => state.fetchStatus !== 'fetching', 3000);
        }
        return state.data as T;
        // const cache = queryClient.getQueryData<T>(key);
        // if (cache) {
        //   return cache;
        // }
      }
      setIsLoading(true);
      return f()
        .then((data) => {
          queryClient.setQueryData(key, data);
          return data;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [queryClient]
  );
  return { withCaching, isLoading };
};

export const BookingUserInput: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  // 補完のためにLINEユーザーをまとめて取得する
  // ReferenceInput + AutoCompleteInputで入力が変わるたびにfirestoreから取得する方法もあるが、
  // _lineUsersコレクションのnameに対してlike検索を実装することは難しいので富豪的な実装になっている
  const { withCaching, isLoading } = useWithCaching<DisplayLineUser[]>();
  const queryClient = useQueryClient();
  const autoCompleteChoices: (input: string) => Promise<Choice[]> =
    React.useCallback(
      async (input: string) => {
        const lineUsers = await (async () => {
          const pagination: Pagination<DisplayLineUser> = {
            perPage: 100,
            sort: { field: 'lastBookedAt', direction: 'desc' },
          };
          if (!input) {
            const queryKey = lineUserQueryKey.listForAutoComplete({
              providerAccountId: providerAccount.id,
              perPage: pagination.perPage,
              sort: pagination.sort as Pagination<LineUser>['sort'],
              input: '',
            }).queryKey;
            return withCaching(queryKey, () =>
              getLineUserList(queryClient, providerAccount.id, {
                filter: {
                  archived: false,
                },
                pagination,
              }).then((result) => result.data)
            );
          }
          const queryKey = lineUserQueryKey.listForAutoComplete({
            providerAccountId: providerAccount.id,
            perPage: pagination.perPage,
            sort: pagination.sort as Pagination<LineUser>['sort'],
            input: input,
          }).queryKey;
          return withCaching(queryKey, async () => {
            const byDisplayName = getLineUserList(
              queryClient,
              providerAccount.id,
              {
                filter: { displayName: input, archived: false },
                pagination,
              }
            );
            const byDisplayNameByProvider = getLineUserList(
              queryClient,
              providerAccount.id,
              {
                filter: { displayNameByProvider: input, archived: false },
                pagination,
              }
            );
            const results = await Promise.all([
              byDisplayName,
              byDisplayNameByProvider,
            ]);
            return results.flatMap((result) => result.data).uniqBy((l) => l.id);
          });
        })();
        return (
          lineUsers.map((lineUser) => {
            // 別名をつけていればそちらを優先して表示する
            const text = lineUser.displayNameByProvider
              ? lineUser.displayNameByProvider
              : lineUser.displayName;
            const element = (
              <HStack key={lineUser.id}>
                <Image
                  borderRadius={'full'}
                  width={'32px'}
                  src={lineUser.pictureUrlSmall}
                />
                <Text>{text}</Text>
              </HStack>
            );
            return { id: lineUser.id, text, element };
          }) || []
        );
      },
      [providerAccount.id, queryClient, withCaching]
    );

  const [useLineUser, setUseLineUser] = React.useState(true);
  const formContext = useFormContext<BookingCreateParams>();

  const changeUserType = React.useCallback(() => {
    if (useLineUser) {
      formContext.setValue('lineUserId', undefined);
    } else {
      formContext.setValue('userName', undefined);
    }
    setUseLineUser(!useLineUser);
  }, [formContext, useLineUser]);

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      whiteSpace={'nowrap'}
    >
      <FormControl>
        <HStack justifyContent={'center'}>
          <Checkbox
            defaultChecked
            checked={useLineUser}
            onChange={changeUserType}
          ></Checkbox>
          <FormLabel>ポチコ経由で予約を取得したことがあるユーザー</FormLabel>
        </HStack>
      </FormControl>
      {useLineUser ? (
        <FormControl
          isRequired
          isInvalid={!!formContext.formState.errors?.lineUserId}
        >
          <FormLabel w={'7rem'}>LINEユーザー</FormLabel>
          <VStack alignItems={'flex-start'}>
            <HStack justifyContent={'flex-start'}>
              <AutoCompleteInput
                type={'mustSelect'}
                defaultValue={undefined}
                choices={autoCompleteChoices}
                onChange={(choice) => {
                  formContext.setValue('lineUserId', String(choice.id));
                }}
                placeholder={'検索'}
                emptyText={'該当するユーザーがいません'}
              />
              {isLoading && <Spinner />}
            </HStack>
            <FormErrorMessage>
              {formContext.formState.errors?.lineUserId?.message}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      ) : (
        <FormControl
          isRequired
          isInvalid={!!formContext.formState.errors?.userName}
        >
          <HStack alignItems={'baseline'}>
            <FormLabel>予約者の名前</FormLabel>
            <VStack alignItems={'center'}>
              <Input
                type={'text'}
                {...formContext.register('userName', {
                  required: true,
                })}
                // onChange={(e) => {
                //   const userName = e.target.value;
                //   formContext.setValue('userName', userName);
                // }}
              />
              <FormErrorMessage>
                {formContext.formState.errors?.userName?.message}
              </FormErrorMessage>
            </VStack>
          </HStack>
        </FormControl>
      )}
    </VStack>
  );
};
