import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import React from 'react';

import Footer from './Footer';
import { Logo } from './Logo';
import { LogoutButton } from './LogoutButton';

export const RegistrationLayout: React.FC<{
  showLogoutButton: boolean;
  children: React.ReactNode;
}> = ({ showLogoutButton, children }) => {
  return (
    <VStack w={'full'}>
      <Header showLogoutButton={showLogoutButton} />
      {children}
      <Footer />
    </VStack>
  );
};

const Header: React.FC<{ showLogoutButton: boolean }> = ({
  showLogoutButton,
}) => {
  return (
    <HStack
      width={'full'}
      borderBottom={'1px solid #DDDDDD'}
      paddingX={4}
      paddingY={4}
      justifyContent={'space-between'}
    >
      <Flex justifyContent={'left'} alignItems={'center'}>
        <Logo width={'135px'} />
      </Flex>
      {showLogoutButton && (
        <Box>
          <LogoutButton />
        </Box>
      )}
    </HStack>
  );
};
