import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogDispatcher } from '../../ui/Dialog';

export const useAddSavedFilterDialog = <T,>({
  upsert,
  toComponent,
}: {
  upsert: (data: { name: string; filter: T }) => Promise<void>;
  toComponent: ((filter: T) => React.ReactElement) | undefined;
}) => {
  const { openDialog, closeDialog, isOpen } = useDialogDispatcher();
  const mutation = useMutation({
    mutationFn: async (data: { name: string; filter: T }) => {
      return upsert({ name: data.name, filter: data.filter });
    },
    onSuccess: () => {
      closeDialog();
    },
  });
  const open = React.useCallback(
    async (filter: T) => {
      console.log({
        message: `useAddSavedFilterDialog open`,
        isOpen,
        toComponent,
      });
      if (!toComponent) {
        return;
      }
      if (isOpen) {
        return;
      }
      return openDialog({
        size: 'lg',
        closeOnOverlayClick: true,
        body: (
          <DialogBody
            onCancel={closeDialog}
            onSubmit={async (data) => {
              return mutation.mutate({ name: data.name, filter });
            }}
            isLoading={mutation.isPending}
            toComponent={toComponent(filter)}
          />
        ),
      });
    },
    [toComponent, closeDialog, isOpen, mutation, openDialog]
  );
  return {
    open,
  };
};

const DialogBody = ({
  onCancel,
  onSubmit,
  isLoading,
  toComponent,
}: {
  onCancel: () => void;
  onSubmit: (input: { name: string }) => Promise<void>;
  isLoading: boolean;
  toComponent: React.ReactElement;
}) => {
  const form = useForm<{ name: string }>({
    mode: 'onChange',
  });
  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" alignItems={'center'}>
        現在の検索条件を保存しますか？
        <AlertDialogCloseButton onClick={onCancel} />
      </AlertDialogHeader>

      <AlertDialogBody>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <VStack w={'full'} alignItems={'flex-start'}>
              <FormControl
                isRequired
                isInvalid={Boolean(form.formState.errors?.name)}
              >
                <FormLabel>検索条件名</FormLabel>
                <Input
                  type="text"
                  placeholder="名前"
                  {...form.register('name', { required: true })}
                />
              </FormControl>

              {/* 検索条件を表示する */}
              {toComponent}

              <HStack w={'full'} justifyContent={'flex-end'}>
                <Button type="button" colorScheme="gray" onClick={onCancel}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={!form.formState.isValid}
                  variant={'blue-fill'}
                >
                  保存
                </Button>
              </HStack>
            </VStack>
          </form>
        </FormProvider>
      </AlertDialogBody>
      <AlertDialogFooter />
    </AlertDialogContent>
  );
};
