import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';

import { MdCheckCircle, MdDrafts } from 'react-icons/md';
import { useIsPC } from '../../../hooks/useIsPC';
import { Layout } from '../../ui/Layout';
import { LimitDateTime } from '../../ui/LimitDateTime';
import { NavigationButton } from '../../ui/NavigationButton';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { TextWithNewLine } from '../../ui/TextWithNewLine';
import { BookingMenuPreview } from './BookingMenuPreview';
import { BookingSuccessPreview } from './BookingSuccess';
import { DeleteBookingMenuButton } from './DeleteBookingMenuButton';

export const BookingMenuShow: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  return (
    <Layout
      hasBackButton={{
        action: 'list',
        resourceName: 'bookingMenu',
        providerAccount,
      }}
      pageTitle="予約メニュー詳細"
    >
      <BookingMenuDetail
        providerAccount={providerAccount}
        bookingMenu={bookingMenu}
      />
    </Layout>
  );
};

export const BookingMenuDetail: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  const isPC = useIsPC();
  return (
    <Flex
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      direction={isPC ? 'row' : 'column-reverse'}
      gap={'32px'}
    >
      <VStack
        spacing={'20px'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        flexGrow={1}
        w={'full'}
      >
        <VStack
          alignItems={'flex-start'}
          backgroundColor={'white'}
          py={'16px'}
          w={'full'}
          m={0}
          divider={<StackDivider borderColor="gray.200" />}
          borderWidth={'1px'}
          borderColor={'gray.200'}
          spacing={'12px'}
        >
          {providerAccount.needBookingMenu && (
            <Box p={'16px'} w={'full'}>
              <VStack alignItems={'flex-start'} w={'full'}>
                <Heading as={'h1'} fontSize={'20px'}>
                  {bookingMenu.name}
                </Heading>
                <HStack
                  fontWeight={'bold'}
                  color={
                    bookingMenu.status === 'active' ? 'green.500' : 'gray.500'
                  }
                >
                  <Icon
                    as={
                      bookingMenu.status === 'active' ? MdCheckCircle : MdDrafts
                    }
                    boxSize={'24px'}
                  />
                  <Text>
                    {bookingMenu.status === 'active' ? '公開中' : '下書き中'}
                  </Text>
                </HStack>
              </VStack>
            </Box>
          )}
          <Box p={'16px'} w={'full'}>
            <LimitDateTime bookingMenu={bookingMenu} source="bookingStart" />
          </Box>
          <Box p={'16px'} w={'full'}>
            <LimitDateTime bookingMenu={bookingMenu} source="bookingEnd" />
          </Box>
          <Box p={'16px'} w={'full'}>
            <LimitDateTime bookingMenu={bookingMenu} source="cancelEnd" />
          </Box>
          <VStack
            p={'16px'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            width={'full'}
          >
            <Text fontWeight={'bold'}>予約完了時のメッセージ</Text>
            <TextWithNewLine>
              {bookingMenu.customText?.bookingSuccess ?? '設定無し'}
            </TextWithNewLine>
          </VStack>
        </VStack>
        <HStack w={'full'} justifyContent={'flex-end'}>
          <DeleteBookingMenuButton
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
          />
          <NavigationButton
            variant={'blue-fill'}
            w={isPC ? 'fit-content' : 'full'}
            to={{
              action: 'edit',
              resourceId: bookingMenu.id,
              resourceName: 'bookingMenu',
              providerAccount,
            }}
          >
            編集する
          </NavigationButton>
        </HStack>
      </VStack>
      <VStack
        flexGrow={1}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        p={'20px'}
        w={'full'}
        alignItems={'flex-start'}
        spacing={'24px'}
        backgroundColor={'white'}
      >
        <Text fontSize={'lg'} fontWeight={'bold'}>
          プレビュー
        </Text>
        {providerAccount.needBookingMenu && (
          <PreviewContainer label={'予約メニュー'}>
            <BookingMenuPreview
              bookingMenu={bookingMenu}
              showImage={Boolean(bookingMenu.imageUrl)}
            />
          </PreviewContainer>
        )}
        <BookingSuccessPreview
          providerAccount={providerAccount}
          bookingSuccessMessage={bookingMenu.customText?.bookingSuccess}
        />
      </VStack>
    </Flex>
  );
};
